import { DefaultsData } from "store/order/state";
import { defaultsWasSet } from "store/order/action";
import { useCallback } from 'react';
import useDispatchTyped from "store/hook/useDispatchTyped/useDispatchTyped";

const useSetDefaultsWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setDefaultsWasSet = useCallback(
        async (defaults: DefaultsData) => {
            await dispatch(defaultsWasSet(defaults));
        },
        [dispatch],
    );

    return setDefaultsWasSet;
};

export default useSetDefaultsWasSetAction;

import { AppState } from '../../../../appState';
import { PersonalInformationData } from '../../../state';
import { useSelector } from 'react-redux';

const usePersonalInformationData = (): PersonalInformationData | undefined | null => {
    const personalInformationData = useSelector<AppState, PersonalInformationData | undefined | null>((state) => {
        return state.order.personalInformationData;
    });

    return personalInformationData;
};

export default usePersonalInformationData;

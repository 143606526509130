import { AppAction } from './appAction';
import { AppState } from './appState';
import { combineReducers } from 'redux';
import orderReducer from './order/reducer';

const rootReducer = combineReducers<AppState, AppAction>({
    order: orderReducer,
});

export default rootReducer;

import { addressValuesToClientData, clientDataToAddressValues } from 'component/form/addressForm/mapper';
import { ButtonVariant } from 'component/button/buttonProps';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getNavigationNameForPageEnum, getSubtitleForPageEnum, getTitleForPageEnum, PageEnum } from 'route/page';
import { getUrlAdysHomepage } from 'config/url';
import { insectControlServiceRoute, insectControlSuccessRoute } from 'route/routeInsectControl';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddressForm, { AddressFormValues } from 'component/form/addressForm';
import PageWrapper from 'component/pageWrapper';
import React, { useEffect } from 'react';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useSetBillingClientWasSetAction from 'store/order/hook/address/useSetBillingClientWasSetAction/useSetBillingClientWasSetAction';
import useSetDeliveryClientWasSetAction from 'store/order/hook/address/useSetDeliveryClientWasSetAction/useSetDeliveryClientWasSetAction';
import useSetPostalCodeIsNotInSystemAction from 'store/order/hook/postalCodeIsNotInSystem/useSetPostalCodeIsNotInSystemAction/useSetPostalCodeIsNotInSystemAction';
import useSetServiceTypeWasSetAction from 'store/order/hook/order/useSetServiceTypeWasSetAction/useSetServiceTypeWasSetAction';

const InsectControlAddressPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const deliveryClient = useDeliveryClient();
    const setDeliveryClientWasSet = useSetDeliveryClientWasSetAction();
    const billingClient = useBillingClient();
    const setBillingAddressDataWasSet = useSetBillingClientWasSetAction();
    const { setPostalCodeIsNotInSystemWasSet } = useSetPostalCodeIsNotInSystemAction();
    const setServiceTypeWasSet = useSetServiceTypeWasSetAction();

    useEffect(() => {
        setServiceTypeWasSet(ServiceType.insectControl);
    }, [setServiceTypeWasSet]);

    const onUnknownZip = (zip: string) => {
        const subject = t('order.screen.address.unexpectedZipMailSubject', { postalCode: zip });
        setPostalCodeIsNotInSystemWasSet();
        history.push(insectControlSuccessRoute.url());
        window.open('mailto:info@adys.lu?subject=' + subject);
    };

    const onSubmit = async (values: AddressFormValues) => {
        await setDeliveryClientWasSet(addressValuesToClientData(values.delivery));
        await setBillingAddressDataWasSet(values.billing !== undefined ? addressValuesToClientData(values.billing) : null);
        history.push(insectControlServiceRoute.url());
    };

    return (
        <PageWrapper
            page={PageEnum.insectControlAddress}
            pageTitle={getNavigationNameForPageEnum(PageEnum.insectControlAddress)}
            serviceType={ServiceType.insectControl}
            wizardItems={[PageEnum.insectControlAddress, PageEnum.insectControlService, PageEnum.insectControlConfirm, PageEnum.insectControlPayment]}
            title={flc(getTitleForPageEnum(PageEnum.insectControlAddress))}
            subtitle={getSubtitleForPageEnum(PageEnum.insectControlAddress)}
            price={undefined}
            secondButton={{
                type: 'submit',
                form: 'addressForm',
                arrowContinue: true,
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    window.location.href = getUrlAdysHomepage();
                },
                text: t('order.navigation.cancel'),
                variant: ButtonVariant.outlined,
            }}
        >
            <AddressForm
                id="addressForm"
                onSubmit={onSubmit}
                onUnknownZip={onUnknownZip}
                defaultValues={{
                    delivery: deliveryClient !== undefined ? clientDataToAddressValues(deliveryClient) : undefined,
                    billing: billingClient !== undefined && billingClient !== null ? clientDataToAddressValues(billingClient) : undefined,
                }}
            />
        </PageWrapper>
    );
};

export default InsectControlAddressPage;

import i18next from 'i18next';

export enum BinTypeEnum {
    household = 'household',
    organic = 'organic',
    glass = 'glass',
    paper = 'paper',
}

export const translateBinTypeEnum = (binTypeEnum: BinTypeEnum): string => {
    switch (binTypeEnum) {
        case BinTypeEnum.glass:
            return i18next.t('enum.binType.glass');
        case BinTypeEnum.household:
            return i18next.t('enum.binType.household');
        case BinTypeEnum.organic:
            return i18next.t('enum.binType.organic');
        case BinTypeEnum.paper:
            return i18next.t('enum.binType.paper');
        default:
            throw new Error('unexpected BinTypeEnum in translateBinTypeEnum');
    }
};

import { binCleaningDefaultRoute } from 'route/routeBinCleaning';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';

const Root: React.FC<{}> = () => {
    const history = useHistory();

    useEffect(() => {
        history.push(binCleaningDefaultRoute.url());
    }, [history]);

    return null;
};

export default Root;

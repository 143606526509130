import { AppLanguageEnum, findLanguageFromUnknown, getAbbreviationsByAppLanguageEnum } from '../../localization/appLanguage';
import i18next from 'i18next';
import NewHeader from 'component/newHeader';
import React, { useCallback, useReducer } from 'react';

const HeaderWrap: React.FC<{}> = () => {
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

    const language = i18next.language;
    const languageFound = findLanguageFromUnknown(language);
    const languageEnum = languageFound === null ? AppLanguageEnum.english : languageFound;

    const onChangeLanguage = useCallback(
        (languageEnum: AppLanguageEnum) => {
            i18next.changeLanguage(getAbbreviationsByAppLanguageEnum(languageEnum)).then(() => {
                forceUpdate();
            });
        },
        [forceUpdate],
    );

    return <NewHeader language={languageEnum} onChangeLanguage={onChangeLanguage} />;
};

export default HeaderWrap;

import { getStepNumberByPage, PageEnum } from '../../route/page';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { WizardNavigationProps } from './wizardNavigationProps';
import { WizardNavigationStepSize } from './stepLinkWrap/step/wizardNavigationStepProps';
import { WizardNavigationStepType } from './stepLinkWrap/step/number/wizardNavigationStepNumberProps';
import React from 'react';
import styled from 'styled-components';
import WizardNavigationStepLinkWrap from './stepLinkWrap';

interface BoxComponentProps {
    readonly isSmallLayout: boolean;
    readonly isMediumLayout: boolean;
}

const smallMarginLeftAndRight = 10;
const mediumMarginLeftAndRight = 20;

const BoxComponent = styled.div`
    margin-left: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return smallMarginLeftAndRight;
        }
        if (props.isMediumLayout) {
            return mediumMarginLeftAndRight;
        }
        return 0;
    }}px;
    margin-right: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return smallMarginLeftAndRight;
        }
        if (props.isMediumLayout) {
            return mediumMarginLeftAndRight;
        }
        return 0;
    }}px;
`;

const WizardNavigation: React.FC<WizardNavigationProps> = (props) => {
    const { page, items } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    const size: WizardNavigationStepSize = isSmallLayout ? WizardNavigationStepSize.small : WizardNavigationStepSize.big;

    return (
        <div
            style={{
                height: 64,
                backgroundColor: '#FDFEFE',
                // boxShadow: '0 0 2px 0 rgba(0,0,0,0.24)',
                borderColor: 'rgba(0,0,0,0.24)',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                borderTopStyle: 'solid',
                borderBottomStyle: 'solid',
                borderWidth: 1,
            }}
        >
            <div
                style={{
                    height: 64,
                    backgroundColor: '#FDFEFE',
                    justifyContent: 'center',
                    ...(isSmallLayout === false && isMediumLayout === false
                        ? {
                              maxWidth: 944,
                              margin: '0 auto',
                          }
                        : {}),
                }}
            >
                <BoxComponent isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: page === PageEnum.congratulations || size === WizardNavigationStepSize.small ? 'center' : 'space-between',
                            position: 'relative',
                            background:
                                page === PageEnum.congratulations || size === WizardNavigationStepSize.small
                                    ? 'none'
                                    : 'linear-gradient(180deg, #FDFEFE calc(50% - 0px), rgba(0,0,0,0.32) calc(50%), #FDFEFE calc(50% + 1px))',
                            height: 64,
                            backgroundColor: '#FDFEFE',
                        }}
                    >
                        {items === undefined && <WizardNavigationStepLinkWrap page={page} size={size} type={WizardNavigationStepType.done} />}
                        {items !== undefined &&
                            items.map((pageItem) => {
                                return (
                                    <WizardNavigationStepLinkWrap
                                        key={pageItem}
                                        page={pageItem}
                                        size={pageItem === page ? WizardNavigationStepSize.big : size}
                                        type={((): WizardNavigationStepType => {
                                            const pageItemNumber = getStepNumberByPage(pageItem);
                                            const pageNumber = getStepNumberByPage(page);
                                            if (pageItemNumber < pageNumber) {
                                                return WizardNavigationStepType.done;
                                            }
                                            if (pageItemNumber === pageNumber) {
                                                return WizardNavigationStepType.current;
                                            }
                                            return WizardNavigationStepType.future;
                                        })()}
                                    />
                                );
                            })}
                    </div>
                </BoxComponent>
            </div>
        </div>
    );
};

export default WizardNavigation;

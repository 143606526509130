import { ContentBoxProps } from "component/contentBox/contentBoxProps";
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface BoxComponentProps {
    readonly isSmallLayout: boolean;
    readonly isMediumLayout: boolean;
}

const smallMarginLeftAndRight = 10;
const mediumMarginLeftAndRight = 20;

const BoxComponent = styled.div`
    margin-left: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return smallMarginLeftAndRight;
        }
        if (props.isMediumLayout) {
            return mediumMarginLeftAndRight;
        }
        return 0;
    }}px;
    margin-right: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return smallMarginLeftAndRight;
        }
        if (props.isMediumLayout) {
            return mediumMarginLeftAndRight;
        }
        return 0;
    }}px;
`;

interface WrapProps {
    readonly isSmallLayout: boolean;
}

const Wrap = styled.div`
    background-color: ${(props: ContentBoxProps) => {
        return props.transparentBackground === true ? 'transparent' : 'rgba(255, 255, 255, 0.64)';
    }};
    background: ${(props: ContentBoxProps) => {
        return props.transparentBackground === true ? 'transparent' : 'linear-gradient(180deg, #f0f5f7 0%, #d7e5eb 100%)';
    }};
    min-height: ${(props: WrapProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        return '700px';
    }};
    padding-bottom: 0;
    padding-top: ${(props: WrapProps) => {
        if (props.isSmallLayout) {
            return 128;
        }
        return 0;
    }}px;
`;

const ContentBox: React.FC<ContentBoxProps> = (props) => {
    const { children, transparentBackground } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    return (
        <Wrap transparentBackground={transparentBackground} isSmallLayout={isSmallLayout}>
            <div
                style={{
                    justifyContent: 'center',
                    ...(isSmallLayout === false && isMediumLayout === false
                        ? {
                              maxWidth: 944,
                              margin: '0 auto',
                          }
                        : {}),
                }}
            >
                <BoxComponent isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                    {children}
                </BoxComponent>
            </div>
        </Wrap>
    );
};

export default ContentBox;

import { CleaningServiceSelectProps, CleaningServiceSelectSize } from './cleaningServiceSelectProps';
import { Controller } from 'react-hook-form';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, useTheme } from '@material-ui/core';
import { translateCleaningFrequencyCode } from '../../../model/cleaningService/cleaningFrequency/cleaningFrequencyCodeEnum';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import MonthsCheckboxes from '../../form/months';
import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isError: boolean;
    readonly size: CleaningServiceSelectSize;
}

const BoxWrap = styled.div`
    height: 76px;
    width: ${(props: BoxWrapProps) => {
        // const full = props.isError ? 'calc(100% - 50px)' : '100%';
        switch (props.size) {
            case CleaningServiceSelectSize.full:
                return 'auto';
            case CleaningServiceSelectSize.small:
                return '226px';
            case CleaningServiceSelectSize.big:
                return '376px';
            default:
                throw new Error('unexpected CleaningServiceSelectSize in BoxWrap');
        }

        // return props.size === CleaningServiceSelectSize.full ? '100%' : '226px';
    }};
`;

const CleaningServiceSelect: React.FC<CleaningServiceSelectProps> = (props) => {
    const { name, control, frequencies, error, size, monthsCheckboxes } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const frequenciesSorted = frequencies.sort((frequencyA, frequencyB) => {
        const regExp = new RegExp(/^\d+/);

        const codeA = frequencyA.code;
        const numberStringA = codeA.match(regExp);
        if (numberStringA === null) {
            return 0;
        }
        const numberA = parseInt(numberStringA[0]);

        const codeB = frequencyB.code;
        const numberStringB = codeB.match(regExp);
        if (numberStringB === null) {
            return 0;
        }
        const numberB = parseInt(numberStringB[0]);

        if (numberA > numberB) {
            return 1;
        }
        return -1;
    });

    return (
        <BoxWrap size={size} isError={error !== undefined}>
            <FormControl variant={'outlined'} fullWidth={true}>
                <InputLabel>{flc(t('order.screen.cleaningService.cleaningFrequencyLabel'))}</InputLabel>
                <Controller
                    control={control}
                    name={name}
                    as={
                        <Select
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                            fullWidth={true}
                            // style={{
                            //     width: error !== undefined ? 'calc(100% - 50px)' : '100%',
                            // }}
                            label={flc(t('order.screen.cleaningService.cleaningFrequencyLabel'))}
                            endAdornment={
                                error === undefined ? undefined : (
                                    <InputAdornment
                                        position={'start'}
                                        style={
                                            {
                                                // position: 'absolute',
                                            }
                                        }
                                    >
                                        <div
                                            style={{
                                                marginRight: 10,
                                                // position: 'absolute',
                                            }}
                                        >
                                            <ReportProblemIcon color={'secondary'} />
                                        </div>
                                    </InputAdornment>
                                )
                            }
                            inputProps={{
                                style: {
                                    width: '100px',
                                },
                            }}
                        >
                            {frequenciesSorted.map((frequency) => {
                                return (
                                    <MenuItem key={frequency.code} value={frequency.tariffConfigId} style={{color: frequency.price === null ? theme.palette.text.secondary : theme.palette.text.primary}}>
                                        {translateCleaningFrequencyCode(frequency.code)}&nbsp;<br/>

                                        {frequency.isRenewedAfter12months && <span style={{fontSize: "small"}}>{i18next.t('order.screen.cleaningService.renewedAfter12Months')}</span>}
                                        {!frequency.isRenewedAfter12months && <span style={{fontSize: "small"}}>{i18next.t('order.screen.cleaningService.noSubscription')}</span>}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    }
                />
            </FormControl>
            {error !== undefined && (
                <FormHelperText
                    style={{
                        color: theme.palette.secondary.main,
                        marginLeft: 10,
                    }}
                >
                    {error}
                </FormHelperText>
            )}
            {monthsCheckboxes !== null && (
                <MonthsCheckboxes
                    disabled={monthsCheckboxes.disabled}
                    selectedMonths={monthsCheckboxes.selectedMonths}
                    selectedMonthsHandlers={monthsCheckboxes.selectedMonthsHandlers}
                    size={monthsCheckboxes.size}
                    warning={monthsCheckboxes.warning}
                    error={monthsCheckboxes.error}
                />
            )}
        </BoxWrap>
    );
};

export default CleaningServiceSelect;

import { CleaningServiceBinBoxProps, MonthCheckboxData } from './cleaningServiceBinBoxProps';
import { CleaningServiceSelectSize } from './select/cleaningServiceSelectProps';
import { flc } from '../../utils/strings/firstLetterCapitalize';
import { NotAvailableMessageTextLength } from './notAvailableMessageBox/notAvailableMessageBoxProps';
import { TextInputSize } from '../form/textInput/textInputProps';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BinTitle from './binTitle';
import CleaningServiceSelect from './select';
import NotAvailableMessageBox from './notAvailableMessageBox';
import React from 'react';
import styled from 'styled-components';
import TextInput from '../form/textInput';

interface BoxWrapProps {
    readonly available: boolean;
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    border-width: ${(props: BoxWrapProps) => {
        return props.available === true ? 0 : 2;
    }}px;
    border-color: #ffbe01;
    border-style: solid;
    max-width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout === false && props.isMediumLayout === false) {
            return '944px';
        }
        return 'none';
    }};
    background-color: #fdfdfe;
    margin-bottom: 16px;
    border-radius: 2px;
`;

interface InputsWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
    readonly monthCheckboxData: MonthCheckboxData;
}

const InputsWrap = styled.div`
    display: flex;
    align-items: ${(props: InputsWrapProps) => {
        if (props.isSmallLayout) {
            return 'stretch';
        }
        return 'center';
    }};
    flex-direction: ${(props: InputsWrapProps) => {
        if (props.isSmallLayout) {
            return 'column';
        }
        return 'row';
    }};
    padding-top: ${(props: InputsWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return 16;
    }}px;
    padding-bottom: ${(props: InputsWrapProps) => {
        if (props.isSmallLayout) {
            if (props.monthCheckboxData === null) {
                return 0;
            }
            return 120;
        }
        if (props.monthCheckboxData === null) {
            return 30;
        }
        return 80;
    }}px;
`;

interface TitleWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const TitleWrap = styled.div`
    flex-grow: ${(props: TitleWrapProps) => {
        if (props.isMediumLayout || !props.isSmallLayout) {
            return 1;
        }
        return 'initial';
    }};
    margin-left: ${(props: TitleWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        if (props.isMediumLayout) {
            return 24;
        }
        return 40;
    }}px;
    margin-top: ${(props: TitleWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        return 0;
    }}px;
`;

interface CountWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const CountWrap = styled.div`
    flex-grow: 0;
    margin-left: ${(props: CountWrapProps) => {
        if (props.isSmallLayout) {
            return 56;
        }
        if (props.isMediumLayout) {
            return 12;
        }
        return 12;
    }}px;
    margin-right: ${(props: CountWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        if (props.isMediumLayout) {
            return 12;
        }
        return 12;
    }}px;
    flex-basis: ${(props: CountWrapProps) => {
        if (props.isMediumLayout) {
            return '144px';
        }
        if (props.isSmallLayout) {
            return 0;
        }
        return '144px';
    }};
    position: relative;
    top: ${(props: CountWrapProps) => {
        if (props.isMediumLayout) {
            return 10;
        }
        if (props.isSmallLayout) {
            return 0;
        }
        return 10;
    }}px;
    margin-top: ${(props: CountWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        return 0;
    }}px;
`;

interface SelectWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const SelectWrap = styled.div`
    margin-left: ${(props: SelectWrapProps) => {
        if (props.isSmallLayout) {
            return 56;
        }
        if (props.isMediumLayout) {
            return 12;
        }
        return 12;
    }}px;
    margin-right: ${(props: SelectWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        if (props.isMediumLayout) {
            return 24;
        }
        return 24;
    }}px;
    position: relative;
    top: ${(props: SelectWrapProps) => {
        if (props.isMediumLayout) {
            return 10;
        }
        if (props.isSmallLayout) {
            return 0;
        }
        return 10;
    }}px;
    margin-top: ${(props: SelectWrapProps) => {
        if (props.isSmallLayout) {
            return 12;
        }
        return 0;
    }}px;
`;

const CleaningServiceBinBox: React.FC<CleaningServiceBinBoxProps> = (props) => {
    const { control, binType, inputRef, cleaningFrequencies, binColor, available, cleaningFrequencyError, cleaningFrequencyName, countName, countError, monthsCheckboxes } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    return (
        <BoxWrap available={available} isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
            <InputsWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout} monthCheckboxData={monthsCheckboxes}>
                <TitleWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                    <BinTitle binColor={binColor} binType={binType} />
                </TitleWrap>
                <CountWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                    <TextInput
                        name={countName}
                        size={isMediumLayout ? TextInputSize.binCount : TextInputSize.full}
                        label={flc(t('order.screen.cleaningService.countLabel'))}
                        inputRef={inputRef}
                        html5Type="number"
                        error={countError}
                        htmlMin={0}
                    />
                </CountWrap>
                <SelectWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                    <CleaningServiceSelect
                        frequencies={cleaningFrequencies}
                        name={cleaningFrequencyName}
                        control={control}
                        size={((): CleaningServiceSelectSize => {
                            if (isSmallLayout) {
                                return CleaningServiceSelectSize.full;
                            }
                            if (isMediumLayout) {
                                return CleaningServiceSelectSize.big;
                            }
                            return CleaningServiceSelectSize.big;
                        })()}
                        error={cleaningFrequencyError}
                        monthsCheckboxes={monthsCheckboxes}
                    />
                </SelectWrap>
            </InputsWrap>
            <div>{available === false && <NotAvailableMessageBox textLength={NotAvailableMessageTextLength.long} />}</div>
        </BoxWrap>
    );
};

export default CleaningServiceBinBox;

import * as z from "zod";
import { binCleaningAddressRoute } from "route/routeBinCleaning";
import { BinColorEnum } from "model/cleaningService/binColor/binColorSchema";
import { BinTypeEnum } from "model/cleaningService/binType/binTypeEnum";
import { CleaningFrequencyCodeEnum } from "model/cleaningService/cleaningFrequency/cleaningFrequencyCodeEnum";
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { getSubtitleForPageEnum, getTitleForPageEnum, PageEnum } from '../../../route/page';
import { RouteComponentProps, useHistory } from "react-router-dom";
import ContentBox from '../../contentBox';
import FooterWrap from 'component/footerWrap';
import GoogleTagManager from '../../system/googleTagManager';
import HeaderWrap from '../../headerWrap';
import OrderTitle from '../../orderTitle';
import React, { useEffect } from 'react';
import Signpost from './signpost';
import styled from 'styled-components';
import useSetDefaultsWasSetAction from "store/order/hook/defaults/useSetDefaults";

const ContentBoxTop = styled.div``;

const ContentBoxIn = styled.div`
    height: 100%; ;
`;

export const PostalCodeSchema = z.object({
    id: z.number().int(),
    code: z.string(),
});

export const SelectedMonthsSchema = z.object({
    january: z.boolean(),
    february: z.boolean(),
    march: z.boolean(),
    april: z.boolean(),
    may: z.boolean(),
    june: z.boolean(),
    july: z.boolean(),
    august: z.boolean(),
    september: z.boolean(),
    october: z.boolean(),
    november: z.boolean(),
    december: z.boolean(),
});

export const TariffItemSchema = z.object({
    binColor: z.nativeEnum(BinColorEnum).nullable(),
    binType: z.nativeEnum(BinTypeEnum),
    cleaningFrequency: z.nativeEnum(CleaningFrequencyCodeEnum),
    count: z.number().int().min(0),
    selectedMonths: SelectedMonthsSchema.nullable(),
    tariffConfigId: z.number().int().min(0),
});

export const DefaultsSchema = z.object({
    postalCode: PostalCodeSchema,
    tariffItems: z.array(TariffItemSchema),
});

export type Defaults = z.infer<typeof DefaultsSchema>;

const parseDefaults = (defaults: string|null): Defaults | null => {
    if (defaults === null) {
        return null;
    }
    try {
        const data = JSON.parse(defaults);
        return DefaultsSchema.parse(data);
    } catch (e) {
        console.error(e);
        return null;
    }
}

const WelcomePage: React.FC<RouteComponentProps> = ({location }) => {
    const history = useHistory();
    const setDefaultsWasSet = useSetDefaultsWasSetAction();

    useEffect(() => {
        (async () => {
            const defaults = parseDefaults(new URLSearchParams(location.search).get('defaults'));
            if (defaults === null) {
                return;
            }

            await setDefaultsWasSet(defaults);
            history.push(binCleaningAddressRoute.url());
        })();

    }, [location.search, setDefaultsWasSet, history]);

    return (
        <>
            <GoogleTagManager />
            <HeaderWrap />
            <ContentBox>
                <ContentBoxIn>
                    <ContentBoxTop>
                        <OrderTitle title={flc(getTitleForPageEnum(PageEnum.welcome))} subtitle={getSubtitleForPageEnum(PageEnum.welcome)} />
                    </ContentBoxTop>
                    <Signpost />
                </ContentBoxIn>
            </ContentBox>
            <FooterWrap />
        </>
    );
};

export default WelcomePage;

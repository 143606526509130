import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, useMediaQuery, useTheme } from '@material-ui/core';
import { TextInputProps, TextInputSize } from './textInputProps';
import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
    readonly size: TextInputSize;
}

const BoxWrap = styled.div`
    height: 76px;
    width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        switch (props.size) {
            case TextInputSize.binCount:
                return 144;
            case TextInputSize.big:
                return 456;
            case TextInputSize.small:
                return 236;
            case TextInputSize.full:
                return 'auto';
            default:
                throw new Error('unexpected TextInputSize in BoxWrap');
        }
    }}px;
`;

const TextInput: React.FC<TextInputProps> = (props) => {
    const { onChange, size, error, name, inputRef, disabled, rows, label, placeholder, html5Type, value, htmlMin } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BoxWrap size={size} isSmallLayout={isSmallLayout}>
            <FormControl variant={'outlined'} fullWidth={true}>
                {label !== undefined && <InputLabel error={error !== undefined}>{label}</InputLabel>}
                <OutlinedInput
                    name={name}
                    disabled={disabled}
                    onChange={onChange}
                    rows={rows}
                    placeholder={placeholder}
                    endAdornment={
                        error === undefined ? undefined : (
                            <InputAdornment position={'start'}>
                                <ReportProblemIcon color={'secondary'} />
                            </InputAdornment>
                        )
                    }
                    label={label}
                    error={error !== undefined}
                    inputRef={inputRef}
                    type={html5Type}
                    multiline={rows !== undefined && rows > 1}
                    inputProps={
                        htmlMin === undefined
                            ? undefined
                            : {
                                  min: htmlMin,
                              }
                    }
                    // style={{
                    //     backgroundColor: '#ffffff',
                    // }}
                    // value={val}
                    value={value}
                />
            </FormControl>
            {error !== undefined && (
                <FormHelperText variant="outlined" error>
                    {error}
                </FormHelperText>
            )}
        </BoxWrap>
    );
};

export default TextInput;

import { AppLanguageEnum, getAbbreviationsByAppLanguageEnum } from 'localization/appLanguage';
import { embeddedCalculatorRoute } from 'route/routeBinCleaning';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect } from 'react';

const EmbeddedCalculatorFr: React.FC<{}> = () => {
    const history = useHistory();
    useEffect(() => {
        i18next.changeLanguage(getAbbreviationsByAppLanguageEnum(AppLanguageEnum.fr)).then(() => {
            history.push(embeddedCalculatorRoute.url());
        });
    }, [history]);

    return null;
};

export default EmbeddedCalculatorFr;

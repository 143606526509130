import { orderWasCreated } from 'store/order/action';
import { useCallback } from 'react';
import useDispatchTyped from 'store/hook/useDispatchTyped/useDispatchTyped';

const useSetOrderWasCreated = () => {
    const dispatch = useDispatchTyped();

    return useCallback(async () => {
        await dispatch(orderWasCreated());
    }, [dispatch]);
};

export default useSetOrderWasCreated;

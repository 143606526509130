import { AppState } from '../../../../appState';
import { RatControlData } from '../../../state';
import { useSelector } from 'react-redux';

const useRatControlData = (): RatControlData | undefined => {
    const ratControlData = useSelector<AppState, RatControlData | undefined>((state) => {
        return state.order.ratControlData;
    });

    return ratControlData;
};

export default useRatControlData;

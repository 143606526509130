import * as z from 'zod';

export enum BinColorEnum {
    yellow = 'yellow',
    black = 'black',
    brown = 'brown',
    green = 'green',
    blue = 'blue',
}

export const BinColorSchema = z.object({
    id: z.number().int(),
    code: z.nativeEnum(BinColorEnum),
    description: z.string(),
});

export type BinColor = z.infer<typeof BinColorSchema>;

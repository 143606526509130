import { AppState } from '../../../../appState';
import { useSelector } from 'react-redux';

const usePostalCodeIsNotInSystem = (): boolean => {
    const postalCodeIsNotInSystem = useSelector<AppState, boolean>((state) => {
        return state.order.postalCodeIsNotInSystem;
    });

    return postalCodeIsNotInSystem;
};

export default usePostalCodeIsNotInSystem;

import { Box, Typography } from "@material-ui/core";
import { ConfirmDataProps } from './confirmDataProps';
import { flc } from '../../utils/strings/firstLetterCapitalize';
import { useTranslation } from 'react-i18next';
import ConfirmDataInfoBox from './infoBox';
import React, { memo } from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div``;

const BoxDataInfoBox = styled.div`
    margin-bottom: 14px;
`;

const ConfirmData: React.FC<ConfirmDataProps> = memo((props) => {
    const { deliveryClientData, billingClientData, children } = props;

    const { t } = useTranslation();

    const address: string =
        deliveryClientData.address.streetNo + ', ' + deliveryClientData.address.street.name + ', L-' + deliveryClientData.address.postalCode.code + ' ' + deliveryClientData.address.city.name;
    const billingAddress: string | null =
        billingClientData === null
            ? null
            : billingClientData.address.streetNo + ', ' + billingClientData.address.street.name + ', L-' + billingClientData.address.postalCode.code + ' ' + billingClientData.address.city.name;

    return (
        <BoxWrap>
            <Box mb={4}>
                <Typography variant={'h5'}>
                    {flc(t('order.screen.confirmation.titles.deliveryAddress'))}
                </Typography>
            </Box>
            {deliveryClientData.person !== undefined && (
                <BoxDataInfoBox>
                    <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.fullName'))} value={`${deliveryClientData.person.firstName} ${deliveryClientData.person.lastName}`} />
                </BoxDataInfoBox>
            )}
            {deliveryClientData.company !== undefined && (
                <BoxDataInfoBox>
                    <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.company'))} value={`${deliveryClientData.company.name} ${deliveryClientData.company.vatNumber}`} />
                </BoxDataInfoBox>
            )}
            <BoxDataInfoBox>
                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.address'))} value={address} />
            </BoxDataInfoBox>
            <BoxDataInfoBox>
                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.email'))} value={deliveryClientData.email} />
            </BoxDataInfoBox>
            <BoxDataInfoBox>
                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.phone'))} value={deliveryClientData.phone} />
            </BoxDataInfoBox>

            {billingClientData !== null && billingAddress !== null && (
                <>
                    <Box mt={4} mb={4}>
                        <Typography variant={'h5'}>
                            {flc(t('order.screen.confirmation.titles.billingAddress'))}
                        </Typography>
                    </Box>
                    {billingClientData.person !== undefined && (
                        <BoxDataInfoBox>
                            <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.fullName'))} value={`${billingClientData.person.firstName} ${billingClientData.person.lastName}`} />
                        </BoxDataInfoBox>
                    )}
                    {billingClientData.company !== undefined && (
                        <>
                            <BoxDataInfoBox>
                                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.company'))} value={`${billingClientData.company.name} ${billingClientData.company.vatNumber}`} />
                            </BoxDataInfoBox>
                        </>
                    )}
                    <BoxDataInfoBox>
                        <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.address'))} value={billingAddress} />
                    </BoxDataInfoBox>
                    <BoxDataInfoBox>
                        <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.email'))} value={billingClientData.email} />
                    </BoxDataInfoBox>
                    <BoxDataInfoBox>
                        <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.phone'))} value={billingClientData.phone} />
                    </BoxDataInfoBox>
                </>
            )}
            {children}
        </BoxWrap>
    );
});

export default ConfirmData;

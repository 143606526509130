import { ClientData } from 'store/order/state';
import { CompanyValues, CustomerType, PersonValues } from 'component/form/addressForm/index';

export const clientDataToAddressValues = (client: ClientData): PersonValues | CompanyValues => {
    if (client.person !== undefined) {
        return {
            type: CustomerType.person,
            zip: { id: client.address.postalCode.id, name: client.address.postalCode.code },
            streetNo: client.address.streetNo,
            street: client.address.street,
            city: client.address.city,
            personFirstName: client.person.firstName,
            personLastName: client.person.lastName,
            email: client.email,
            phone: client.phone,
        };
    } else if (client.company !== undefined) {
        return {
            type: CustomerType.company,
            zip: { id: client.address.postalCode.id, name: client.address.postalCode.code },
            streetNo: client.address.streetNo,
            street: client.address.street,
            city: client.address.city,
            companyName: client.company.name,
            companyVatNumber: client.company.vatNumber,
            email: client.email,
            phone: client.phone,
        };
    } else {
        throw Error('person or company has to be set!');
    }
};

export const addressValuesToClientData = (values: PersonValues | CompanyValues): ClientData => ({
    person:
        'personFirstName' in values
            ? {
                  firstName: values.personFirstName,
                  lastName: values.personLastName,
              }
            : undefined,
    company:
        'companyName' in values
            ? {
                  name: values.companyName,
                  vatNumber: values.companyVatNumber,
              }
            : undefined,
    email: values.email,
    phone: values.phone.replace(' ', ''),
    address: {
        city: values.city,
        postalCode: { id: values.zip.id, code: values.zip.name },
        street: values.street,
        streetNo: values.streetNo,
    },
});

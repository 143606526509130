export enum AppLanguageEnum {
    english = 'en',
    fr = 'fr',
    luxembourgish = 'lu',
}

export const getAbbreviationsByAppLanguageEnum = (appLanguage: AppLanguageEnum) => {
    switch (appLanguage) {
        case AppLanguageEnum.english:
            return 'en';
        case AppLanguageEnum.fr:
            return 'fr';
        case AppLanguageEnum.luxembourgish:
            return 'lu';
        default:
            throw new Error('unexpected AppLanguageEnum in getAbbreviationsByAppLanguageEnum');
    }
};

export const getNameByAppLanguageEnum = (appLanguage: AppLanguageEnum) => {
    switch (appLanguage) {
        case AppLanguageEnum.english:
            return 'english';
        case AppLanguageEnum.fr:
            return 'french';
        case AppLanguageEnum.luxembourgish:
            return 'luxembourgish';
        default:
            throw new Error('unexpected AppLanguageEnum in getAbbreviationsByAppLanguageEnum');
    }
};

export const findLanguageFromUnknown = (value: unknown): AppLanguageEnum | null => {
    if (value === AppLanguageEnum.english) {
        return AppLanguageEnum.english;
    }
    if (value === AppLanguageEnum.fr) {
        return AppLanguageEnum.fr;
    }
    if (value === AppLanguageEnum.luxembourgish) {
        return AppLanguageEnum.luxembourgish;
    }
    return null;
};

export const getLocaleByLanguageStringWithUndefined = (language: string | undefined): string => {
    if (language === AppLanguageEnum.fr) {
        return 'fr_FR';
    }
    if (language === AppLanguageEnum.luxembourgish) {
        return 'lb_LU';
    }

    return 'en_US';
};

import { ButtonVariant } from '../../../../button/buttonProps';
import { SignpostCompaniesOptionProps } from './signpostCompaniesOptionProps';
import { useTranslation } from 'react-i18next';
import React from 'react';
import SignpostOption from '../option';
import WorkIcon from '@material-ui/icons/Work';

const SignpostCompaniesOption: React.FC<SignpostCompaniesOptionProps> = () => {
    const { t } = useTranslation();

    const mailSubject = t('order.screen.welcome.signpost.companies.mailSubject');

    return (
        <SignpostOption
            button={{
                variant: ButtonVariant.outlined,
                text: t('order.screen.welcome.signpost.companies.buttonText'),
                arrowContinue: true,
                onClick: () => {
                    window.open('mailto:info@adys.lu?subject=' + mailSubject);
                },
            }}
            Logo={WorkIcon}
            title={t('order.screen.welcome.signpost.companies.title')}
        />
    );
};

export default SignpostCompaniesOption;

import { AppAction } from '../../appAction';
import { DispatchTyped } from './dispatchTyped';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useDispatchTyped = (): DispatchTyped => {
    const dispatch = useDispatch();

    const callback = useCallback(
        async (action: AppAction) => {
            await dispatch(action);
        },
        [dispatch],
    );

    return callback;
};

export default useDispatchTyped;

import * as z from 'zod';
import { BinTypeEnum } from './binTypeEnum';

export const BinTypeSchema = z.object({
    id: z.number().int(),
    code: z.nativeEnum(BinTypeEnum),
    description: z.string(),
});

export type BinType = z.infer<typeof BinTypeSchema>;

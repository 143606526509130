import { AppLanguageEnum, findLanguageFromUnknown } from 'localization/appLanguage';
import Footer from 'component/footer';
import i18next from 'i18next';
import React from 'react';

const FooterWrap: React.FC<{}> = () => {
    const language = i18next.language;
    const languageFound = findLanguageFromUnknown(language);
    const languageEnum = languageFound === null ? AppLanguageEnum.english : languageFound;

    return <Footer language={languageEnum} />;
};

export default FooterWrap;

import { makeStyles } from '@material-ui/core';
import { SubmenuProps } from 'component/newHeader/Submenu/submenuProps';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '22px',
        padding: 0,
        display: 'flex',
        backgroundColor: theme.palette.grey[50],
        zIndex: 1000,

        [theme.breakpoints.up('md')]: {
            padding: '16px',
            position: 'absolute',
        },

        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
}));

const Submenu: React.FC<SubmenuProps> = (props) => {
    const { style } = props;
    const classes = useStyles();

    return (
        <ul className={classes.root} style={style}>
            {props.children}
        </ul>
    );
};

export default Submenu;

import { IconButton, makeStyles } from '@material-ui/core';
import { ToggleButtonProps } from 'component/newHeader/ToggleButton/toggleButtonProps';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';

const useStyles = makeStyles({
    root: {
        height: '26px',
        width: '26px',
    },
});

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
    const { active, onClick } = props;
    const classes = useStyles();

    return (
        <IconButton classes={{ root: classes.root }} color="primary" component="span" onClick={onClick}>
            {active ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
        </IconButton>
    );
};

export default ToggleButton;

import { flc } from 'utils/strings/firstLetterCapitalize';
import { InfoBoxInsectControlProps } from 'component/confirmData/infoBoxInsectControl/infoBoxInsectControlProps';
import { translateInsectControlTypeEnum } from 'model/insectControl/insectControlType/insectControlTypeEnum';
import { useTranslation } from 'react-i18next';
import ConfirmDataInfoBox from 'component/confirmData/infoBox';
import React from 'react';
import styled from 'styled-components';

const BoxDataInfoBox = styled.div`
    margin-bottom: 24px;
`;

const InfoBoxInsectControl: React.FC<InfoBoxInsectControlProps> = (props) => {
    const { problem, description } = props;
    const { t } = useTranslation();

    return (
        <>
            <BoxDataInfoBox>
                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.problem'))} value={translateInsectControlTypeEnum(problem)} />
            </BoxDataInfoBox>
            {description && (
                <BoxDataInfoBox>
                    <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.description'))} value={description} />
                </BoxDataInfoBox>
            )}
        </>
    );
};

export default InfoBoxInsectControl;

import { ConfirmDataValueProps } from './confirmDataValueProps';
import { Typography } from '@material-ui/core';
import React from 'react';

const ConfirmDataValue: React.FC<ConfirmDataValueProps> = (props) => {
    const { value } = props;

    return (
        <Typography
            style={{
                color: '#000000',
                fontSize: 16,
                letterSpacing: 0,
                fontWeight: 600,
            }}
        >
            {value}
        </Typography>
    );
};

export default ConfirmDataValue;

export enum TranslationNamespaceEnum {
    app = 'app',
}

export const addTranslationNamespace = (translationNamespaceEnum: TranslationNamespaceEnum, key: string) => {
    return `${translationNamespaceEnum.toString()}:${key}`;
};

export const addAppNs = (key: string) => {
    return addTranslationNamespace(TranslationNamespaceEnum.app, key);
};

import { makeStyles } from '@material-ui/core';
import { MenuLinkProps } from 'component/newHeader/MenuLink/menuLinkProps';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            margin: '12px 0',
        },
    },
    link: {
        fontSize: '16px',
        color: '#00A8E0',
        fontWeight: 700,
        display: 'block',
        position: 'relative',
        lineHeight: '24px',
        padding: '12px 14px',
        transition: '.2s',
        textDecoration: 'none',

        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        },
    },
}));

const MenuLink: React.FC<MenuLinkProps> = (props) => {
    const { href } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <a href={href} className={classes.link}>
                {props.children}
            </a>
        </div>
    );
};

export default MenuLink;

import { SwitchProps } from 'component/form/switchInput/switchProps';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import SwitchTabs from 'component/switchTabs';

const SwitchInput: React.FC<SwitchProps> = (props) => {
    const { name, items } = props;
    const { register, setValue, watch } = useFormContext();

    const type = watch(name);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(name, newValue);
    };

    return (
        <>
            <SwitchTabs items={items} value={type} onChange={handleChange} />

            {items.map((item) => (
                <input key={item.value} type="radio" name={name} value={item.value} ref={register} style={{ display: 'none' }} />
            ))}
        </>
    );
};

export default SwitchInput;

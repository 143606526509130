import { Box, Typography } from '@material-ui/core';
import { ServiceTypeIconSize } from 'component/serviceTypeIcon/serviceTypeIconSize';
import { TitleTypeServiceProps } from 'component/titleTypeService/titleTypeServiceProps';
import { translateServiceType } from 'common/serviceType';
import React from 'react';
import ServiceTypeIcon from 'component/serviceTypeIcon';
import styled from 'styled-components';

const BoxComponent = styled.div`
    display: flex;
    align-items: center;
`;

const TitleTypeService: React.FC<TitleTypeServiceProps> = (props) => {
    const { serviceType, leftIcon } = props;

    const text = translateServiceType(serviceType);

    return (
        <BoxComponent>
            <Box order={leftIcon ? 2 : 1}>
                <Typography
                    style={{
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        marginRight: leftIcon ? 0 : 10,
                        marginLeft: leftIcon ? 10 : 0,
                    }}
                >
                    {text.toUpperCase()}
                </Typography>
            </Box>
            <Box order={leftIcon ? 1 : 2}>
                <ServiceTypeIcon serviceType={serviceType} size={ServiceTypeIconSize.small} />
            </Box>
        </BoxComponent>
    );
};

export default TitleTypeService;

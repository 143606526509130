import { LogoProps } from './logoProps';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as ReactLogo } from '../../assets/logo/logo.svg';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'block',

        '& #text-adys': {
            fill: (props) => (props.inverse ? 'white' : '#00A8E0'),
        },
    },
});

const Logo: React.FC<LogoProps> = (props) => {
    const classes = useStyles(props);

    return <ReactLogo className={classes.root} />;
};

export default Logo;

import { MonthsCheckboxLabelProps } from './monthsCheckboxLabelProps';
import { Typography } from '@material-ui/core';
import React from 'react';

const MonthsCheckboxLabel: React.FC<MonthsCheckboxLabelProps> = (props) => {
    const { text } = props;

    return (
        <Typography
            style={{
                fontSize: 12,
                color: 'rgba(0, 0, 0, 0.638694)',
            }}
            variant={'body1'}
        >
            {text}
        </Typography>
    );
};

export default MonthsCheckboxLabel;

export interface AutocompleteSelectItem {
    readonly id: number;
    readonly name: string;
}

export enum AutocompleteSelectSize {
    small = 'small',
    big = 'big',
}

export interface AutocompleteSelectProps {
    readonly addLabelText?: string;
    readonly addNew?: boolean;
    readonly disabled?: boolean;
    readonly error?: string;
    readonly items: AutocompleteSelectItem[];
    readonly label?: string;
    readonly name: string;
    readonly size: AutocompleteSelectSize;
    readonly filterMinLength?: number;
    readonly inputValueString?: string;
    readonly setConfirmed?: (value: undefined | AutocompleteSelectItem) => void;
    readonly setInputValueString?: (values: string) => void;
    readonly setNoErrors?: () => void;
    readonly value?: AutocompleteSelectItem;
}

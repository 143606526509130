import { Route } from 'route/route';

export const embeddedCalculatorRoute: Route = {
    url: () => '/embedded-calculator',
    pattern: () => '/embedded-calculator',
};

export const embeddedCalculatorFrRoute: Route = {
    url: () => '/fr/embedded-calculator',
    pattern: () => '/fr/embedded-calculator',
};

export const embeddedCalculatorLbRoute: Route = {
    url: () => '/lu/embedded-calculator',
    pattern: () => '/lu/embedded-calculator',
};

export const binCleaningDefaultRoute: Route = {
    url: () => '/bin-cleaning',
    pattern: () => '/bin-cleaning',
};

export const binCleaningFrRoute: Route = {
    url: () => '/bin-cleaning/fr',
    pattern: () => '/bin-cleaning/fr',
};

export const binCleaningLbRoute: Route = {
    url: () => '/bin-cleaning/lu',
    pattern: () => '/bin-cleaning/lu',
};

export const binCleaningWelcomeRoute: Route = {
    url: () => '/bin-cleaning',
    pattern: () => '/bin-cleaning',
};

export const binCleaningAddressRoute: Route = {
    url: () => '/bin-cleaning/address',
    pattern: () => '/bin-cleaning/address',
};

export const binCleaningServiceRoute: Route = {
    url: () => '/bin-cleaning/cleaningService',
    pattern: () => '/bin-cleaning/cleaningService',
};

export const binCleaningConfirmationRoute: Route = {
    url: () => '/bin-cleaning/confirmation',
    pattern: () => '/bin-cleaning/confirmation',
};

export const binCleaningPaymentRoute: Route = {
    url: () => '/bin-cleaning/payment',
    pattern: () => '/bin-cleaning/payment',
};

export const binCleaningPersonalInformationRoute: Route = {
    url: () => '/bin-cleaning/personalInformation',
    pattern: () => '/bin-cleaning/personalInformation',
};

export const binCleaningSuccessRoute: Route = {
    url: () => '/bin-cleaning/success',
    pattern: () => '/bin-cleaning/success',
};

import { flc } from 'utils/strings/firstLetterCapitalize';
import { InfoBoxRatControlProps } from 'component/confirmData/infoBoxRatControl/infoBoxRatControlProps';
import { translateRatControlTypeEnum } from 'model/ratControl/ratControlType/ratControlTypeEnum';
import { useTranslation } from 'react-i18next';
import ConfirmDataInfoBox from 'component/confirmData/infoBox';
import React from 'react';
import styled from 'styled-components';

const BoxDataInfoBox = styled.div`
    margin-bottom: 24px;
`;

const InfoBoxRatControl: React.FC<InfoBoxRatControlProps> = (props) => {
    const { problem, description } = props;
    const { t } = useTranslation();

    return (
        <>
            <BoxDataInfoBox>
                <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.problem'))} value={translateRatControlTypeEnum(problem)} />
            </BoxDataInfoBox>
            {description && (
                <BoxDataInfoBox>
                    <ConfirmDataInfoBox title={flc(t('order.screen.confirmation.titles.description'))} value={description} />
                </BoxDataInfoBox>
            )}
        </>
    );
};

export default InfoBoxRatControl;

import { addressValuesToClientData, clientDataToAddressValues } from 'component/form/addressForm/mapper';
import { binCleaningServiceRoute, binCleaningSuccessRoute } from 'route/routeBinCleaning';
import { ButtonVariant } from '../../button/buttonProps';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getNavigationNameForPageEnum, getSubtitleForPageEnum, getTitleForPageEnum, PageEnum } from 'route/page';
import { getUrlAdysHomepage } from 'config/url';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddressForm, { AddressFormValues } from 'component/form/addressForm';
import PageWrapper from 'component/pageWrapper';
import React, { useEffect } from 'react';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useSetBillingClientWasSetAction from 'store/order/hook/address/useSetBillingClientWasSetAction/useSetBillingClientWasSetAction';
import useSetDeliveryClientWasSetAction from 'store/order/hook/address/useSetDeliveryClientWasSetAction/useSetDeliveryClientWasSetAction';
import useSetPostalCodeIsNotInSystemAction from 'store/order/hook/postalCodeIsNotInSystem/useSetPostalCodeIsNotInSystemAction/useSetPostalCodeIsNotInSystemAction';
import useSetServiceTypeWasSetAction from 'store/order/hook/order/useSetServiceTypeWasSetAction/useSetServiceTypeWasSetAction';

const CleaningAddressPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const deliveryClient = useDeliveryClient();
    const setDeliveryClientWasSet = useSetDeliveryClientWasSetAction();
    const billingClient = useBillingClient();
    const setBillingClientWasSet = useSetBillingClientWasSetAction();
    const { setPostalCodeIsNotInSystemWasSet } = useSetPostalCodeIsNotInSystemAction();
    const setServiceTypeWasSet = useSetServiceTypeWasSetAction();

    useEffect(() => {
        setServiceTypeWasSet(ServiceType.binsCleaning);
    }, [setServiceTypeWasSet]);

    const onUnknownZip = (zip: string) => {
        const subject = t('order.screen.address.unexpectedZipMailSubject', { postalCode: zip });
        setPostalCodeIsNotInSystemWasSet();
        history.push(binCleaningSuccessRoute.url());
        window.open('mailto:info@adys.lu?subject=' + subject);
    };

    const onSubmit = async (values: AddressFormValues) => {
        await setDeliveryClientWasSet(addressValuesToClientData(values.delivery));
        await setBillingClientWasSet(values.billing !== undefined ? addressValuesToClientData(values.billing) : null);
        history.push(binCleaningServiceRoute.url());
    };

    return (
        <PageWrapper
            page={PageEnum.cleaningAddress}
            pageTitle={getNavigationNameForPageEnum(PageEnum.cleaningAddress)}
            serviceType={ServiceType.binsCleaning}
            wizardItems={[PageEnum.cleaningAddress, PageEnum.cleaningService, PageEnum.cleaningConfirm, PageEnum.cleaningPayment]}
            title={flc(getTitleForPageEnum(PageEnum.cleaningAddress))}
            subtitle={getSubtitleForPageEnum(PageEnum.cleaningAddress)}
            price={undefined}
            secondButton={{
                type: 'submit',
                form: 'addressForm',
                arrowContinue: true,
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    window.location.href = getUrlAdysHomepage();
                },
                text: t('order.navigation.cancel'),
                variant: ButtonVariant.outlined,
            }}
        >
            <AddressForm
                id="addressForm"
                onSubmit={onSubmit}
                onUnknownZip={onUnknownZip}
                showDeliveryTypeSwitch={false}
                defaultValues={{
                    delivery: deliveryClient !== undefined ? clientDataToAddressValues(deliveryClient) : undefined,
                    billing: billingClient !== undefined && billingClient !== null ? clientDataToAddressValues(billingClient) : undefined,
                }}
            />
        </PageWrapper>
    );
};

export default CleaningAddressPage;

import { OrderTitleProps } from './orderTitleProps';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';

const OrderTitle: React.FC<OrderTitleProps> = (props) => {
    const { title, subtitle, titleCenter, titleStyle } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    const paddingMain = useMemo(() => {
        if (isMediumLayout) {
            return 40;
        }
        if (isSmallLayout) {
            return 20;
        }
        return 40;
    }, [isMediumLayout, isSmallLayout]);

    return (
        <>
            <Typography
                variant={'h2'}
                style={{
                    paddingBottom: isSmallLayout ? 8 : 16,
                    paddingTop: paddingMain,
                    ...(isSmallLayout
                        ? {
                              fontSize: 32,
                          }
                        : {}),
                    ...titleStyle,
                }}
                align={titleCenter ? 'center' : 'inherit'}
            >
                {title}
            </Typography>
            <Typography
                variant={'h4'}
                style={{
                    paddingBottom: paddingMain,
                    ...(isSmallLayout
                        ? {
                              fontSize: 16,
                          }
                        : {}),
                }}
            >
                {subtitle}
            </Typography>
        </>
    );
};

export default OrderTitle;

import { ClickAwayListener, makeStyles } from '@material-ui/core';
import { MenuItemProps } from 'component/newHeader/MenuItem/menuItemProps';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',

        [theme.breakpoints.down('md')]: {
            margin: '12px 0',
        },
    },
    link: (props: MenuItemProps) => ({
        fontSize: '16px',
        color: props.active ? 'black' : '#00A8E0',
        fontWeight: 700,
        display: 'block',
        position: 'relative',
        lineHeight: '24px',
        padding: '12px 14px',
        transition: '.2s',
        textDecoration: 'none',

        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        },
    }),
    icon: (props: MenuItemProps) => ({
        position: 'absolute',
        left: '10px',
        bottom: '-8px',
        transform: props.active ? 'rotate(180deg)' : undefined,
        transition: '.2s',
    }),
}));

const MenuItem: React.FC<MenuItemProps> = (props) => {
    const { active, name, onClick, onClickAway } = props;
    const classes = useStyles(props);

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div className={classes.root}>
                <a className={classes.link} onClick={onClick}>
                    {name}
                    <ExpandMoreIcon className={classes.icon} />
                </a>
                {active ? props.children : null}
            </div>
        </ClickAwayListener>
    );
};

export default MenuItem;

import { AppState } from '../../../../appState';
import { ClientData } from '../../../state';
import { useSelector } from 'react-redux';

const useBillingClient = (): ClientData | undefined | null => {
    return useSelector<AppState, ClientData | undefined | null>((state) => {
        return state.order.billingClientData;
    });
};

export default useBillingClient;

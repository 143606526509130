import { binCleaningAddressRoute } from 'route/routeBinCleaning';
import { ButtonVariant } from '../../../../button/buttonProps';
import { SignpostIndividualsOptionProps } from './signpostIndividualsOptionProps';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FaceIcon from '@material-ui/icons/Face';
import React from 'react';
import SignpostOption from '../option';

const SignpostIndividualsOption: React.FC<SignpostIndividualsOptionProps> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <SignpostOption
            button={{
                variant: ButtonVariant.contained,
                text: t('order.screen.welcome.signpost.individuals.buttonText'),
                arrowContinue: true,
                onClick: () => {
                    history.push(binCleaningAddressRoute.url());
                },
            }}
            Logo={FaceIcon}
            title={t('order.screen.welcome.signpost.individuals.title')}
        />
    );
};

export default SignpostIndividualsOption;

import { FooterProps } from 'component/footer/footerProps';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Logo from 'component/logo';
import React from 'react';
import SocialIcons from 'component/footer/SocialIcons';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'GeneralSans,sans-serif',
        padding: '64px 0',
        backgroundColor: '#00A8E0',
        color: 'white',

        '& a': {
            color: 'white',
        },

        '& a:hover': {
            opacity: 0.7,
        },
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up(600)]: {
            width: '600px',
        },

        [theme.breakpoints.up(960)]: {
            width: '960px',
        },

        [theme.breakpoints.up(1140)]: {
            width: '1140px',
        },
    },
    logo: {},
    title: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    list: {
        fontSize: '14px',
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
}));

const Footer: React.FC<FooterProps> = (props) => {
    const { language } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Grid className={classes.container} container spacing={3}>
                <Grid item xs={12} md={2}>
                    <a href={'https://www.adys.lu/?lang=fr'} className={classes.logo}>
                        <Logo inverse />
                    </a>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.title}>{t('header.hygiene')}</div>
                            <ul className={classes.list}>
                                <li>
                                    <a href={`https://www.adys.lu/services/nettoyage-de-poubelles-conteneurs?lang=${language}`}>{t('header.cleaningOfWasteBins')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/sortie-et-rentree-des-poubelles?lang=${language}`}>{t('header.takingWasteBins')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/sortie-des-sacs-valorlux?lang=${language}`}>{t('header.takingOutValorluxBags')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/station-de-tri?lang=${language}`}>{t('header.sortingStation')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/elimination-de-dechets-encombrants?lang=${language}`}>{t('header.bulkyWasteRemoval')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/diffuseur-de-parfum?lang=${language}`}>{t('header.perfumeDiffuser')}</a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.title}>{t('header.pestControl')}</div>
                            <ul className={classes.list}>
                                <li>
                                    <a href={`https://www.adys.lu/services/disinfection/?lang=${language}`}>{t('header.disinfection')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/rat-control/?lang=${language}`}>{t('header.ratControl')}</a>
                                </li>
                                <li>
                                    <a href={`https://www.adys.lu/services/disinsection/?lang=${language}`}>{t('header.insectControl')}</a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.title}>{t('footer.other')}</div>
                            <ul className={classes.list}>
                                <li>
                                    <a href={`https://www.adys.lu/?lang=${language}`}>{t('footer.homepage')}</a>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <SocialIcons />
                </Grid>
            </Grid>
        </div>
    );
};

export default Footer;

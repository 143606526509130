import { binCleaningAddressRoute } from 'route/routeBinCleaning';
import { getUrlMoreInfoBinCleaning, getUrlMoreInfoInsectControl, getUrlMoreInfoRatControl } from 'config/url';
import { insectControlAddressRoute } from 'route/routeInsectControl';
import { makeStyles } from '@material-ui/core';
import { OtherServicesProps } from 'component/otherServices/OtherServicesProps';
import { ratControlAddressRoute } from 'route/routeRatControl';
import { ServiceType } from 'common/serviceType';
import OtherService from 'component/otherServices/otherService';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
}));

const OtherServices: React.FC<OtherServicesProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {ServiceType.binsCleaning !== props.currentService && (
                <OtherService service={ServiceType.binsCleaning} pathMoreInfo={getUrlMoreInfoBinCleaning()} pathOrderNow={binCleaningAddressRoute.url()} />
            )}
            {ServiceType.ratControl !== props.currentService && <OtherService service={ServiceType.ratControl} pathMoreInfo={getUrlMoreInfoRatControl()} pathOrderNow={ratControlAddressRoute.url()} />}
            {ServiceType.insectControl !== props.currentService && (
                <OtherService service={ServiceType.insectControl} pathMoreInfo={getUrlMoreInfoInsectControl()} pathOrderNow={insectControlAddressRoute.url()} />
            )}
        </div>
    );
};

export default OtherServices;

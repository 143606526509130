import { Box, Checkbox, FormControlLabel, FormHelperText, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonVariant } from '../../button/buttonProps';
import { confirmationSchema } from '../../../store/order/state';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { getNavigationNameForPageEnum, getTitleForPageEnum, PageEnum } from '../../../route/page';
import { getUrlWithConditionsAndTerms } from '../../../config/url';
import { ratControlAddressRoute, ratControlPaymentRoute, ratControlServiceRoute } from 'route/routeRatControl';
import { ServiceType } from 'common/serviceType';
import { TextInputSize } from '../../form/textInput/textInputProps';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import ConfirmData from 'component/confirmData';
import InfoBoxRatControl from 'component/confirmData/infoBoxRatControl';
import PageWrapper from 'component/pageWrapper';
import React, { useEffect, useRef } from 'react';
import TextInput from '../../form/textInput';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useConfirmationData from '../../../store/order/hook/confirmation/useConfirmationData/useConfirmationData';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useRatControlData from 'store/order/hook/ratControlService/useRatControlData/useRatControlData';
import useSetConfirmationDataWasSetAction from '../../../store/order/hook/confirmation/useSetMessageWasSetAction/useSetConfirmationDataWasSetAction';

interface FormInputs {
    readonly agreeTerms: true;
    readonly message: string | null;
}

const RatControlConfirmationPage: React.FC<{}> = () => {
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();
    const setConfirmationDataWasSetAction = useSetConfirmationDataWasSetAction();
    const history = useHistory();
    const ratControlData = useRatControlData();
    const deliveryClient = useDeliveryClient();
    const billingClient = useBillingClient();
    const confirmationData = useConfirmationData();

    const termsRef = useRef<HTMLFormElement>(null);

    const { register, errors, handleSubmit } = useForm<FormInputs>({
        resolver: zodResolver(confirmationSchema),
        defaultValues: {
            message: confirmationData?.message,
        },
    });

    const onSubmit = async (data: FormInputs) => {
        await setConfirmationDataWasSetAction(data);
        history.push(ratControlPaymentRoute.url());
    };

    const termsAndConditionsError = errors.agreeTerms?.type === 'invalid_literal_value';

    useEffect(() => {
        if (termsAndConditionsError === true) {
            // @ts-ignore
            termsRef.current.scrollIntoView();
        }
    }, [termsAndConditionsError]);

    if (deliveryClient === undefined || billingClient === undefined) {
        history.push(ratControlAddressRoute.url());
        return null;
    }
    if (ratControlData === undefined) {
        history.push(ratControlServiceRoute.url());
        return null;
    }

    return (
        <PageWrapper
            page={PageEnum.ratControlConfirm}
            pageTitle={getNavigationNameForPageEnum(PageEnum.ratControlConfirm)}
            serviceType={ServiceType.ratControl}
            wizardItems={[PageEnum.ratControlAddress, PageEnum.ratControlService, PageEnum.ratControlConfirm, PageEnum.ratControlPayment]}
            title={flc(getTitleForPageEnum(PageEnum.ratControlConfirm))}
            subtitle={flc(t('order.screen.confirmation.subtitle'))}
            price={{
                label: flc(t('order.price')),
                amount: ratControlData.price,
            }}
            secondButton={{
                arrowContinue: true,
                onClick: handleSubmit(onSubmit),
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(ratControlServiceRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            <ConfirmData deliveryClientData={deliveryClient} billingClientData={billingClient}>
                <InfoBoxRatControl problem={ratControlData.problem} description={ratControlData.description} />
            </ConfirmData>

            <Box marginTop={isSmallLayout ? 0 : '20px'}>
                <form onSubmit={handleSubmit(onSubmit)} ref={termsRef}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput name="message" size={TextInputSize.big} placeholder={flc(t('order.screen.confirmation.form.message.placeholder'))} inputRef={register} rows={3} />
                        </Grid>
                        <Grid item xs={6}>
                            {ratControlData.images.map((file) => {
                                return <img key={file.name} src={URL.createObjectURL(file)} alt={file.name} style={{ height: '100px', marginRight: '14px' }} />;
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            {t('order.screen.confirmation.weWillContactYou')}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox inputRef={register} name={'agreeTerms'} />}
                                label={
                                    <>
                                        {t('order.screen.confirmation.termAndConditions.label')}&nbsp;
                                        <a href={getUrlWithConditionsAndTerms()} target={'_blank'}>
                                            {t('order.screen.confirmation.termAndConditions.labelLing')}
                                        </a>
                                    </>
                                }
                            />
                            {termsAndConditionsError === true && (
                                <FormHelperText
                                    style={{
                                        color: theme.palette.secondary.main,
                                        marginLeft: 0,
                                        marginTop: 0,
                                    }}
                                >
                                    {t('order.screen.confirmation.termAndConditions.required')}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box marginTop={'16px'}>&nbsp;</Box>
        </PageWrapper>
    );
};

export default RatControlConfirmationPage;

import * as z from 'zod';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { InsectControlFormProps } from 'component/form/insectControlForm/insectControlFormProps';
import { InsectControlTypeEnum, translateInsectControlTypeEnum } from 'model/insectControl/insectControlType/insectControlTypeEnum';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import FileInput from 'component/form/fileInput';
import React from 'react';
import Select from 'component/form/select';
import TextField from 'component/form/textField';

const insectControlFormValuesSchema = z.object({
    images: z.array(z.instanceof(File)),
    problem: z.nativeEnum(InsectControlTypeEnum),
    description: z.string().optional(),
});

export type InsectControlFormValues = z.infer<typeof insectControlFormValuesSchema>;

const InsectControlForm: React.FC<InsectControlFormProps> = (props) => {
    const { id, onSubmit, defaultValues } = props;
    const { t } = useTranslation();

    const methods = useForm<InsectControlFormValues>({
        resolver: zodResolver(insectControlFormValuesSchema),
        defaultValues: {
            images: defaultValues?.images ?? [],
            // @ts-ignore
            problem: defaultValues?.problem ?? '',
            description: defaultValues?.description,
        },
    });
    const { handleSubmit, errors } = methods;

    return (
        <FormProvider {...methods}>
            <form id={id} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FileInput
                            name="images"
                            label={flc(t('order.screen.insectControlService.form.images.label'))}
                            error={errors.images && t('order.screen.insectControlService.form.images.required')}
                            accept="image/*"
                            multiple={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            name="problem"
                            label={flc(t('order.screen.insectControlService.form.problem.label'))}
                            error={errors.problem && t('order.screen.insectControlService.form.problem.required')}
                        >
                            <MenuItem value={InsectControlTypeEnum.ants}> {translateInsectControlTypeEnum(InsectControlTypeEnum.ants)}</MenuItem>
                            <MenuItem value={InsectControlTypeEnum.cockroaches}> {translateInsectControlTypeEnum(InsectControlTypeEnum.cockroaches)}</MenuItem>
                            <MenuItem value={InsectControlTypeEnum.flies}> {translateInsectControlTypeEnum(InsectControlTypeEnum.flies)}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="description"
                            label={flc(t('order.screen.insectControlService.form.description.label'))}
                            error={errors.description && t('order.screen.insectControlService.form.description.required')}
                            variant="outlined"
                            fullWidth
                            rows={4}
                            margin="none"
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default InsectControlForm;

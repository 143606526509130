import { AppState } from '../../../../appState';
import { InsectControlData } from '../../../state';
import { useSelector } from 'react-redux';

const useInsectControlData = (): InsectControlData | undefined => {
    const insectControlData = useSelector<AppState, InsectControlData | undefined>((state) => {
        return state.order.insectControlData;
    });

    return insectControlData;
};

export default useInsectControlData;

import { AppLanguageEnum, getAbbreviationsByAppLanguageEnum } from 'localization/appLanguage';
import { ratControlAddressRoute } from 'route/routeRatControl';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect } from 'react';

const RatControlLb: React.FC<{}> = () => {
    const history = useHistory();

    useEffect(() => {
        i18next.changeLanguage(getAbbreviationsByAppLanguageEnum(AppLanguageEnum.luxembourgish)).then(() => {
            history.push(ratControlAddressRoute.url());
        });
    }, [history]);

    return null;
};

export default RatControlLb;

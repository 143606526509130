import { BinImageSize } from '../../bin/image/binImageProps';
import { BinTitleProps } from './binTitleProps';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { translateBinTypeEnum } from '../../../model/cleaningService/binType/binTypeEnum';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BinImage from '../../bin/image';
import React from 'react';
import styled from 'styled-components';

const BinIcon = styled.div`
    height: 24px;
    width: 24px;
`;

const BinText = styled.div`
    height: 24px;
    margin-left: 16px;
`;

const BinTitle: React.FC<BinTitleProps> = (props) => {
    const { binType, binColor } = props;

    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <BinIcon>{binColor !== null && <BinImage color={binColor} size={BinImageSize.big} />}</BinIcon>
            <BinText>
                <Typography
                    style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        letterSpacing: 0,
                        lineHeight: '24px',
                        width: '250px',
                    }}
                >
                    {flc(t('order.wasteBinsWithType', { binType: translateBinTypeEnum(binType) }))}
                </Typography>
            </BinText>
        </div>
    );
};

export default BinTitle;

import { CleaningFrequency } from '../../../model/cleaningService/cleaningFrequency/cleaningFrequencySchema';
import { Control } from 'react-hook-form/dist/types';
import { MonthsCheckboxesSize } from '../../form/months/monthsCheckboxesSize';
import { SelectedMonths } from '../../../store/order/state';
import { SelectedMonthsHandlers } from '../../form/months/selectedMonthsHandlers';

export enum CleaningServiceSelectSize {
    full = 'full',
    small = 'small',
    big = 'big',
}

export interface CleaningServiceSelectProps {
    readonly control?: Control;
    readonly error?: string;
    readonly frequencies: CleaningFrequency[];
    readonly monthsCheckboxes: {
        readonly disabled: boolean;
        readonly error?: string;
        readonly selectedMonths: SelectedMonths;
        readonly selectedMonthsHandlers: SelectedMonthsHandlers;
        readonly size: MonthsCheckboxesSize;
        readonly warning?: string;
    } | null;
    readonly name: string;
    readonly size: CleaningServiceSelectSize;
}

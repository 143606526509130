import * as z from 'zod';
import { AddressFormProps } from 'component/form/addressForm/addressFormProps';
import { Box, CircularProgress } from '@material-ui/core';
import { CitySchema } from 'model/city/citySchema';
import { FormProvider, useForm } from 'react-hook-form';
import { getPostalCodeUrl } from 'api/url/apiBackEndUrl';
import { parsePostalCodeList } from 'model/postalCode/parser/parsePostalCode';
import { PostalCode } from 'model/postalCode/postalCodeSchema';
import { StreetSchema } from 'model/street/streetSchema';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import AddressBlock from 'component/form/addressForm/addressBlock';
import axios from 'system/axios/axios';
import OrderTitle from 'component/orderTitle';
import React, { useEffect } from 'react';
import ServerError from 'component/error/serverError';
import SwitchTabs from 'component/switchTabs';

export enum CustomerType {
    person = 'person',
    company = 'company',
}

const personSchema = z.object({
    type: z.nativeEnum(CustomerType),
    personFirstName: z.string().nonempty(),
    personLastName: z.string().nonempty(),
    zip: z.object({
        id: z.number().int(), // 0: custom
        name: z.string(),
    }),
    streetNo: z.string().nonempty(),
    street: StreetSchema,
    city: CitySchema,
    email: z.string().nonempty().email(),
    phone: z
        .string()
        .nonempty()
        .regex(/^[\d+\/ ]+$/i),
});
export type PersonValues = z.infer<typeof personSchema>;

const companySchema = z.object({
    type: z.nativeEnum(CustomerType),
    companyName: z.string().nonempty(),
    companyVatNumber: z.string().nonempty(),
    zip: z.object({
        id: z.number().int(), // 0: custom
        name: z.string(),
    }),
    streetNo: z.string().nonempty(),
    street: StreetSchema,
    city: CitySchema,
    email: z.string().nonempty().email(),
    phone: z
        .string()
        .nonempty()
        .regex(/^[\d+\/ ]+$/i),
});
export type CompanyValues = z.infer<typeof companySchema>;

export interface AddressFormDefaults {
    readonly delivery?: PersonValues | CompanyValues;
    readonly billing?: PersonValues | CompanyValues;
}

const addressFormValuesSchema = z.object({
    delivery: z.union([personSchema, companySchema]),
    billing: z.union([personSchema, companySchema]).optional(),
});
export type AddressFormValues = z.infer<typeof addressFormValuesSchema>;

const AddressForm: React.FC<AddressFormProps> = (props) => {
    const { id, onSubmit, onUnknownZip, defaultValues, showDeliveryTypeSwitch } = props;

    const { t } = useTranslation();
    const [sameBillingAddress, setSameBillingAddress] = React.useState<boolean>(defaultValues?.billing === undefined);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSameBillingAddress(newValue === 'same');
    };

    const methods = useForm<AddressFormValues>({
        resolver: (values) => {
            return zodResolver(
                z.object({
                    delivery: deliveryType === CustomerType.company ? companySchema : personSchema,
                    billing: sameBillingAddress ? z.literal(undefined) : billingType === CustomerType.company ? companySchema : personSchema,
                    // @ts-ignore
                }),
            )(values);
        },
        defaultValues: {
            delivery: {
                ...defaultValues?.delivery,
                zip: defaultValues?.delivery?.zip ?? null,
                street: defaultValues?.delivery?.street ?? null,
                city: defaultValues?.delivery?.city ?? null,
                type: defaultValues?.delivery?.type ?? CustomerType.person,
            } as PersonValues,
            billing: {
                ...defaultValues?.billing,
                zip: defaultValues?.billing?.zip ?? null,
                street: defaultValues?.billing?.street ?? null,
                city: defaultValues?.billing?.city ?? null,
                type: defaultValues?.billing?.type ?? CustomerType.person,
            } as PersonValues,
        },
    });
    const { handleSubmit, watch } = methods;

    const zip = watch('delivery.zip');
    const deliveryType = watch('delivery.type');
    const billingType = watch('billing.type');

    const { data: zipCodes, isError: isErrorZips } = useQuery<PostalCode[]>(
        'postalCode',
        async () => {
            const response = await axios.get(getPostalCodeUrl());
            return parsePostalCodeList(response.data.data);
        },
        {
            refetchOnMount: false,
        },
    );

    useEffect(() => {
        if (zip?.id === 0) {
            onUnknownZip(zip.name);
        }
    }, [zip?.id, zip?.name, onUnknownZip]);

    if (zipCodes === undefined) {
        return <CircularProgress />;
    }

    if (isErrorZips) {
        return <ServerError />;
    }

    return (
        <FormProvider {...methods}>
            <form id={id} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <AddressBlock name={'delivery'} zipCodes={zipCodes} showTypeSwitch={showDeliveryTypeSwitch} />

                <OrderTitle title={t('order.screen.address.title.billingAddress')} subtitle={t('order.screen.address.subtitle.billingAddress')} />
                <Box mb={4}>
                    <SwitchTabs
                        items={[
                            {
                                value: 'same',
                                name: t('enum.billingAddress.same').toUpperCase(),
                            },
                            {
                                value: 'other',
                                name: t('enum.billingAddress.different').toUpperCase(),
                            },
                        ]}
                        value={sameBillingAddress ? 'same' : 'other'}
                        onChange={handleChange}
                    />
                </Box>
                {!sameBillingAddress && <AddressBlock name={'billing'} zipCodes={zipCodes} />}
                <Box pb={4} />
            </form>
        </FormProvider>
    );
};

export default AddressForm;

import { InsectControlData } from '../../../state';
import { insectControlDataWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetInsectControlDataWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setInsectControlDataWasSet = useCallback(
        async (insectControlData: InsectControlData) => {
            await dispatch(insectControlDataWasSet(insectControlData));
        },
        [dispatch],
    );

    return setInsectControlDataWasSet;
};

export default useSetInsectControlDataWasSetAction;

import { ConfirmDataTitleProps } from './confirmDataTitleProps';
import { Typography } from '@material-ui/core';
import React from 'react';

const ConfirmDataTitle: React.FC<ConfirmDataTitleProps> = (props) => {
    const { title } = props;

    return (
        <Typography
            style={{
                color: 'rgba(0, 0, 0, 0.64)',
                fontSize: 12,
                letterSpacing: 0,
            }}
        >
            {title}
        </Typography>
    );
};

export default ConfirmDataTitle;

import { AppState } from '../../../../appState';
import { ServiceType } from "common/serviceType";
import { useSelector } from 'react-redux';

const useServiceType = (): ServiceType | undefined => {
    return useSelector<AppState, ServiceType | undefined>((state) => {
        return state.order.serviceType;
    });
};

export default useServiceType;

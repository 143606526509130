import { NotAvailableMessageBoxProps, NotAvailableMessageTextLength } from './notAvailableMessageBoxProps';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    background-color: #ffbe01;
    padding: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return '12px';
        }
        return '12px 24px';
    }};
`;

const Text = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
`;

const NotAvailableMessageBox: React.FC<NotAvailableMessageBoxProps> = memo((props) => {
    const { textLength } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const text = useMemo<string>(() => {
        switch (textLength) {
            case NotAvailableMessageTextLength.short:
                return t('order.binTypeNotAvailable.short');
            case NotAvailableMessageTextLength.long:
                return t('order.binTypeNotAvailable.long');
            default:
                throw new Error('Unexpected NotAvailableMessageTextLength in NotAvailableMessageBox');
        }
    }, [t, textLength]);

    return (
        <BoxWrap isSmallLayout={isSmallLayout}>
            <Text>{text}</Text>
        </BoxWrap>
    );
});

export default NotAvailableMessageBox;

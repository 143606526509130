import * as z from 'zod';
import { binCleaningAddressRoute, binCleaningConfirmationRoute } from 'route/routeBinCleaning';
import { BinColor } from '../../../model/cleaningService/binColor/binColorSchema';
import { BinType } from '../../../model/cleaningService/binType/binTypeSchema';
import { BinTypeEnum } from '../../../model/cleaningService/binType/binTypeEnum';
import { ButtonVariant } from '../../button/buttonProps';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { CleaningFrequency } from '../../../model/cleaningService/cleaningFrequency/cleaningFrequencySchema';
import { CleaningFrequencyCodeEnum } from "model/cleaningService/cleaningFrequency/cleaningFrequencyCodeEnum";
import { CleaningService } from '../../../model/cleaningService/cleaningServiceSchema';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { getCleaningServicesUrl } from '../../../api/url/apiBackEndUrl';
import { getInputCleaningFrequencyNameByBinType, getInputCountNameByBinType } from './getInputNameByBinType';
import { getNavigationNameForPageEnum, getSubtitleForPageEnum, getTitleForPageEnum, PageEnum } from '../../../route/page';
import { MonthsCheckboxesSize } from '../../form/months/monthsCheckboxesSize';
import { parseCleaningServiceList } from '../../../model/cleaningService/parser/parseCleaningService';
import { SelectedMonths, TariffItem } from '../../../store/order/state';
import { SelectedMonthsHandlers } from '../../form/months/selectedMonthsHandlers';
import { ServiceType } from 'common/serviceType';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import axios from '../../../system/axios/axios';
import CleaningServiceBinBox from '../../cleaningServiceBinBox';
import PageWrapper from 'component/pageWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ServerError from '../../error/serverError';
import styled from 'styled-components';
import useCleaningServiceData from '../../../store/order/hook/cleaningService/useCleaningServiceData/useCleaningServiceData';
import usePostalCode from '../../../store/order/hook/address/usePostalCode/usePostalCode';
import useSetCleaningServiceDataWasSetAction from '../../../store/order/hook/cleaningService/useSetCleaningServiceDataWasSetAction/useSetCleaningServiceDataWasSetAction';

const CleaningServiceBinBoxesWrap = styled.div`
    padding-bottom: 1px; // due to margin
`;

type ConvertedDataForBinType = {
    readonly available: boolean;
    readonly binColor: BinColor | null;
    readonly binType: BinType;
    readonly cleaningFrequency: CleaningFrequency | null;
    readonly count: number | null;
};

interface ConvertedData {
    readonly glass: ConvertedDataForBinType;
    readonly household: ConvertedDataForBinType;
    readonly organic: ConvertedDataForBinType;
    readonly sum: number | null;
}

interface MonthCheckboxes {
    readonly frequency?: CleaningFrequencyCodeEnum;
    readonly disabled: boolean;
    readonly error?: string;
    readonly selectedMonths: SelectedMonths;
    readonly warning?: string;
}

const formInputsSchema = z.object({
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.household)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.household)]: z.any(),
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.organic)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.organic)]: z.any(),
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.glass)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.glass)]: z.any(),
});

type FormInputs = z.infer<typeof formInputsSchema>;

const getDefaultMonthCheckboxes = (): MonthCheckboxes => {
    return {
        frequency: undefined,
        warning: undefined,
        error: undefined,
        disabled: true,
        selectedMonths: {
            january: false,
            february: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            august: false,
            september: false,
            october: false,
            november: false,
            december: false,
        },
    };
};

// eslint-disable-next-line
const selectedMonthsHandlers: SelectedMonthsHandlers = {
    onJanuaryChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onFebruaryChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onMarchChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onAprilChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onMayChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onJuneChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onJulyChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onAugustChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onSeptemberChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onOctoberChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onNovemberChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
    onDecemberChanged: (newValue) => {
        console.log('newValue: ', newValue);
    },
};

const convertBinaryToMonth = (array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]): SelectedMonths => {
    // console.log('convertBinaryToMonth', array);
    const months = {
        january: array[0],
        february: array[1],
        march: array[2],
        april: array[3],
        may: array[4],
        june: array[5],
        july: array[6],
        august: array[7],
        september: array[8],
        october: array[9],
        november: array[10],
        december: array[11],
    };
    // console.log('months', months);
    return months;
};

const getCurrenMonthNumber = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    const month = (new Date().getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    return month;
};

const getNextMonthNumber = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    const month = new Date().getMonth() + 1;
    if (month === 12) {
        return 1;
    }
    return (month + 1) as 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

const getDefault1Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 12;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault2Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 6;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 6 || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault3Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 4;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 4 || index + 1 === nextMonthLow + 8 || index + 1 === nextMonthLow + 12;
    });
    console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault4Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 3;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 3 || index + 1 === nextMonthLow + 6 || index + 1 === nextMonthLow + 9 || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault6Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 2;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        // console.log('index', index);
        return (
            index + 1 === nextMonthLow ||
            index + 1 === nextMonthLow + 2 ||
            index + 1 === nextMonthLow + 4 ||
            index + 1 === nextMonthLow + 6 ||
            index + 1 === nextMonthLow + 8 ||
            index + 1 === nextMonthLow + 10 ||
            index + 1 === nextMonthLow + 12
        );
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const isExactlyCount = (selectedMonths: SelectedMonths, quantity: number|undefined): boolean => {
    let count = 0;
    if (selectedMonths.january) {
        count++;
    }
    if (selectedMonths.february) {
        count++;
    }
    if (selectedMonths.march) {
        count++;
    }
    if (selectedMonths.april) {
        count++;
    }
    if (selectedMonths.may) {
        count++;
    }
    if (selectedMonths.june) {
        count++;
    }
    if (selectedMonths.july) {
        count++;
    }
    if (selectedMonths.august) {
        count++;
    }
    if (selectedMonths.september) {
        count++;
    }
    if (selectedMonths.october) {
        count++;
    }
    if (selectedMonths.november) {
        count++;
    }
    if (selectedMonths.december) {
        count++;
    }

    return count === quantity;
};

const CleaningServicePage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const cleaningServiceData = useCleaningServiceData();

    const [monthCheckboxesHousehold, setMonthCheckboxesHousehold] = useState<MonthCheckboxes | null>(() => {
        const tariffItem = cleaningServiceData?.tariffItems.find((item) => {
            return item.binType === BinTypeEnum.household;
        });
        if (tariffItem === undefined) {
            return { ...getDefaultMonthCheckboxes() };
        }
        if (tariffItem.selectedMonths === null) {
            return null;
        }
        return {
            ...getDefaultMonthCheckboxes(),
            frequency: tariffItem.cleaningFrequency,
            disabled: tariffItem.cleaningFrequency === CleaningFrequencyCodeEnum.cleaningsPerYear12,
            selectedMonths: tariffItem.selectedMonths,
        };
    });
    const [monthCheckboxesOrganic, setMonthCheckboxesOrganic] = useState<MonthCheckboxes | null>(() => {
        const tariffItem = cleaningServiceData?.tariffItems.find((item) => {
            return item.binType === BinTypeEnum.organic;
        });
        if (tariffItem === undefined) {
            return { ...getDefaultMonthCheckboxes() };
        }
        if (tariffItem.selectedMonths === null) {
            return null;
        }
        return {
            ...getDefaultMonthCheckboxes(),
            frequency: tariffItem.cleaningFrequency,
            disabled: tariffItem.cleaningFrequency === CleaningFrequencyCodeEnum.cleaningsPerYear12,
            selectedMonths: tariffItem.selectedMonths,
        };
    });
    const [monthCheckboxesGlass, setMonthCheckboxesGlass] = useState<MonthCheckboxes | null>(() => {
        const tariffItem = cleaningServiceData?.tariffItems.find((item) => {
            return item.binType === BinTypeEnum.glass;
        });
        if (tariffItem === undefined) {
            return { ...getDefaultMonthCheckboxes() };
        }
        if (tariffItem.selectedMonths === null) {
            return null;
        }
        return {
            ...getDefaultMonthCheckboxes(),
            ...getDefaultMonthCheckboxes(),
            frequency: tariffItem.cleaningFrequency,
            disabled: tariffItem.cleaningFrequency === CleaningFrequencyCodeEnum.cleaningsPerYear12,
            selectedMonths: tariffItem.selectedMonths,
        };
    });
    const sizeForMonthCheckboxes = useMemo<MonthsCheckboxesSize>(() => {
        if (isSmallLayout === true) {
            return MonthsCheckboxesSize.small;
        }
        return MonthsCheckboxesSize.big;
    }, [isSmallLayout]);

    const postalCode = usePostalCode();
    const setCleaningServiceDataWasSet = useSetCleaningServiceDataWasSetAction();

    const { control, handleSubmit, register, watch, errors: formErrors } = useForm<FormInputs>({
        // @ts-ignore
        resolver: async (inputs): Promise<{ errors: DeepMap<FormInputs, FieldError> }> => {
            let errors: DeepMap<FormInputs, FieldError> = {};
            // @ts-ignore
            if (inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)] !== undefined && inputs[getInputCountNameByBinType(BinTypeEnum.household)] === '') {
                errors = {
                    ...errors,
                    [getInputCountNameByBinType(BinTypeEnum.household)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.count.required'),
                    },
                };
            }
            // @ts-ignore
            if (inputs[getInputCountNameByBinType(BinTypeEnum.household)] && inputs[getInputCountNameByBinType(BinTypeEnum.household)] !== '') {
                // @ts-ignore
                const householdCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.household)] as string);
                const householdCount = parseInt(householdCountInput);
                if (householdCount < 0) {
                    errors = {
                        ...errors,
                        [getInputCountNameByBinType(BinTypeEnum.household)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.positive'),
                        },
                    };
                }
            }
            if (
                inputs[getInputCountNameByBinType(BinTypeEnum.household)] &&
                // @ts-ignore
                inputs[getInputCountNameByBinType(BinTypeEnum.household)] !== '0' &&
                inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)] === undefined
            ) {
                errors = {
                    ...errors,
                    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                    },
                };
            }

            // @ts-ignore
            if (inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)] !== undefined && inputs[getInputCountNameByBinType(BinTypeEnum.organic)] === '') {
                errors = {
                    ...errors,
                    [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.count.required'),
                    },
                };
            }
            // @ts-ignore
            if (inputs[getInputCountNameByBinType(BinTypeEnum.organic)] && inputs[getInputCountNameByBinType(BinTypeEnum.organic)] !== '') {
                // @ts-ignore
                const organicCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.organic)] as string);
                const organicCount = parseInt(organicCountInput);
                if (organicCount < 0) {
                    errors = {
                        ...errors,
                        [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.positive'),
                        },
                    };
                }
            }
            if (
                inputs[getInputCountNameByBinType(BinTypeEnum.organic)] &&
                // @ts-ignore
                inputs[getInputCountNameByBinType(BinTypeEnum.organic)] !== '0' &&
                inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)] === undefined
            ) {
                errors = {
                    ...errors,
                    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                    },
                };
            }

            // @ts-ignore
            if (inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)] !== undefined && inputs[getInputCountNameByBinType(BinTypeEnum.glass)] === '') {
                errors = {
                    ...errors,
                    [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.count.required'),
                    },
                };
            }
            // @ts-ignore
            if (inputs[getInputCountNameByBinType(BinTypeEnum.glass)] && inputs[getInputCountNameByBinType(BinTypeEnum.glass)] !== '') {
                // @ts-ignore
                const glassCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.glass)] as string);
                const glassCount = parseInt(glassCountInput);
                if (glassCount < 0) {
                    errors = {
                        ...errors,
                        [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.positive'),
                        },
                    };
                }
            }
            if (
                inputs[getInputCountNameByBinType(BinTypeEnum.glass)] &&
                // @ts-ignore
                inputs[getInputCountNameByBinType(BinTypeEnum.glass)] !== '0' &&
                inputs[getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)] === undefined
            ) {
                errors = {
                    ...errors,
                    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)]: {
                        type: 'missing',
                        message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                    },
                };
            }

            if (_isEmpty(errors)) {
                // @ts-ignore
                const glassCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.glass)] as string);
                // @ts-ignore
                const organicCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.organic)] as string);
                // @ts-ignore
                const householdCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.household)] as string);

                // console.log('glassCountInput', glassCountInput);
                // console.log('organicCountInput', organicCountInput, organicCountInput === '');
                // console.log('householdCountInput', householdCountInput);

                if (glassCountInput === '' && organicCountInput === '' && householdCountInput === '') {
                    errors = {
                        ...errors,
                        [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.required'),
                        },
                        [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.required'),
                        },
                        [getInputCountNameByBinType(BinTypeEnum.household)]: {
                            type: 'missing',
                            message: t('order.screen.cleaningService.form.count.required'),
                        },
                    };
                } else {
                    const glassCountInputNumber = glassCountInput === '' ? 0 : parseInt(glassCountInput);
                    const organicCountInputNumber = organicCountInput === '' ? 0 : parseInt(organicCountInput);
                    const householdCountInputNumber = householdCountInput === '' ? 0 : parseInt(householdCountInput);

                    // console.log('glassCountInputNumber', glassCountInputNumber);
                    // console.log('organicCountInputNumber', organicCountInputNumber);
                    // console.log('householdCountInputNumber', householdCountInputNumber);

                    if (glassCountInputNumber <= 0 && organicCountInputNumber <= 0 && householdCountInputNumber <= 0) {
                        errors = {
                            ...errors,
                            [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                                type: 'missing',
                                message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                            },
                            [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                                type: 'missing',
                                message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                            },
                            [getInputCountNameByBinType(BinTypeEnum.household)]: {
                                type: 'missing',
                                message: t('order.screen.cleaningService.form.cleaningFrequency.required'),
                            },
                        };
                    }
                }
            }

            return {
                errors,
            };
        },
        // @ts-ignore
        defaultValues: {
            [getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.household;
            })?.tariffConfigId,
            [getInputCountNameByBinType(BinTypeEnum.household)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.household;
            })?.count,

            [getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.organic;
            })?.tariffConfigId,
            [getInputCountNameByBinType(BinTypeEnum.organic)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.organic;
            })?.count,

            [getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.glass;
            })?.tariffConfigId,
            [getInputCountNameByBinType(BinTypeEnum.glass)]: cleaningServiceData?.tariffItems.find((item) => {
                return item.binType === BinTypeEnum.glass;
            })?.count,
        },
    });

    const getDefaultByIdCleaningFrequency = useCallback((quantity: number) => {
        if (quantity === 1) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear1,
                selectedMonths: getDefault1Months(),
                disabled: false,
                warning: undefined,
            };
        }
        if (quantity === 2) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear2,
                selectedMonths: getDefault2Months(),
                disabled: false,
                warning: undefined,
            };
        }
        if (quantity === 3) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear3,
                selectedMonths: getDefault3Months(),
                disabled: false,
                warning: undefined,
            };
        }
        if (quantity === 4) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear4,
                selectedMonths: getDefault4Months(),
                disabled: false,
                warning: undefined,
            };
        }
        if (quantity === 6) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear6,
                selectedMonths: getDefault6Months(),
                disabled: false,
                warning: undefined,
            };
        }
        if (quantity === 12) {
            return {
                frequency: CleaningFrequencyCodeEnum.cleaningsPerYear12,
                selectedMonths: {
                    january: true,
                    february: true,
                    march: true,
                    april: true,
                    may: true,
                    june: true,
                    july: true,
                    august: true,
                    september: true,
                    october: true,
                    november: true,
                    december: true,
                },
                disabled: true,
                warning: undefined,
            };
        }

        return {
            frequency: CleaningFrequencyCodeEnum.cleaningsPerYear1,
            selectedMonths: getDefault1Months(),
            disabled: false,
            warning: undefined,
        };
    }, []);

    const { data, isError: isErrorCleaningService } = useQuery<CleaningService[]>(
        ['cleaningServices', postalCode?.id],
        async () => {
            const postalCodeId = postalCode?.id;
            if (postalCodeId === undefined) {
                throw new Error('Unexpected: postalCodeId is undefined');
            }
            const response = await axios.get(getCleaningServicesUrl(postalCodeId));
            // console.log('response', response);
            const responseData = response.data;
            const cleaningServices = parseCleaningServiceList(responseData.data);
            return cleaningServices;
        },
        {
            enabled: postalCode !== undefined,
        },
    );

    const household = useMemo<CleaningService | undefined>(() => {
        if (data === undefined) {
            return undefined;
        }
        return data.find((item) => {
            return item.binType.code === BinTypeEnum.household;
        });
    }, [data]);

    const organic = useMemo<CleaningService | undefined>(() => {
        if (data === undefined) {
            return undefined;
        }
        return data.find((item) => {
            return item.binType.code === BinTypeEnum.organic;
        });
    }, [data]);

    const glass = useMemo<CleaningService | undefined>(() => {
        if (data === undefined) {
            return undefined;
        }
        return data.find((item) => {
            return item.binType.code === BinTypeEnum.glass;
        });
    }, [data]);

    const cleaningFrequencyHouseholdId = watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.household));
    const cleaningFrequencyHousehold = household?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyHouseholdId);
    useEffect(() => {
        if (cleaningFrequencyHousehold !== undefined && cleaningFrequencyHousehold.code !== monthCheckboxesHousehold?.frequency) {
            setMonthCheckboxesHousehold((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyHousehold.quantity) };
            });
        }
    }, [cleaningFrequencyHousehold, monthCheckboxesHousehold, getDefaultByIdCleaningFrequency]);
    const countHousehold: string | undefined = watch(getInputCountNameByBinType(BinTypeEnum.household)) as string | undefined;

    const cleaningFrequencyGlassId = watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass));
    const cleaningFrequencyGlass = glass?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyGlassId);
    useEffect(() => {
        if (cleaningFrequencyGlass !== undefined && cleaningFrequencyGlass.code !== monthCheckboxesGlass?.frequency) {
            setMonthCheckboxesGlass((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyGlass.quantity) };
            });
        }
    }, [cleaningFrequencyGlass, monthCheckboxesGlass, getDefaultByIdCleaningFrequency]);
    const countGlass = watch(getInputCountNameByBinType(BinTypeEnum.glass)) as string | undefined;

    const cleaningFrequencyOrganicId = watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic));
    const cleaningFrequencyOrganic = organic?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyOrganicId);
    useEffect(() => {
        if (cleaningFrequencyOrganic !== undefined && cleaningFrequencyOrganic.code !== monthCheckboxesOrganic?.frequency) {
            setMonthCheckboxesOrganic((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyOrganic.quantity) };
            });
        }
    }, [cleaningFrequencyOrganic, monthCheckboxesOrganic, getDefaultByIdCleaningFrequency]);
    const countOrganic = watch(getInputCountNameByBinType(BinTypeEnum.organic)) as string | undefined;

    const convertedData = useMemo<ConvertedData | undefined>(() => {
        // @ts-ignore
        const nullData: ConvertedData = {
            // @ts-ignore
            household: {
                available: false,
            },
            // @ts-ignore
            organic: {
                available: false,
            },
            // @ts-ignore
            glass: {
                available: false,
            },
            sum: 0,
        };

        if (data === undefined) {
            return undefined;
        }

        let output = { ...nullData };
        if (household !== undefined) {
            let count: number | null;
            if (countHousehold === '' || countHousehold === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countHousehold);
                // console.log('countHousehold', countHousehold);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countHousehold);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyHouseholdId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = household.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyHouseholdId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const r: ConvertedDataForBinType = {
                available: household.binColor !== null && household.cleaningFrequencies[0].price !== null,
                binColor: household.binColor,
                binType: household.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, household: r };
        }

        if (organic !== undefined) {
            let count: number | null;
            if (countOrganic === '' || countOrganic === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countOrganic);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countOrganic);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyOrganicId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = organic.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyOrganicId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const r: ConvertedDataForBinType = {
                available: organic.binColor !== null && organic.cleaningFrequencies[0].price !== null,
                binColor: organic.binColor,
                binType: organic.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, organic: r };
        }

        if (glass !== undefined) {
            let count: number | null;
            if (countGlass === '' || countGlass === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countGlass);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countGlass);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyGlassId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = glass.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyGlassId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const selectedFrequency = glass.cleaningFrequencies.find((frequency) => frequency.tariffConfigId === cleaningFrequencyGlassId);
            const isSomeFrequencyAvailable = glass.cleaningFrequencies.reduce((isAvailable: boolean, frequency: CleaningFrequency) => isAvailable || frequency.price !== null, false);
            const r: ConvertedDataForBinType = {
                available: glass.binColor !== null && ((selectedFrequency !== undefined && selectedFrequency.price !== null) || (selectedFrequency === undefined && isSomeFrequencyAvailable)),
                binColor: glass.binColor,
                binType: glass.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, glass: r };
        }

        let sum: number | null = 0;
        if (output.household.available === true) {
            if (output.household.count !== null && sum !== null) {
                if (output.household.cleaningFrequency !== null) {
                    if (output.household.cleaningFrequency.price !== null) {
                        sum += output.household.cleaningFrequency.price * output.household.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        if (output.organic.available === true) {
            if (output.organic.count !== null && sum !== null) {
                if (output.organic.cleaningFrequency !== null) {
                    if (output.organic.cleaningFrequency.price) {
                        sum += output.organic.cleaningFrequency.price * output.organic.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        if (output.glass.available === true) {
            if (output.glass.count !== null && sum !== null) {
                if (output.glass.cleaningFrequency !== null) {
                    if (output.glass.cleaningFrequency.price !== null) {
                        sum += output.glass.cleaningFrequency.price * output.glass.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        sum = sum === null ? null : Math.round(sum * 100) / 100;
        output = { ...output, sum };

        return output as ConvertedData;
    }, [cleaningFrequencyGlassId, cleaningFrequencyHouseholdId, cleaningFrequencyOrganicId, countGlass, countHousehold, countOrganic, data, glass, household, organic]);

    useEffect(() => {
        if (convertedData !== undefined) {
            if (convertedData.household.available === false) {
                setMonthCheckboxesHousehold(null);
            }
            if (convertedData.organic.available === false) {
                setMonthCheckboxesOrganic(null);
            }
            if (convertedData.glass.available === false) {
                setMonthCheckboxesGlass(null);
            }
        }
    }, [convertedData]);

    const onSubmitFirst = async (e: React.BaseSyntheticEvent) => {
        if (
            cleaningFrequencyHousehold !== undefined &&
            countHousehold !== undefined &&
            countHousehold !== '0' &&
            monthCheckboxesHousehold !== null &&
            monthCheckboxesHousehold.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesHousehold.selectedMonths, cleaningFrequencyHousehold.quantity)
        ) {
            setMonthCheckboxesHousehold((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesHousehold((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }
        if (
            cleaningFrequencyOrganic !== undefined &&
            countOrganic !== undefined &&
            countOrganic !== '0' &&
            monthCheckboxesOrganic !== null &&
            monthCheckboxesOrganic.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesOrganic.selectedMonths, cleaningFrequencyOrganic.quantity)
        ) {
            setMonthCheckboxesOrganic((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesOrganic((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }
        if (
            cleaningFrequencyGlass !== undefined &&
            countGlass !== undefined &&
            countGlass !== '0' &&
            monthCheckboxesGlass !== null &&
            monthCheckboxesGlass.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesGlass.selectedMonths, cleaningFrequencyGlass.quantity)
        ) {
            setMonthCheckboxesGlass((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesGlass((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }

        await handleSubmit(onSubmit)(e);
    };

    const onSubmit = async () => {
        if (convertedData === undefined) {
            return;
        }

        const tariffItems: TariffItem[] = [];
        if (convertedData.household.cleaningFrequency !== null && convertedData.household.count !== null && convertedData.household.count !== 0) {
            tariffItems.push({
                count: convertedData.household.count,
                tariffConfigId: convertedData.household.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.household,
                cleaningFrequency: convertedData.household.cleaningFrequency.code,
                binColor: convertedData.household.binColor === null || convertedData.household.cleaningFrequency.price === null ? null : convertedData.household.binColor.code,
                selectedMonths: monthCheckboxesHousehold === null ? null : monthCheckboxesHousehold.selectedMonths,
            });
        }
        if (convertedData.organic.cleaningFrequency !== null && convertedData.organic.count !== null && convertedData.organic.count !== 0) {
            tariffItems.push({
                count: convertedData.organic.count,
                tariffConfigId: convertedData.organic.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.organic,
                cleaningFrequency: convertedData.organic.cleaningFrequency.code,
                binColor: convertedData.organic.binColor === null || convertedData.organic.cleaningFrequency.price === null ? null : convertedData.organic.binColor.code,
                selectedMonths: monthCheckboxesOrganic === null ? null : monthCheckboxesOrganic.selectedMonths,
            });
        }
        if (convertedData.glass.cleaningFrequency !== null && convertedData.glass.count !== null && convertedData.glass.count !== 0) {
            tariffItems.push({
                count: convertedData.glass.count,
                tariffConfigId: convertedData.glass.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.glass,
                cleaningFrequency: convertedData.glass.cleaningFrequency.code,
                binColor: convertedData.glass.binColor === null || convertedData.glass.cleaningFrequency.price === null ? null : convertedData.glass.binColor.code,
                selectedMonths: monthCheckboxesGlass === null ? null : monthCheckboxesGlass.selectedMonths,
            });
        }
        let price = convertedData.sum === null ? 0 : convertedData.sum;
        price = Math.round(price * 100) / 100;

        await setCleaningServiceDataWasSet({
            price,
            tariffItems,
        });
        history.push(binCleaningConfirmationRoute.url());
    };

    if (postalCode === undefined) {
        history.push(binCleaningAddressRoute.url());
        return null;
    }
    if (isErrorCleaningService === true) {
        return <ServerError />;
    }
    if (data === undefined || convertedData === undefined) {
        return <CircularProgress />;
    }
    if (household === undefined || organic === undefined || glass === undefined) {
        return null;
    }

    return (
        <PageWrapper
            page={PageEnum.cleaningService}
            pageTitle={getNavigationNameForPageEnum(PageEnum.cleaningService)}
            serviceType={ServiceType.binsCleaning}
            wizardItems={[PageEnum.cleaningAddress, PageEnum.cleaningService, PageEnum.cleaningConfirm, PageEnum.cleaningPayment]}
            title={flc(getTitleForPageEnum(PageEnum.cleaningService))}
            subtitle={getSubtitleForPageEnum(PageEnum.cleaningService)}
            price={{
                label: flc(t('order.pricePerYear')),
                amount: convertedData.sum,
            }}
            secondButton={{
                arrowContinue: true,
                onClick: async (e) => {
                    await onSubmitFirst(e);
                },
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(binCleaningAddressRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            <form
                onSubmit={async (e) => {
                    await onSubmitFirst(e);
                }}
            >
                <CleaningServiceBinBoxesWrap>
                    <CleaningServiceBinBox
                        key={BinTypeEnum.household.toString()}
                        available={convertedData.household.available}
                        binColor={convertedData.household.binColor === null ? null : convertedData.household.binColor.code}
                        binType={BinTypeEnum.household}
                        cleaningFrequencies={household.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)}
                        countName={getInputCountNameByBinType(BinTypeEnum.household)}
                        control={control}
                        inputRef={register}
                        countError={
                            // @ts-ignore
                            formErrors[getInputCountNameByBinType(BinTypeEnum.household)] === undefined || formErrors[getInputCountNameByBinType(BinTypeEnum.household)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCountNameByBinType(BinTypeEnum.household)].message
                        }
                        cleaningFrequencyError={
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)] === undefined ||
                            // @ts-ignore
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)].message
                        }
                        monthsCheckboxes={
                            monthCheckboxesHousehold === null
                                ? null
                                : {
                                      ...monthCheckboxesHousehold,
                                      size: sizeForMonthCheckboxes,
                                      selectedMonthsHandlers: {
                                          onJanuaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          january:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 1 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onFebruaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          february:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 2 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMarchChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          march:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 3 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAprilChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          april:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 4 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMayChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          may:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 5 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJuneChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          june:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 6 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJulyChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          july:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 7 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAugustChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          august:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 8 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onSeptemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          september:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 9 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onOctoberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          october:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 10 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onNovemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          november:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 11 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onDecemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesHousehold((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          december:
                                                              cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyHouseholdId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyHousehold?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 12 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                      },
                                  }
                        }
                    />

                    <CleaningServiceBinBox
                        key={BinTypeEnum.organic.toString()}
                        available={convertedData.organic.available}
                        binColor={convertedData.organic.binColor === null ? null : convertedData.organic.binColor.code}
                        binType={BinTypeEnum.organic}
                        cleaningFrequencies={organic.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)}
                        countName={getInputCountNameByBinType(BinTypeEnum.organic)}
                        control={control}
                        inputRef={register}
                        countError={
                            // @ts-ignore
                            formErrors[getInputCountNameByBinType(BinTypeEnum.organic)] === undefined || formErrors[getInputCountNameByBinType(BinTypeEnum.organic)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCountNameByBinType(BinTypeEnum.organic)].message
                        }
                        cleaningFrequencyError={
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)] === undefined ||
                            // @ts-ignore
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)].message
                        }
                        monthsCheckboxes={
                            monthCheckboxesOrganic === null
                                ? null
                                : {
                                      ...monthCheckboxesOrganic,
                                      size: sizeForMonthCheckboxes,
                                      selectedMonthsHandlers: {
                                          onJanuaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          january:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 1 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onFebruaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          february:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 2 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMarchChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          march:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 3 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAprilChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          april:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 4 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMayChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          may:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 5 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJuneChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          june:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 6 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJulyChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          july:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 7 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAugustChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          august:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 8 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onSeptemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          september:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 9 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onOctoberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          october:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 10 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onNovemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          november:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 11 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onDecemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesOrganic((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          december:
                                                              cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyOrganicId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyOrganic?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 12 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                      },
                                  }
                        }
                    />

                    <CleaningServiceBinBox
                        key={BinTypeEnum.glass.toString()}
                        available={convertedData.glass.available}
                        binColor={convertedData.glass.binColor === null ? null : convertedData.glass.binColor.code}
                        binType={BinTypeEnum.glass}
                        cleaningFrequencies={glass.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)}
                        countName={getInputCountNameByBinType(BinTypeEnum.glass)}
                        control={control}
                        inputRef={register}
                        countError={
                            // @ts-ignore
                            formErrors[getInputCountNameByBinType(BinTypeEnum.glass)] === undefined || formErrors[getInputCountNameByBinType(BinTypeEnum.glass)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCountNameByBinType(BinTypeEnum.glass)].message
                        }
                        cleaningFrequencyError={
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)] === undefined ||
                            // @ts-ignore
                            formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)].message === undefined
                                ? undefined
                                : // @ts-ignore
                                  formErrors[getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)].message
                        }
                        monthsCheckboxes={
                            monthCheckboxesGlass === null
                                ? null
                                : {
                                      ...monthCheckboxesGlass,
                                      size: sizeForMonthCheckboxes,
                                      selectedMonthsHandlers: {
                                          onJanuaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          january:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 1 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onFebruaryChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          february:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 2 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMarchChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          march:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 3 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAprilChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          april:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 4 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onMayChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          may:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 5 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJuneChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          june:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 6 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onJulyChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          july:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 7 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onAugustChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          august:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 8 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onSeptemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          september:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 9 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onOctoberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          october:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 10 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onNovemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          november:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 11 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                          onDecemberChanged: (newValue: boolean) => {
                                              setMonthCheckboxesGlass((old) => {
                                                  if (old === null) {
                                                      return null;
                                                  }
                                                  return {
                                                      ...old,
                                                      selectedMonths: {
                                                          ...old.selectedMonths,
                                                          december:
                                                              cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                                  ? false
                                                                  : newValue,
                                                      },
                                                      warning:
                                                          cleaningFrequencyGlassId !== undefined && isExactlyCount(old.selectedMonths, cleaningFrequencyGlass?.quantity) && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                                                              : getCurrenMonthNumber() === 12 && newValue === true
                                                              ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                                                              : undefined,
                                                      error: undefined,
                                                  };
                                              });
                                          },
                                      },
                                  }
                        }
                    />
                </CleaningServiceBinBoxesWrap>
            </form>
        </PageWrapper>
    );
};

export default CleaningServicePage;

import * as z from "zod";
import { BinTypeEnum } from "model/cleaningService/binType/binTypeEnum";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { FieldError } from "react-hook-form/dist/types/errors";
import {
    getInputCleaningFrequencyNameByBinType,
    getInputCountNameByBinType
} from "component/page/cleaningService/getInputNameByBinType";
import _trim from "lodash/trim";

const formInputsSchema = z.object({
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.household)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.household)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.household)]: z.any(),
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.organic)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.organic)]: z.any(),
    [getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass)]: z.number().int().optional(),
    [getInputCountNameByBinType(BinTypeEnum.glass)]: z.number().int().optional(),
    // [getInputCountNameByBinType(BinTypeEnum.glass)]: z.any(),
});

export type FormInputs = z.infer<typeof formInputsSchema>;

export const validateCountRequired = (inputs, binType: BinTypeEnum, message: string): DeepMap<FormInputs, FieldError> => {
    let errors: DeepMap<FormInputs, FieldError> = {};
    if (inputs[getInputCleaningFrequencyNameByBinType(binType)] !== undefined && inputs[getInputCountNameByBinType(binType)] === '') {
        errors = {
            ...errors,
            [getInputCountNameByBinType(binType)]: {
                type: 'missing',
                message,
            },
        };
    }
    return errors;
}

export const validateCountPositive = (inputs, binType: BinTypeEnum, message: string): DeepMap<FormInputs, FieldError> => {
    let errors: DeepMap<FormInputs, FieldError> = {};
    // @ts-ignore
    if (inputs[getInputCountNameByBinType(binType)] && inputs[getInputCountNameByBinType(binType)] !== '') {
        // @ts-ignore
        const countInput = _trim(inputs[getInputCountNameByBinType(binType)] as string);
        const count = parseInt(countInput);
        if (count < 0) {
            errors = {
                ...errors,
                [getInputCountNameByBinType(binType)]: {
                    type: 'missing',
                    message,
                },
            };
        }
    }
    return errors;
}

export const validateFrequencyRequired = (inputs, binType: BinTypeEnum, message: string): DeepMap<FormInputs, FieldError> => {
    let errors: DeepMap<FormInputs, FieldError> = {};
    if (
        inputs[getInputCountNameByBinType(binType)] &&
        // @ts-ignore
        inputs[getInputCountNameByBinType(binType)] !== '0' &&
        inputs[getInputCleaningFrequencyNameByBinType(binType)] === undefined
    ) {
        errors = {
            ...errors,
            [getInputCleaningFrequencyNameByBinType(binType)]: {
                type: 'missing',
                message,
            },
        };
    }
    return errors;
}

export const validateAtLeastOneCountRequired = (inputs, message: string): DeepMap<FormInputs, FieldError> => {
    let errors: DeepMap<FormInputs, FieldError> = {};
    // @ts-ignore
    const glassCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.glass)] as string);
    // @ts-ignore
    const organicCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.organic)] as string);
    // @ts-ignore
    const householdCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.household)] as string);//

    if (glassCountInput === '' && organicCountInput === '' && householdCountInput === '') {
        errors = {
            ...errors,
            [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                type: 'missing',
                message,
            },
            [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                type: 'missing',
                message,
            },
            [getInputCountNameByBinType(BinTypeEnum.household)]: {
                type: 'missing',
                message,
            },
        };
    }
    return errors;
}

export const validateAtLeastOneFrequencyRequired = (inputs, message: string): DeepMap<FormInputs, FieldError> => {
    let errors: DeepMap<FormInputs, FieldError> = {};
    // @ts-ignore
    const glassCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.glass)] as string);
    // @ts-ignore
    const organicCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.organic)] as string);
    // @ts-ignore
    const householdCountInput = _trim(inputs[getInputCountNameByBinType(BinTypeEnum.household)] as string);//

    const glassCountInputNumber = glassCountInput === '' ? 0 : parseInt(glassCountInput);
    const organicCountInputNumber = organicCountInput === '' ? 0 : parseInt(organicCountInput);
    const householdCountInputNumber = householdCountInput === '' ? 0 : parseInt(householdCountInput);//

    if (glassCountInputNumber <= 0 && organicCountInputNumber <= 0 && householdCountInputNumber <= 0) {
        errors = {
            ...errors,
            [getInputCountNameByBinType(BinTypeEnum.glass)]: {
                type: 'missing',
                message,
            },
            [getInputCountNameByBinType(BinTypeEnum.organic)]: {
                type: 'missing',
                message,
            },
            [getInputCountNameByBinType(BinTypeEnum.household)]: {
                type: 'missing',
                message,
            },
        };
    }
    return errors;
}
import * as z from 'zod';
import { BinColorSchema } from './binColor/binColorSchema';
import { BinTypeSchema } from './binType/binTypeSchema';
import { CleaningFrequencySchema } from './cleaningFrequency/cleaningFrequencySchema';

export const CleaningServiceSchema = z.object({
    binType: BinTypeSchema,
    binColor: BinColorSchema.nullable(),
    cleaningFrequencies: z.array(CleaningFrequencySchema),
});

export type CleaningService = z.infer<typeof CleaningServiceSchema>;

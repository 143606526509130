import { AppAction } from '../appAction';
import { OrderReducerState } from './state';
import { OrderSelector } from './selector';
import { Reducer } from 'redux';
import { ServiceType } from 'common/serviceType';

const getInitialState = (): OrderReducerState => {
    return {
        serviceType: undefined,
        deliveryClientData: undefined,
        billingClientData: undefined,
        cleaningServiceData: undefined,
        ratControlData: undefined,
        insectControlData: undefined,
        confirmationData: undefined,
        personalInformationData: undefined,
        paymentData: undefined,
        postalCodeIsNotInSystem: false,
        orderWasCreated: false,
    };
};

const orderReducer: Reducer<OrderReducerState, AppAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case OrderSelector.defaultsWasSet:
            return {
                ...state,
                deliveryClientData: {
                    person: {
                        firstName: '',
                        lastName: ''
                    },
                    company: undefined,
                    email: '',
                    phone: '',
                    address: {
                        city: {id: 0, name: ''},
                        postalCode: action.defaults.postalCode,
                        street: {id: 0, name: ''},
                        streetNo: '',
                    },
                },
                cleaningServiceData: {
                    price: 0,
                    tariffItems: action.defaults.tariffItems,
                }
            };
        case OrderSelector.personalInformationDataWasSet:
            return {
                ...state,
                personalInformationData: action.personalInformation,
            };
        case OrderSelector.confirmationDataWasSet:
            return {
                ...state,
                confirmationData: action.confirmationData,
            };
        case OrderSelector.cleaningServiceDataWasSet:
            return {
                ...state,
                cleaningServiceData: action.cleaningServiceData,
            };
        case OrderSelector.ratControlDataWasSet:
            return {
                ...state,
                ratControlData: action.ratControlData,
            };
        case OrderSelector.insectControlDataWasSet:
            return {
                ...state,
                insectControlData: action.insectControlData,
            };
        case OrderSelector.deliveryClientWasSet:
            return {
                ...state,
                deliveryClientData: action.deliveryClient,
            };
        case OrderSelector.billingClientWasSet:
            return {
                ...state,
                billingClientData: action.billingClient,
            };
        case OrderSelector.paymentDataWasSet:
            return {
                ...state,
                paymentData: action.paymentData,
            };
        case OrderSelector.serviceTypeWasSet:
            return {
                ...state,
                serviceType: action.serviceType,
            };
        case OrderSelector.newOrderStarted:
            let deliveryClientData = state?.deliveryClientData;
            let billingClientData = state?.billingClientData;

            if (action.serviceType === ServiceType.binsCleaning && state?.deliveryClientData?.company !== undefined) {
                if (state?.billingClientData === undefined || state?.billingClientData === null) {
                    deliveryClientData = undefined;
                    billingClientData = state?.deliveryClientData;
                } else {
                    deliveryClientData = undefined;
                    billingClientData = state?.billingClientData;
                }
            }

            return {
                ...getInitialState(),
                serviceType: action.serviceType,
                deliveryClientData,
                billingClientData,
            };
        case OrderSelector.orderWasCreated:
            return {
                ...state,
                orderWasCreated: true,
            };
        case OrderSelector.postalCodeIsNotInSystemWasSet:
            return {
                ...state,
                postalCodeIsNotInSystem: true,
            };
        case OrderSelector.postalCodeIsNotInSystemWasUnset:
            return {
                ...state,
                postalCodeIsNotInSystem: false,
            };
        default:
            return state;
    }
};

export default orderReducer;

import { AppLanguageEnum, getAbbreviationsByAppLanguageEnum } from 'localization/appLanguage';
import { binCleaningWelcomeRoute } from 'route/routeBinCleaning';
import { useHistory, useLocation } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect } from 'react';

const CleaningFr: React.FC<{}> = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        i18next.changeLanguage(getAbbreviationsByAppLanguageEnum(AppLanguageEnum.fr)).then(() => {
            history.push(binCleaningWelcomeRoute.url() + location.search);
        });
    }, [history, location.search]);

    return null;
};

export default CleaningFr;

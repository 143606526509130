import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import React from 'react';

const useStyles = makeStyles({
    root: {
        marginTop: '50px',
        fontSize: '16px',
    },
    container: {
        marginTop: '16px',
    },
    link: {
        marginRight: '20px',
    },
    icon: {
        width: '28px',
        height: '28px',
    },
});

const SocialIIcons: React.FC<{}> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            {t('footer.visitOnSocials')}
            <div className={classes.container}>
                <a className={classes.link} target="_blank" href="https://www.instagram.com/adyshygiene/">
                    <InstagramIcon className={classes.icon} />
                </a>
                <a className={classes.link} target="_blank" href=" https://www.facebook.com/adyshygiene">
                    <FacebookIcon className={classes.icon} />
                </a>
                <a className={classes.link} target="_blank" href="https://lu.linkedin.com/company/adys-hygiène">
                    <LinkedInIcon className={classes.icon} />
                </a>
            </div>
        </div>
    );
};

export default SocialIIcons;

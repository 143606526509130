import { newOrderStarted } from 'store/order/action';
import { ServiceType } from "common/serviceType";
import { useCallback } from 'react';
import useDispatchTyped from 'store/hook/useDispatchTyped/useDispatchTyped';

const useSetNewOrderStarted = () => {
    const dispatch = useDispatchTyped();

    return useCallback(async (serviceType: ServiceType) => {
        await dispatch(newOrderStarted(serviceType));
    }, [dispatch]);
};

export default useSetNewOrderStarted;

import { AppLanguageEnum, getNameByAppLanguageEnum } from 'localization/appLanguage';
import { LanguageSelectProps } from 'component/newHeader/LanguageSelect/languageSelectProps';
import { MenuItem, MenuList } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
    const { language, onChange } = props;
    const { t } = useTranslation();

    return (
        <MenuList>
            {language !== AppLanguageEnum.luxembourgish ? (
                <MenuItem onClick={() => onChange(AppLanguageEnum.luxembourgish)}>{t(getNameByAppLanguageEnum(AppLanguageEnum.luxembourgish))}</MenuItem>
            ) : null}
            {language !== AppLanguageEnum.fr ? <MenuItem onClick={() => onChange(AppLanguageEnum.fr)}>{t(getNameByAppLanguageEnum(AppLanguageEnum.fr))}</MenuItem> : null}
            {language !== AppLanguageEnum.english ? <MenuItem onClick={() => onChange(AppLanguageEnum.english)}>{t(getNameByAppLanguageEnum(AppLanguageEnum.english))}</MenuItem> : null}
        </MenuList>
    );
};

export default LanguageSelect;

import { AutocompleteSelectAddLabelProps } from './autocompleteSelectAddLabelProps';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    background-color: #35a6db;
    border-radius: 4px;
    display: flex;
    padding: 4px;
`;

const AutocompleteSelectAddLabel: React.FC<AutocompleteSelectAddLabelProps> = (props) => {
    const { text } = props;

    return (
        <BoxWrap>
            <Typography
                style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                }}
            >
                {text.toUpperCase()}
            </Typography>
        </BoxWrap>
    );
};

export default AutocompleteSelectAddLabel;

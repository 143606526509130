import {
    CleaningServiceData,
    ClientData,
    ConfirmationData,
    DefaultsData,
    InsectControlData,
    PaymentData,
    PersonalInformationData,
    RatControlData
} from './state';
import { OrderSelector } from './selector';
import { ServiceType } from 'common/serviceType';

export const personalInformationDataWasSet = (personalInformation: PersonalInformationData) => {
    return {
        type: OrderSelector.personalInformationDataWasSet,
        personalInformation,
    } as const;
};
export type PersonalInformationDataWasSetAction = ReturnType<typeof personalInformationDataWasSet>;

export const confirmationDataWasSet = (confirmationData: ConfirmationData) => {
    return {
        type: OrderSelector.confirmationDataWasSet,
        confirmationData,
    } as const;
};
export type ConfirmationDataWasSetAction = ReturnType<typeof confirmationDataWasSet>;

export const cleaningServiceDataWasSet = (cleaningServiceData: CleaningServiceData) => {
    return {
        type: OrderSelector.cleaningServiceDataWasSet,
        cleaningServiceData,
    } as const;
};
export type CleaningServiceDataWasSetAction = ReturnType<typeof cleaningServiceDataWasSet>;

export const defaultsWasSet = (defaults: DefaultsData) => {
    return {
        type: OrderSelector.defaultsWasSet,
        defaults,
    } as const;
};
export type DefaultsWasSetAction = ReturnType<typeof defaultsWasSet>;

export const ratControlDataWasSet = (ratControlData: RatControlData) => {
    return {
        type: OrderSelector.ratControlDataWasSet,
        ratControlData,
    } as const;
};
export type RatControlDataWasSetAction = ReturnType<typeof ratControlDataWasSet>;

export const insectControlDataWasSet = (insectControlData: InsectControlData) => {
    return {
        type: OrderSelector.insectControlDataWasSet,
        insectControlData,
    } as const;
};
export type InsectControlDataWasSetAction = ReturnType<typeof insectControlDataWasSet>;

export const deliveryClientWasSet = (deliveryClient: ClientData) => {
    return {
        type: OrderSelector.deliveryClientWasSet,
        deliveryClient,
    } as const;
};
export type DeliveryClientWasSetAction = ReturnType<typeof deliveryClientWasSet>;

export const billingClientWasSet = (billingClient: ClientData | null) => {
    return {
        type: OrderSelector.billingClientWasSet,
        billingClient,
    } as const;
};
export type BillingClientWasSetAction = ReturnType<typeof billingClientWasSet>;

export const paymentDataWasSet = (paymentData: PaymentData) => {
    return {
        type: OrderSelector.paymentDataWasSet,
        paymentData,
    } as const;
};
export type PaymentDataWasSetAction = ReturnType<typeof paymentDataWasSet>;

export const newOrderStarted = (serviceType: ServiceType) => {
    return {
        type: OrderSelector.newOrderStarted,
        serviceType,
    } as const;
};
export type NewOrderStartedAction = ReturnType<typeof newOrderStarted>;

export const orderWasCreated = () => {
    return {
        type: OrderSelector.orderWasCreated,
    } as const;
};
export type OrderWasCreatedAction = ReturnType<typeof orderWasCreated>;

export const serviceTypeWasSet = (serviceType: ServiceType) => {
    return {
        type: OrderSelector.serviceTypeWasSet,
        serviceType,
    } as const;
};
export type ServiceTypeWasSetAction = ReturnType<typeof serviceTypeWasSet>;

export const postalCodeIsNotInSystemWasSet = () => {
    return {
        type: OrderSelector.postalCodeIsNotInSystemWasSet,
    } as const;
};
export type PostalCodeIsNotInSystemWasSetAction = ReturnType<typeof postalCodeIsNotInSystemWasSet>;

export const postalCodeIsNotInSystemWasUnset = () => {
    return {
        type: OrderSelector.postalCodeIsNotInSystemWasUnset,
    } as const;
};
export type PostalCodeIsNotInSystemWasUnsetAction = ReturnType<typeof postalCodeIsNotInSystemWasUnset>;

export type OrderAction =
    | DefaultsWasSetAction
    | PersonalInformationDataWasSetAction
    | ConfirmationDataWasSetAction
    | CleaningServiceDataWasSetAction
    | RatControlDataWasSetAction
    | InsectControlDataWasSetAction
    | DeliveryClientWasSetAction
    | BillingClientWasSetAction
    | PaymentDataWasSetAction
    | NewOrderStartedAction
    | OrderWasCreatedAction
    | PostalCodeIsNotInSystemWasSetAction
    | PostalCodeIsNotInSystemWasUnsetAction
    | ServiceTypeWasSetAction;

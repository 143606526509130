import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GoogleTagManager from '../../system/googleTagManager';
import React from 'react';

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <GoogleTagManager />
            <Box textAlign="center">
                <Box>
                    <Typography>{t('pageNotFound')}</Typography>
                </Box>
            </Box>
        </>
    );
};

export default NotFoundPage;

import { Helmet } from 'react-helmet';
import React from 'react';

const FacebookDomainVerification: React.FC<{}> = () => {
    // @ts-ignore
    const isProduction: boolean = window?.projectSettings?.isProduction === true;

    if (!isProduction) {
        return null;
    }

    return (
        <Helmet>
            <meta name="facebook-domain-verification" content="ihcuxc834b9qvuma69kc0hujs5n6q5" />
        </Helmet>
    );
};

export default FacebookDomainVerification;

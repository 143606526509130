import { ErrorMessageProps } from './errorMessageProps';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
`;

const MessageWrap = styled.div`
    background-color: #d73268;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
`;

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    const { text } = props;

    return (
        <BoxWrap>
            <MessageWrap>
                <Typography
                    style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#FFFFFF',
                    }}
                >
                    {text}
                </Typography>
            </MessageWrap>
        </BoxWrap>
    );
};

export default ErrorMessage;

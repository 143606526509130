export const getUrlWithConditionsAndTerms = (): string => {
    return 'https://www.adys.lu/s/Ady_s_ConditionsGenerales_Nettoyage_WEB28112016.pdf';
};

export const getUrlAdysHomepage = (): string => {
    return 'https://adys.lu';
};

export const getUrlMoreInfoBinCleaning = (): string => {
    return 'https://www.adys.lu/services/cleaning-of-bins-containers/';
};

export const getUrlMoreInfoRatControl = (): string => {
    return 'https://www.adys.lu/services/rat-control/';
};

export const getUrlMoreInfoInsectControl = (): string => {
    return 'https://www.adys.lu/services/disinsection/';
};

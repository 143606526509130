import i18next from 'i18next';

export enum RatControlTypeEnum {
    rats = 'rats',
    mice = 'mice',
}

export const translateRatControlTypeEnum = (ratControlTypeEnum: RatControlTypeEnum): string => {
    switch (ratControlTypeEnum) {
        case RatControlTypeEnum.rats:
            return i18next.t('enum.ratControlType.rats');
        case RatControlTypeEnum.mice:
            return i18next.t('enum.ratControlType.mice');
        default:
            throw new Error('unexpected RatControlTypeEnum in translateRatControlTypeEnum');
    }
};

import { MouseEvent } from 'react';

export enum ButtonVariant {
    outlined = 'outlined',
    contained = 'contained',
}

export interface ButtonProps {
    readonly arrowContinue?: boolean;
    readonly form?: string;
    readonly onClick?: ((event: MouseEvent<HTMLAreaElement> | MouseEvent<HTMLButtonElement>) => void) | null;
    readonly text: string;
    readonly type?: 'submit' | 'reset' | 'button';
    readonly variant: ButtonVariant;
    readonly href?: string;
}

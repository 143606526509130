import { SignpostProps } from './SignpostProps';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import SignpostCompaniesOption from './companiesOption';
import SignpostIndividualsOption from './individualsOption';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    display: flex;
    flex-direction: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'column';
        }
        return 'row';
    }};
    width: 100%;
`;

interface OptionWrapProps {
    readonly isSmallLayout: boolean;
}

const OptionWrap = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    display: flex;
    height: 208px;
    justify-content: center;
    width: ${(props: OptionWrapProps) => {
        if (props.isSmallLayout) {
            return 100;
        }
        return 50;
    }}%;
`;

interface IndividualsWrapProps {
    readonly isSmallLayout: boolean;
}

const IndividualsWrap = styled(OptionWrap)`
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: ${(props: IndividualsWrapProps) => {
        if (props.isSmallLayout) {
            return 4;
        }
        return 0;
    }}px;
    border-bottom-left-radius: ${(props: IndividualsWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return 4;
    }}px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.16);
    border-width: 1px;
    border-right-width: ${(props: IndividualsWrapProps) => {
        if (props.isSmallLayout) {
            return 1;
        }
        return 0;
    }}px;
    border-bottom-width: ${(props: IndividualsWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return 1;
    }}px;
`;

interface CompaniesWrapProps {
    readonly isSmallLayout: boolean;
}

const CompaniesWrap = styled(OptionWrap)`
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: ${(props: CompaniesWrapProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        return 4;
    }}px;
    border-bottom-left-radius: ${(props: CompaniesWrapProps) => {
        if (props.isSmallLayout) {
            return 4;
        }
        return 0;
    }}px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.16);
    border-width: 1px;
`;

const Signpost: React.FC<SignpostProps> = () => {
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BoxWrap isSmallLayout={isSmallLayout}>
            <IndividualsWrap isSmallLayout={isSmallLayout}>
                <SignpostIndividualsOption />
            </IndividualsWrap>
            <CompaniesWrap isSmallLayout={isSmallLayout}>
                <SignpostCompaniesOption />
            </CompaniesWrap>
        </BoxWrap>
    );
};

export default Signpost;

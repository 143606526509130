import { BinImageSize } from '../../../bin/image/binImageProps';
import { flc } from '../../../../utils/strings/firstLetterCapitalize';
import { InfoBoxBinProps } from './infoBoxBinProps';
import { NotAvailableMessageTextLength } from '../../../cleaningServiceBinBox/notAvailableMessageBox/notAvailableMessageBoxProps';
import { translateBinTypeEnum } from '../../../../model/cleaningService/binType/binTypeEnum';
import { translateCleaningFrequencyCode } from '../../../../model/cleaningService/cleaningFrequency/cleaningFrequencyCodeEnum';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BinImage from '../../../bin/image';
import ConfirmDataValue from '../../value';
import NotAvailableMessageBox from '../../../cleaningServiceBinBox/notAvailableMessageBox';
import React, { memo } from 'react';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly available: boolean;
    readonly isSmallLayout: boolean;
}

const BoxWrap = styled.div`
    border-width: ${(props: BoxWrapProps) => {
        return props.available === true ? 0 : 2;
    }}px;
    border-color: #ffbe01;
    border-style: solid;
    min-width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        return '300px';
    }};
    max-width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        return '300px';
    }};
    margin-bottom: ${(props: BoxWrapProps) => {
        if (props.available === false) {
            return '16px';
        }
        return 0;
    }};
`;

const BoxWrapIn = styled.div`
    padding: 20px;
`;

const BinImagesWrap = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

const InfoBoxBin: React.FC<InfoBoxBinProps> = memo((props) => {
    const { values } = props;
    const { binColor, binType, count, cleaningFrequency, available, selectedMonths } = values;

    const { t } = useTranslation();

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const binItems: undefined[] = [];
    for (let i = 0; i < count; i++) {
        binItems.push(undefined);
    }

    return (
        <BoxWrap available={available} isSmallLayout={isSmallLayout}>
            <BoxWrapIn>
                <BinImagesWrap>
                    {binItems.map(() => {
                        if (binColor === null || available === false) {
                            return null;
                        }
                        return <BinImage color={binColor} size={BinImageSize.small} />;
                    })}
                </BinImagesWrap>
                <ConfirmDataValue value={count.toString() + ' ' + t('order.wasteBinsWithType', { binType: translateBinTypeEnum(binType) })} />
                <ConfirmDataValue value={translateCleaningFrequencyCode(cleaningFrequency)} />
                {selectedMonths !== null &&
                    (!selectedMonths.january ||
                        !selectedMonths.february ||
                        !selectedMonths.march ||
                        !selectedMonths.april ||
                        !selectedMonths.may ||
                        !selectedMonths.june ||
                        !selectedMonths.july ||
                        !selectedMonths.august ||
                        !selectedMonths.september ||
                        !selectedMonths.october ||
                        !selectedMonths.november ||
                        !selectedMonths.december) && (
                        <>
                            {selectedMonths.january && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.january'))}
                                </Typography>
                            )}
                            {selectedMonths.february && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.february'))}
                                </Typography>
                            )}
                            {selectedMonths.march && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.march'))}
                                </Typography>
                            )}
                            {selectedMonths.april && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.april'))}
                                </Typography>
                            )}
                            {selectedMonths.may && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.may'))}
                                </Typography>
                            )}
                            {selectedMonths.june && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.june'))}
                                </Typography>
                            )}
                            {selectedMonths.july && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.july'))}
                                </Typography>
                            )}
                            {selectedMonths.august && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.august'))}
                                </Typography>
                            )}
                            {selectedMonths.september && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.september'))}
                                </Typography>
                            )}
                            {selectedMonths.october && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.october'))}
                                </Typography>
                            )}
                            {selectedMonths.november && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.november'))}
                                </Typography>
                            )}
                            {selectedMonths.december && (
                                <Typography
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.638694)',
                                        fontSize: 12,
                                        letterSpacing: 0,
                                        fontWeight: 800,
                                    }}
                                >
                                    {flc(t('order.month.fullName.december'))}
                                </Typography>
                            )}
                        </>
                    )}
            </BoxWrapIn>
            <div>{available === false && <NotAvailableMessageBox textLength={NotAvailableMessageTextLength.short} />}</div>
        </BoxWrap>
    );
});

export default InfoBoxBin;

import { AppState } from '../../../../appState';
import { PaymentData } from '../../../state';
import { useSelector } from 'react-redux';

const usePaymentData = (): PaymentData | undefined => {
    const paymentData = useSelector<AppState, PaymentData | undefined>((state) => {
        return state.order.paymentData;
    });

    return paymentData;
};

export default usePaymentData;

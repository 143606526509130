import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MonthCheckboxProps } from './monthCheckboxProps';
import MonthsCheckboxLabel from './label';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
`;

const MonthCheckbox: React.FC<MonthCheckboxProps> = (props) => {
    const { checked, text, onChange, disabled } = props;

    return (
        <BoxWrap>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(event) => {
                            onChange(event.target.checked);
                        }}
                        checked={checked}
                        disabled={disabled}
                        color={'primary'}
                        style={{
                            height: 8,
                        }}
                    />
                }
                label={<MonthsCheckboxLabel text={text} />}
                labelPlacement={'bottom'}
                style={{
                    padding: 0,
                    margin: 0,
                    width: 24,
                }}
            />
        </BoxWrap>
    );
};

export default MonthCheckbox;

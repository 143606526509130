import { SignpostOptionProps } from './signpostOptionProps';
import { Typography } from '@material-ui/core';
import Button from '../../../../button';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const OptionTitle = styled.div`
    margin-top: 16px;
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

const SignpostOption: React.FC<SignpostOptionProps> = (props) => {
    const { title, button, Logo } = props;

    return (
        <BoxWrap>
            <Logo
                style={{
                    alignSelf: 'center',
                    height: 40,
                    opacity: 0.8,
                    width: 40,
                }}
            />
            <OptionTitle>
                <Typography
                    style={{
                        color: '#000000',
                        fontSize: 18,
                        fontWeight: 500,
                        letterSpacing: 0,
                        maxWidth: 300,
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Typography>
            </OptionTitle>

            <ButtonWrap>
                <Button onClick={button.onClick} text={button.text} variant={button.variant} arrowContinue={button.arrowContinue} />
            </ButtonWrap>
        </BoxWrap>
    );
};

export default SignpostOption;

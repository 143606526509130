import { createMuiTheme } from '@material-ui/core';
import { PaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { ThemeOptions } from '@material-ui/core';
import GeneralSansRegular from '../assets/fonts/generalSans/GeneralSans-Regular.woff2';
import GeneralSansSemibold from '../assets/fonts/generalSans/GeneralSans-Semibold.woff2';

const generalSansRegular = {
    fontFamily: 'GeneralSans',
    fontStyle: 'normal',
    fontWeight: '400',
    src: `local('GeneralSans'),url(${GeneralSansRegular}) format('woff2')`,
};

const generalSansSemibold = {
    fontFamily: 'GeneralSansSemibold',
    fontStyle: 'normal',
    fontWeight: '400',
    src: `local('GeneralSansSemibold'),url(${GeneralSansSemibold}) format('woff2')`,
};

const greenForFind = '#4cbf2a';

const adysPink: PaletteColorOptions = {
    '50': greenForFind,
    '100': greenForFind,
    '200': greenForFind,
    '300': greenForFind,
    '400': greenForFind,
    '500': greenForFind, // button enabled for primary
    '600': greenForFind,
    '700': greenForFind, // button hover for primary
    '800': greenForFind,
    '900': greenForFind,
    A100: greenForFind,
    A200: greenForFind,
    A400: '#D73268', // button enabled for secondary
    A700: '#da4273', // button hover for secondary
};

const adysBlue: PaletteColorOptions = {
    main: '#35A6DB',
};

const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1900,
        },
    },
    palette: {
        background: {default: 'transparent'},
        // primary: pink,
        // secondary: yellow,
        // secondary: pink,
        primary: adysBlue,
        secondary: adysPink,
    },
    typography: {
        fontFamily: 'GeneralSans',
        h2: {
            fontSize: 40,
            letterSpacing: 0,
            color: '#000000',
            fontFamily: 'GeneralSansSemibold',
            fontWeight: 'normal',
        },
        h4: {
            fontSize: 18,
            letterSpacing: 0,
            color: 'rgba(0,0,0,0.64);',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [generalSansRegular, generalSansSemibold],
                '*, *::before, *::after': {
                    boxSizing: 'content-box',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#FCFDFD',
                '&$disabled': {
                    backgroundColor: 'inherit',
                },
            },
            adornedEnd: {
                // paddingRight: -10,
                // backgroundColor: 'black',
                // marginRight: -10,
            },
        },
    },
};

export default createMuiTheme(themeOptions);

import { makeStyles } from '@material-ui/core';
import { ProductProps } from 'component/newHeader/Product/productProps';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        listStyle: 'none',
        flex: '0 0 162px',

        [theme.breakpoints.down('sm')]: {
            flex: '0 0 124px',
        },
    },
    link: {
        fontWeight: 500,
        lineHeight: '24px',
        display: 'block',
        padding: '16px',
        fontSize: '16px',
        letterSpacing: '0.1px',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'black',

        '&:hover div': {
            transition: '.2s',
            borderColor: '#000',
            transform: 'scale(1.05)',
        },
    },
    imageWrap: {
        border: '1px solid transparent',
        marginBottom: '16px',
    },
    image: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
    },
}));

const Product: React.FC<ProductProps> = (props) => {
    const { name, image, href } = props;
    const classes = useStyles();

    return (
        <li className={classes.root}>
            <a className={classes.link} href={href}>
                <div className={classes.imageWrap}>
                    <img className={classes.image} alt={name} src={image} />
                </div>
                {name}
            </a>
        </li>
    );
};

export default Product;

import { AppState } from '../../../../appState';
import { PostalCode } from '../../../../../model/postalCode/postalCodeSchema';
import { useSelector } from 'react-redux';

const usePostalCode = (): PostalCode | undefined => {
    const postalCode = useSelector<AppState, PostalCode | undefined>((state) => {
        return state.order.deliveryClientData?.address.postalCode;
    });

    return postalCode;
};

export default usePostalCode;

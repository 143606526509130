export enum OrderSelector {
    defaultsWasSet = 'OrderSelector_defaultsWasSet',

    personalInformationDataWasSet = 'OrderSelector_personalInformationDataWasSet',

    confirmationDataWasSet = 'OrderSelector_confirmationDataWasSet',

    cleaningServiceDataWasSet = 'OrderSelector_cleaningServiceDataWasSet',

    ratControlDataWasSet = 'OrderSelector_ratControlDataWasSet',

    insectControlDataWasSet = 'OrderSelector_insectControlDataWasSet',

    deliveryClientWasSet = 'OrderSelector_deliveryClientWasSet',
    billingClientWasSet = 'OrderSelector_billingClientWasSet',

    paymentDataWasSet = 'OrderSelector_paymentDataWasSet',

    orderWasCreated = 'OrderSelector_orderWasCreated',
    newOrderStarted = 'OrderSelector_newOrderStarted',

    serviceTypeWasSet = 'OrderSelector_serviceTypeWasSet',

    postalCodeIsNotInSystemWasSet = 'OrderSelector_postalCodeIsNotInSystemWasSet',
    postalCodeIsNotInSystemWasUnset = 'OrderSelector_postalCodeIsNotInSystemWasUnset',
}

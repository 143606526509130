import { AddressBlockProps } from "component/form/addressForm/addressBlock/addressBlockProps";
import {
    AutocompleteSelectItem,
    AutocompleteSelectSize
} from "component/form/autocompleteSelect/autocompleteSelectProps";
import { BinColor, BinColorEnum } from "model/cleaningService/binColor/binColorSchema";
import { BinType } from "model/cleaningService/binType/binTypeSchema";
import { BinTypeEnum } from "model/cleaningService/binType/binTypeEnum";
import { ButtonVariant } from "component/button/buttonProps";
import { CircularProgress } from "@material-ui/core";
import { CleaningFrequency } from "model/cleaningService/cleaningFrequency/cleaningFrequencySchema";
import { CleaningService } from "model/cleaningService/cleaningServiceSchema";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { FieldError } from "react-hook-form/dist/types/errors";
import { flc } from "utils/strings/firstLetterCapitalize";
import {
    FormInputs, validateAtLeastOneCountRequired, validateAtLeastOneFrequencyRequired,
    validateCountPositive,
    validateCountRequired,
    validateFrequencyRequired
} from "component/page/embeddedCalculator/validators";
import { FormProvider, useForm } from 'react-hook-form';
import { getCleaningServicesUrl, getPostalCodeUrl } from "api/url/apiBackEndUrl";
import {
    getInputCleaningFrequencyNameByBinType,
    getInputCountNameByBinType
} from "component/page/cleaningService/getInputNameByBinType";
import { makeStyles } from "@material-ui/core/styles";
import { MonthsCheckboxesSize } from "component/form/months/monthsCheckboxesSize";
import { parseCleaningServiceList } from "model/cleaningService/parser/parseCleaningService";
import { parsePostalCodeList } from "model/postalCode/parser/parsePostalCode";
import { PostalCode } from "model/postalCode/postalCodeSchema";
import { SelectedMonths, TariffItem } from "store/order/state";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import _isEmpty from 'lodash/isEmpty';
import AutocompleteSelect from "component/form/autocompleteSelect";
import axios from "system/axios/axios";
import BottomNavigation from "component/bottomNavigation";
import CleaningServiceBinBox from "component/cleaningServiceBinBox";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ServerError from "component/error/serverError";

const useStyles = makeStyles({
    root: {
        paddingTop: 10,
    },
    center: {
        display: "flex",
        justifyContent: "center",
    }
});

type ConvertedDataForBinType = {
    readonly available: boolean;
    readonly binColor: BinColor | null;
    readonly binType: BinType;
    readonly cleaningFrequency: CleaningFrequency | null;
    readonly count: number | null;
};

interface ConvertedData {
    readonly glass: ConvertedDataForBinType;
    readonly household: ConvertedDataForBinType;
    readonly organic: ConvertedDataForBinType;
    readonly sum: number | null;
}

interface MonthCheckboxes {
    readonly disabled: boolean;
    readonly error?: string;
    readonly selectedMonths: SelectedMonths;
    readonly warning?: string;
}

const getDefaultMonthCheckboxes = (): MonthCheckboxes => {
    return {
        warning: undefined,
        error: undefined,
        disabled: true,
        selectedMonths: {
            january: false,
            february: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            august: false,
            september: false,
            october: false,
            november: false,
            december: false,
        },
    };
};

const isExactlyCount = (selectedMonths: SelectedMonths, quantity: number|undefined): boolean => {
    let count = 0;
    if (selectedMonths.january) {
        count++;
    }
    if (selectedMonths.february) {
        count++;
    }
    if (selectedMonths.march) {
        count++;
    }
    if (selectedMonths.april) {
        count++;
    }
    if (selectedMonths.may) {
        count++;
    }
    if (selectedMonths.june) {
        count++;
    }
    if (selectedMonths.july) {
        count++;
    }
    if (selectedMonths.august) {
        count++;
    }
    if (selectedMonths.september) {
        count++;
    }
    if (selectedMonths.october) {
        count++;
    }
    if (selectedMonths.november) {
        count++;
    }
    if (selectedMonths.december) {
        count++;
    }

    return count === quantity;
};

const convertBinaryToMonth = (array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]): SelectedMonths => {
    // console.log('convertBinaryToMonth', array);
    const months = {
        january: array[0],
        february: array[1],
        march: array[2],
        april: array[3],
        may: array[4],
        june: array[5],
        july: array[6],
        august: array[7],
        september: array[8],
        october: array[9],
        november: array[10],
        december: array[11],
    };
    return months;
};

const getCurrenMonthNumber = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    const month = (new Date().getMonth() + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    return month;
};

const getNextMonthNumber = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    const month = new Date().getMonth() + 1;
    if (month === 12) {
        return 1;
    }
    return (month + 1) as 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

const getDefault1Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 12;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault2Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 6;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 6 || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault3Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 4;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 4 || index + 1 === nextMonthLow + 8 || index + 1 === nextMonthLow + 12;
    });
    console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault4Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 3;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        return index + 1 === nextMonthLow || index + 1 === nextMonthLow + 3 || index + 1 === nextMonthLow + 6 || index + 1 === nextMonthLow + 9 || index + 1 === nextMonthLow + 12;
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefault6Months = (): SelectedMonths => {
    const nextMonth = getNextMonthNumber();
    // console.log('nextMonth', nextMonth);
    const nextMonthLow = nextMonth % 2;
    // console.log('nextMonthLow', nextMonthLow);

    const array: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ];

    // @ts-ignore
    const output: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean] = array.map<boolean>((value, index) => {
        // console.log('index', index);
        return (
            index + 1 === nextMonthLow ||
            index + 1 === nextMonthLow + 2 ||
            index + 1 === nextMonthLow + 4 ||
            index + 1 === nextMonthLow + 6 ||
            index + 1 === nextMonthLow + 8 ||
            index + 1 === nextMonthLow + 10 ||
            index + 1 === nextMonthLow + 12
        );
    });
    // console.log('output', output);

    return convertBinaryToMonth(output);
};

const getDefaultMonthsByQuantity = (quantity: number) => {
    if (quantity === 1) {
        return {
            selectedMonths: getDefault1Months(),
            disabled: false,
            warning: undefined,
        };
    }
    if (quantity === 2) {
        return {
            selectedMonths: getDefault2Months(),
            disabled: false,
            warning: undefined,
        };
    }
    if (quantity === 3) {
        return {
            selectedMonths: getDefault3Months(),
            disabled: false,
            warning: undefined,
        };
    }
    if (quantity === 4) {
        return {
            selectedMonths: getDefault4Months(),
            disabled: false,
            warning: undefined,
        };
    }
    if (quantity === 6) {
        return {
            selectedMonths: getDefault6Months(),
            disabled: false,
            warning: undefined,
        };
    }
    if (quantity === 12) {
        return {
            selectedMonths: {
                january: true,
                february: true,
                march: true,
                april: true,
                may: true,
                june: true,
                july: true,
                august: true,
                september: true,
                october: true,
                november: true,
                december: true,
            },
            disabled: true,
            warning: undefined,
        };
    }

    return {
        selectedMonths: getDefault1Months(),
        disabled: false,
        warning: undefined,
    };
}

const EmbeddedCalculator: React.FC<AddressBlockProps> = (props) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles(props);

    const [monthCheckboxesHousehold, setMonthCheckboxesHousehold] = useState<MonthCheckboxes | null>(() => {
        return { ...getDefaultMonthCheckboxes() };
    });
    const [monthCheckboxesOrganic, setMonthCheckboxesOrganic] = useState<MonthCheckboxes | null>(() => {
        return { ...getDefaultMonthCheckboxes() };
    });
    const [monthCheckboxesGlass, setMonthCheckboxesGlass] = useState<MonthCheckboxes | null>(() => {
        return { ...getDefaultMonthCheckboxes() };
    });

    const getDefaultByIdCleaningFrequency = useCallback(getDefaultMonthsByQuantity, []);

    const validateCountAndFrequency = (inputs, binType: BinTypeEnum): DeepMap<FormInputs, FieldError> => {
        return {
            ...validateCountRequired(inputs, binType, t('order.screen.cleaningService.form.count.required')),
            ...validateCountPositive(inputs, binType, t('order.screen.cleaningService.form.count.positive')),
            ...validateFrequencyRequired(inputs, binType, t('order.screen.cleaningService.form.cleaningFrequency.required')),
        };
    }

    const methods = useForm({
        // @ts-ignore
        resolver: async (inputs): Promise<{ errors: DeepMap<FormInputs, FieldError> }> => {
            let errors: DeepMap<FormInputs, FieldError> = {
                ...validateCountAndFrequency(inputs, BinTypeEnum.household),
                ...validateCountAndFrequency(inputs, BinTypeEnum.organic),
                ...validateCountAndFrequency(inputs, BinTypeEnum.glass),
            }

            if (_isEmpty(errors)) {
                errors = {
                    ...validateAtLeastOneCountRequired(inputs, t('order.screen.cleaningService.form.count.required')),
                    ...validateAtLeastOneFrequencyRequired(inputs, t('order.screen.cleaningService.form.cleaningFrequency.required')),
                }
            }

            return {
                errors,
            };
        },
        defaultValues: {
            zip: {
                id: 2,
                name: '1111'
            }
        }
    });

    const zip = methods.watch(`zip`);

    const { data: zipCodes, isError: isErrorZips } = useQuery<PostalCode[]>(
        'postalCode',
        async () => {
            const response = await axios.get(getPostalCodeUrl());
            return parsePostalCodeList(response.data.data);
        },
        {
            refetchOnMount: false,
        },
    );

    const { data: cleaningServices, isError: isErrorCleaningService } = useQuery<CleaningService[]>(
        ['cleaningServices', zip?.id],
        async () => {
            const postalCodeId = zip?.id;
            if (postalCodeId === undefined) {
                throw new Error('Unexpected: postalCodeId is undefined');
            }
            const response = await axios.get(getCleaningServicesUrl(postalCodeId));
            const responseData = response.data;
            return parseCleaningServiceList(responseData.data);
        },
        {
            enabled: zip !== undefined,
        },
    );

    const household = useMemo<CleaningService | undefined>(() => {
        if (cleaningServices === undefined) {
            return undefined;
        }
        return cleaningServices.find((item) => {
            return item.binType.code === BinTypeEnum.household;
        });
    }, [cleaningServices]);

    const organic = useMemo<CleaningService | undefined>(() => {
        if (cleaningServices === undefined) {
            return undefined;
        }
        return cleaningServices.find((item) => {
            return item.binType.code === BinTypeEnum.organic;
        });
    }, [cleaningServices]);

    const glass = useMemo<CleaningService | undefined>(() => {
        if (cleaningServices === undefined) {
            return undefined;
        }
        return cleaningServices.find((item) => {
            return item.binType.code === BinTypeEnum.glass;
        });
    }, [cleaningServices]);

    const cleaningFrequencyHouseholdId = methods.watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.household));
    const cleaningFrequencyHousehold = household?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyHouseholdId);
    useEffect(() => {
        if (cleaningFrequencyHousehold !== undefined) {
            setMonthCheckboxesHousehold((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyHousehold.quantity) };
            });
        }
    }, [cleaningFrequencyHousehold, getDefaultByIdCleaningFrequency]);
    const countHousehold = methods.watch(getInputCountNameByBinType(BinTypeEnum.household)) as string | undefined;

    const cleaningFrequencyGlassId = methods.watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.glass));
    const cleaningFrequencyGlass = glass?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyGlassId);
    useEffect(() => {
        if (cleaningFrequencyGlass !== undefined) {
            setMonthCheckboxesGlass((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyGlass.quantity) };
            });
        }
    }, [cleaningFrequencyGlass, getDefaultByIdCleaningFrequency]);
    const countGlass = methods.watch(getInputCountNameByBinType(BinTypeEnum.glass)) as string | undefined;

    const cleaningFrequencyOrganicId = methods.watch(getInputCleaningFrequencyNameByBinType(BinTypeEnum.organic));
    const cleaningFrequencyOrganic = organic?.cleaningFrequencies.find(item => item.tariffConfigId === cleaningFrequencyOrganicId);
    useEffect(() => {
        if (cleaningFrequencyOrganic !== undefined) {
            setMonthCheckboxesOrganic((old) => {
                return { ...old, ...getDefaultByIdCleaningFrequency(cleaningFrequencyOrganic.quantity) };
            });
        }
    }, [cleaningFrequencyOrganic, getDefaultByIdCleaningFrequency]);
    const countOrganic = methods.watch(getInputCountNameByBinType(BinTypeEnum.organic)) as string | undefined;

    useEffect(() => {
        // Resize parent window
        window.parent.postMessage({height: Math.max(document.body.scrollHeight, 200)}, '*');
    });

    const convertedData = useMemo<ConvertedData>(() => {
        // @ts-ignore
        const nullData: ConvertedData = {
            // @ts-ignore
            household: {
                available: false,
            },
            // @ts-ignore
            organic: {
                available: false,
            },
            // @ts-ignore
            glass: {
                available: false,
            },
            sum: 0,
        };

        let output = { ...nullData };
        if (household !== undefined) {
            let count: number | null;
            if (countHousehold === '' || countHousehold === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countHousehold);
                // console.log('countHousehold', countHousehold);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countHousehold);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyHouseholdId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = household.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyHouseholdId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const r: ConvertedDataForBinType = {
                available: household.binColor !== null && household.cleaningFrequencies[0].price !== null,
                binColor: household.binColor,
                binType: household.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, household: r };
        }

        if (organic !== undefined) {
            let count: number | null;
            if (countOrganic === '' || countOrganic === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countOrganic);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countOrganic);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyOrganicId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = organic.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyOrganicId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const r: ConvertedDataForBinType = {
                available: organic.binColor !== null && organic.cleaningFrequencies[0].price !== null,
                binColor: organic.binColor,
                binType: organic.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, organic: r };
        }

        if (glass !== undefined) {
            let count: number | null;
            if (countGlass === '' || countGlass === undefined) {
                count = 0;
            } else {
                const regExp = new RegExp(/^\d+$/);
                const test = regExp.test(countGlass);
                if (test === false) {
                    count = null;
                } else {
                    count = parseInt(countGlass);
                }
            }

            let cleaningFrequency: CleaningFrequency | null;
            if (cleaningFrequencyGlassId === undefined) {
                cleaningFrequency = null;
            } else {
                const found = glass.cleaningFrequencies.find((item) => {
                    return item.tariffConfigId === cleaningFrequencyGlassId;
                });
                if (found === undefined) {
                    cleaningFrequency = null;
                } else {
                    cleaningFrequency = found;
                }
            }

            const selectedFrequency = glass.cleaningFrequencies.find((frequency) => frequency.tariffConfigId === cleaningFrequencyGlassId);
            const isSomeFrequencyAvailable = glass.cleaningFrequencies.reduce((isAvailable: boolean, frequency: CleaningFrequency) => isAvailable || frequency.price !== null, false);
            const r: ConvertedDataForBinType = {
                available: glass.binColor !== null && ((selectedFrequency !== undefined && selectedFrequency.price !== null) || (selectedFrequency === undefined && isSomeFrequencyAvailable)),
                binColor: glass.binColor,
                binType: glass.binType,
                count,
                cleaningFrequency,
            };
            output = { ...output, glass: r };
        }

        let sum: number | null = 0;
        if (output.household.available === true) {
            if (output.household.count !== null && sum !== null) {
                if (output.household.cleaningFrequency !== null) {
                    if (output.household.cleaningFrequency.price !== null) {
                        sum += output.household.cleaningFrequency.price * output.household.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        if (output.organic.available === true) {
            if (output.organic.count !== null && sum !== null) {
                if (output.organic.cleaningFrequency !== null) {
                    if (output.organic.cleaningFrequency.price) {
                        sum += output.organic.cleaningFrequency.price * output.organic.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        if (output.glass.available === true) {
            if (output.glass.count !== null && sum !== null) {
                if (output.glass.cleaningFrequency !== null) {
                    if (output.glass.cleaningFrequency.price !== null) {
                        sum += output.glass.cleaningFrequency.price * output.glass.count;
                    }
                }
            } else {
                sum = null;
            }
        }
        sum = sum === null ? null : Math.round(sum * 100) / 100;
        output = { ...output, sum };

        return output as ConvertedData;
    }, [cleaningFrequencyGlassId, cleaningFrequencyHouseholdId, cleaningFrequencyOrganicId, countGlass, countHousehold, countOrganic, glass, household, organic]);


    const changeMonth = (oldValue, newValue: boolean, cleaningFrequency: CleaningFrequency | undefined, month: string) => {
        if (oldValue === null) {
            return null;
        }
        return {
            ...oldValue,
            selectedMonths: {
                ...oldValue.selectedMonths,
                [month]:
                    cleaningFrequency !== undefined && isExactlyCount(oldValue.selectedMonths, cleaningFrequency?.quantity) && newValue === true
                        ? false
                        : newValue,
            },
            warning:
                cleaningFrequency !== undefined && isExactlyCount(oldValue.selectedMonths, cleaningFrequency?.quantity) && newValue === true
                    ? t('order.screen.cleaningService.form.monthCheckboxes.warning.maxCount')
                    : getCurrenMonthNumber() === 1 && newValue === true
                        ? t('order.screen.cleaningService.form.monthCheckboxes.warning.thisMonth')
                        : undefined,
            error: undefined,
        };
    }

    const onSubmitFirst = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        if (
            cleaningFrequencyHousehold !== undefined &&
            countHousehold !== undefined &&
            countHousehold !== '0' &&
            monthCheckboxesHousehold !== null &&
            monthCheckboxesHousehold.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesHousehold.selectedMonths, cleaningFrequencyHousehold.quantity)
        ) {
            setMonthCheckboxesHousehold((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesHousehold((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }
        if (
            cleaningFrequencyOrganic !== undefined &&
            countOrganic !== undefined &&
            countOrganic !== '0' &&
            monthCheckboxesOrganic !== null &&
            monthCheckboxesOrganic.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesOrganic.selectedMonths, cleaningFrequencyOrganic.quantity)
        ) {
            setMonthCheckboxesOrganic((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesOrganic((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }
        if (
            cleaningFrequencyGlass !== undefined &&
            countGlass !== undefined &&
            countGlass !== '0' &&
            monthCheckboxesGlass !== null &&
            monthCheckboxesGlass.selectedMonths !== null &&
            !isExactlyCount(monthCheckboxesGlass.selectedMonths, cleaningFrequencyGlass.quantity)
        ) {
            setMonthCheckboxesGlass((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: t('order.screen.cleaningService.form.monthCheckboxes.error.moreMonths'),
                };
            });
            return;
        } else {
            setMonthCheckboxesGlass((old) => {
                if (old === null) {
                    return null;
                }
                return {
                    ...old,
                    error: undefined,
                };
            });
        }

        await methods.handleSubmit(onSubmit)(e);
    };

    const onSubmit = () => {
        if (convertedData === undefined) {
            return;
        }

        const tariffItems: TariffItem[] = [];
        if (convertedData.household.cleaningFrequency !== null && convertedData.household.count !== null && convertedData.household.count !== 0) {
            tariffItems.push({
                count: convertedData.household.count,
                tariffConfigId: convertedData.household.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.household,
                cleaningFrequency: convertedData.household.cleaningFrequency.code,
                binColor: convertedData.household.binColor === null || convertedData.household.cleaningFrequency.price === null ? null : convertedData.household.binColor.code,
                selectedMonths: monthCheckboxesHousehold === null ? null : monthCheckboxesHousehold.selectedMonths,
            });
        }
        if (convertedData.organic.cleaningFrequency !== null && convertedData.organic.count !== null && convertedData.organic.count !== 0) {
            tariffItems.push({
                count: convertedData.organic.count,
                tariffConfigId: convertedData.organic.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.organic,
                cleaningFrequency: convertedData.organic.cleaningFrequency.code,
                binColor: convertedData.organic.binColor === null || convertedData.organic.cleaningFrequency.price === null ? null : convertedData.organic.binColor.code,
                selectedMonths: monthCheckboxesOrganic === null ? null : monthCheckboxesOrganic.selectedMonths,
            });
        }
        if (convertedData.glass.cleaningFrequency !== null && convertedData.glass.count !== null && convertedData.glass.count !== 0) {
            tariffItems.push({
                count: convertedData.glass.count,
                tariffConfigId: convertedData.glass.cleaningFrequency.tariffConfigId,
                binType: BinTypeEnum.glass,
                cleaningFrequency: convertedData.glass.cleaningFrequency.code,
                binColor: convertedData.glass.binColor === null || convertedData.glass.cleaningFrequency.price === null ? null : convertedData.glass.binColor.code,
                selectedMonths: monthCheckboxesGlass === null ? null : monthCheckboxesGlass.selectedMonths,
            });
        }

        const locale = i18n.language === undefined ? '' : '/' + i18n.language
        const defaults = JSON.stringify({
            postalCode: {
                id: zip.id,
                code: zip.name
            },
            tariffItems,
        })

        window.top.location.href = `https://my.adys.lu/bin-cleaning${locale}?defaults=${defaults}`;
    };

    if (zipCodes === undefined) {
        return <div className={classes.center}><CircularProgress /></div>;
    }

    if (isErrorZips) {
        return <ServerError />;
    }

    return (
        <div className={classes.root}>
            <style>{"body {background-color: transparent !important;}"}</style>
            <FormProvider {...methods}>
                <form id="calculator" onSubmit={async (e) => await onSubmitFirst(e)} autoComplete="off">
                    <div className={classes.center}>
                        <AutocompleteSelect
                            items={zipCodes.map<AutocompleteSelectItem>((item) => ({ id: item.id, name: item.code }))}
                            name="zip"
                            size={AutocompleteSelectSize.small}
                            //error={errors[name] && errors[name].zip && t('order.screen.address.form.zip.required')}
                            label={flc(t('order.screen.address.form.zip.name'))}
                            addNew={false}
                            addLabelText={flc(t('order.screen.address.form.contactUs'))}
                            filterMinLength={2}
                        />
                    </div>

                    {household !== undefined && <CleaningServiceBinBox
                        binType={household.binType.code}
                        binColor={household.binColor?.code ?? BinColorEnum.green}
                        control={methods.control}
                        available={convertedData.household.available}
                        inputRef={methods.register}
                        cleaningFrequencies={household.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(household.binType.code)}
                        countName={getInputCountNameByBinType(household.binType.code)}
                        countError={methods.errors[getInputCountNameByBinType(household.binType.code)]?.message}
                        cleaningFrequencyError={methods.errors[getInputCleaningFrequencyNameByBinType(household.binType.code)]?.message}
                        monthsCheckboxes={
                            monthCheckboxesHousehold === null
                                ? null
                                : {
                                    ...monthCheckboxesHousehold,
                                    size: MonthsCheckboxesSize.big,
                                    selectedMonthsHandlers: {
                                        onJanuaryChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'january'));
                                        },
                                        onFebruaryChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'february'));
                                        },
                                        onMarchChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'march'));
                                        },
                                        onAprilChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'april'));
                                        },
                                        onMayChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'may'));
                                        },
                                        onJuneChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'june'));
                                        },
                                        onJulyChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'july'));
                                        },
                                        onAugustChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'august'));
                                        },
                                        onSeptemberChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'september'));
                                        },
                                        onOctoberChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'october'));
                                        },
                                        onNovemberChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'november'));
                                        },
                                        onDecemberChanged: (newValue) => {
                                            setMonthCheckboxesHousehold((old) => changeMonth(old, newValue, cleaningFrequencyHousehold, 'december'));
                                        },
                                    },
                                }
                        }
                    />}

                    {organic !== undefined && <CleaningServiceBinBox
                        binType={organic.binType.code}
                        binColor={organic.binColor?.code ?? BinColorEnum.green}
                        control={methods.control}
                        available={convertedData.organic.available}
                        inputRef={methods.register}
                        cleaningFrequencies={organic.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(organic.binType.code)}
                        countName={getInputCountNameByBinType(organic.binType.code)}
                        countError={methods.errors[getInputCountNameByBinType(organic.binType.code)]?.message}
                        cleaningFrequencyError={methods.errors[getInputCleaningFrequencyNameByBinType(organic.binType.code)]?.message}
                        monthsCheckboxes={
                            monthCheckboxesOrganic === null
                                ? null
                                : {
                                    ...monthCheckboxesOrganic,
                                    size: MonthsCheckboxesSize.big,
                                    selectedMonthsHandlers: {
                                        onJanuaryChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'january'));
                                        },
                                        onFebruaryChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'february'));
                                        },
                                        onMarchChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'march'));
                                        },
                                        onAprilChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'april'));
                                        },
                                        onMayChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'may'));
                                        },
                                        onJuneChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'june'));
                                        },
                                        onJulyChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'july'));
                                        },
                                        onAugustChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'august'));
                                        },
                                        onSeptemberChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'september'));
                                        },
                                        onOctoberChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'october'));
                                        },
                                        onNovemberChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'november'));
                                        },
                                        onDecemberChanged: (newValue) => {
                                            setMonthCheckboxesOrganic((old) => changeMonth(old, newValue, cleaningFrequencyOrganic, 'december'));
                                        },
                                    },
                                }
                        }
                    />}

                    {glass !== undefined && <CleaningServiceBinBox
                        binType={glass.binType.code}
                        binColor={glass.binColor?.code ?? BinColorEnum.green}
                        control={methods.control}
                        available={convertedData.glass.available}
                        inputRef={methods.register}
                        cleaningFrequencies={glass.cleaningFrequencies}
                        cleaningFrequencyName={getInputCleaningFrequencyNameByBinType(glass.binType.code)}
                        countName={getInputCountNameByBinType(glass.binType.code)}
                        countError={methods.errors[getInputCountNameByBinType(glass.binType.code)]?.message}
                        cleaningFrequencyError={methods.errors[getInputCleaningFrequencyNameByBinType(glass.binType.code)]?.message}
                        monthsCheckboxes={
                            monthCheckboxesGlass === null
                                ? null
                                : {
                                    ...monthCheckboxesGlass,
                                    size: MonthsCheckboxesSize.big,
                                    selectedMonthsHandlers: {
                                        onJanuaryChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'january'));
                                        },
                                        onFebruaryChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'february'));
                                        },
                                        onMarchChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'march'));
                                        },
                                        onAprilChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'april'));
                                        },
                                        onMayChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'may'));
                                        },
                                        onJuneChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'june'));
                                        },
                                        onJulyChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'july'));
                                        },
                                        onAugustChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'august'));
                                        },
                                        onSeptemberChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'september'));
                                        },
                                        onOctoberChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'october'));
                                        },
                                        onNovemberChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'november'));
                                        },
                                        onDecemberChanged: (newValue) => {
                                            setMonthCheckboxesGlass((old) => changeMonth(old, newValue, cleaningFrequencyGlass, 'december'));
                                        },
                                    },
                                }
                        }
                    />}


                    {cleaningServices !== undefined && <BottomNavigation
                        price={{
                            label: flc(t('order.pricePerYear')),
                            amount: convertedData.sum,
                        }}
                        secondButton={{
                            arrowContinue: true,
                            onClick: async (e) => {
                                await onSubmitFirst(e);
                            },
                            text: t('order.navigation.continue'),
                            variant: ButtonVariant.contained,
                        }}
                    />}
                </form>
            </FormProvider>
        </div>
    );
};

export default EmbeddedCalculator;

import * as React from 'react';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

export enum TextInputSize {
    full = 'full',
    small = 'small',
    big = 'big',
    binCount = 'binCount',
}

export interface TextInputProps {
    readonly disabled?: boolean;
    readonly error?: string;
    readonly html5Type?: string;
    readonly htmlMin?: number;
    readonly inputRef?: React.Ref<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    readonly label?: string;
    readonly name: string;
    readonly onChange?: StandardInputProps['onChange'];
    readonly placeholder?: string;
    readonly rows?: number;
    readonly size: TextInputSize;
    readonly value?: string;
}

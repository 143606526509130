import { BottomNavigationPriceProps } from './bottomNavigationPriceProps';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { getPriceWithVat } from '../../../utils/vat/vat';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';

const BottomNavigationPrice: React.FC<BottomNavigationPriceProps> = memo((props) => {
    const { price, label } = props;

    const { t } = useTranslation();

    const priceString = useMemo(() => {
        if (price === null) {
            return '-';
        }
        const priceWithVat = getPriceWithVat(price);
        return priceWithVat.toFixed(2);
    }, [price]);

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <Typography variant={'h6'}>{label}:&nbsp;</Typography>
            <Typography
                variant={'h6'}
                style={{
                    fontWeight: 'bold',
                }}
            >
                {priceString} {flc(t('order.eur'))}
            </Typography>
        </div>
    );
});

export default BottomNavigationPrice;

import { ClientData } from '../../../state';
import { deliveryClientWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetDeliveryClientWasSetAction = () => {
    const dispatch = useDispatchTyped();

    return useCallback(
        async (deliveryClient: ClientData) => {
            await dispatch(deliveryClientWasSet(deliveryClient));
        },
        [dispatch],
    );
};

export default useSetDeliveryClientWasSetAction;

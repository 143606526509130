export const getPostalCodeUrl = () => {
    return '/v1/adysHygiene/orders/address/postalCodes';
};

export const getLocationUrl = (postalCodeId: number) => {
    return '/v1/adysHygiene/orders/address/locations?postalCodeId=' + postalCodeId;
};

export const getCleaningServicesUrl = (postalCodeId: number) => {
    return '/v1/adysHygiene/orders/cleaningService/services?postalCodeId=' + postalCodeId;
};

export const getRatControlServicesUrl = (postalCodeId: number) => {
    return '/v1/adysHygiene/orders/ratControl/services?postalCodeId=' + postalCodeId;
};

export const getInsectControlServicesUrl = (postalCodeId: number) => {
    return '/v1/adysHygiene/orders/insectControl/services?postalCodeId=' + postalCodeId;
};

export const getPaymentCustomerUrl = () => {
    return '/v1/adysHygiene/orders/payment/customer/create';
};

export const getInterestCustomerUrl = () => {
    return '/v1/adysHygiene/interests/create';
};

export const getOrderCreateUrl = () => {
    return '/v1/adysHygiene/orders/create';
};

export const getPestControlOrderCreateUrl = () => {
    return '/v1/adysHygiene/orders/createPestControl';
};

import { PaymentData } from '../../../state';
import { paymentDataWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetPaymentDataWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setPaymentDataWasSetAction = useCallback(
        async (paymentData: PaymentData) => {
            await dispatch(paymentDataWasSet(paymentData));
        },
        [dispatch],
    );

    return setPaymentDataWasSetAction;
};

export default useSetPaymentDataWasSetAction;

import { PageEnum } from '../../../../../route/page';

export enum WizardNavigationStepType {
    done = 'done',
    current = 'current',
    future = 'future',
}

export interface WizardNavigationStepNumberProps {
    readonly type: WizardNavigationStepType;
    readonly page: PageEnum;
}

import { Controller, useFormContext } from 'react-hook-form';
import { InputAdornment, TextField } from '@material-ui/core';
import { SelectProps } from 'component/form/select/selectProps';
import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const Select: React.FC<SelectProps> = (props) => {
    const { name, label, error, children } = props;

    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            error={error !== undefined}
            as={
                <TextField
                    select
                    label={label}
                    variant="outlined"
                    fullWidth={true}
                    helperText={error}
                    InputProps={{
                        endAdornment:
                            error === undefined ? undefined : (
                                <InputAdornment position={'start'}>
                                    <ReportProblemIcon color={'secondary'} />
                                </InputAdornment>
                            ),
                    }}
                >
                    {children}
                </TextField>
            }
        />
    );
};

export default Select;

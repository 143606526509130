import { BottomNavigationProps } from './bottomNavigationProps';
import { useMediaQuery, useTheme } from '@material-ui/core';
import BottomNavigationPrice from './price';
import Button from '../button';
import React from 'react';
import styled from 'styled-components';

interface BoxComponentProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const BoxComponent = styled.div`
    margin-left: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        if (props.isMediumLayout) {
            return 0;
        }
        return 'auto';
    }};
    margin-right: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return 0;
        }
        if (props.isMediumLayout) {
            return 0;
        }
        return 'auto';
    }};
    width: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return '100%';
        }
        if (props.isMediumLayout) {
            return '100%';
        }
        return 'auto';
    }};
    padding-bottom: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        return 28;
    }}px;
    padding-top: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        return 28;
    }}px;
    max-width: ${(props: BoxComponentProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        if (props.isMediumLayout) {
            return 'auto';
        }
        return '944px';
    }};
`;

const BoxComponentWrap = styled.div`
    background-color: #ffffff;
    bottom: 0;
    position: sticky;
    width: 100%;
    z-index: 1;
`;

interface ButtonRightWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const ButtonRightWrap = styled.div`
    display: flex;
    margin-right: ${(props: ButtonRightWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        if (props.isMediumLayout) {
            return 24;
        }
        return 0;
    }}px;
`;

interface ButtonLeftWrapProps {
    readonly isMediumLayout: boolean;
    readonly isSmallLayout: boolean;
}

const ButtonLeftWrap = styled.div`
    display: flex;
    margin-left: ${(props: ButtonLeftWrapProps) => {
        if (props.isSmallLayout) {
            return 16;
        }
        if (props.isMediumLayout) {
            return 24;
        }
        return 0;
    }}px;
`;

const BottomNavigation: React.FC<BottomNavigationProps> = (props) => {
    const { price, firstButton, secondButton } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    return (
        <BoxComponentWrap>
            <BoxComponent isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout} className="mui-fixed">
                {isSmallLayout && price !== undefined && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: 16,
                        }}
                    >
                        <BottomNavigationPrice label={price.label} price={price.amount} />
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: firstButton === undefined ? 'right' : 'space-between',
                    }}
                >
                    {firstButton !== undefined && (
                        <ButtonLeftWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                            <Button onClick={firstButton.onClick} text={firstButton.text} variant={firstButton.variant} />
                        </ButtonLeftWrap>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {!isSmallLayout && price !== undefined && (
                            <div
                                style={{
                                    display: 'flex',
                                    marginRight: 20,
                                }}
                            >
                                <BottomNavigationPrice label={price.label} price={price.amount} />
                            </div>
                        )}
                        <ButtonRightWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
                            <Button
                                type={secondButton.type}
                                form={secondButton.form}
                                onClick={secondButton.onClick}
                                text={secondButton.text}
                                variant={secondButton.variant}
                                arrowContinue={secondButton.arrowContinue}
                            />
                        </ButtonRightWrap>
                    </div>
                </div>
            </BoxComponent>
        </BoxComponentWrap>
    );
};

export default BottomNavigation;

// import { BottomNavigationProps } from './bottomNavigationProps';
// import { useMediaQuery, useTheme } from '@material-ui/core';
// import BottomNavigationPrice from './price';
// import Button from '../button';
// import React from 'react';
// import styled from 'styled-components';
//
// interface BoxComponentProps {
//     readonly isMediumLayout: boolean;
//     readonly isSmallLayout: boolean;
// }
//
// const BoxComponent = styled.div`
//     margin-left: ${(props: BoxComponentProps) => {
//     if (props.isSmallLayout) {
//         return 0;
//     }
//     if (props.isMediumLayout) {
//         return 0;
//     }
//     return 'auto';
// }}px;
//     margin-right: ${(props: BoxComponentProps) => {
//     if (props.isSmallLayout) {
//         return 0;
//     }
//     if (props.isMediumLayout) {
//         return 0;
//     }
//     return 'auto';
// }}px;
//     position: fixed;
//     bottom: 0;
//     width: ${(props: BoxComponentProps) => {
//     if (props.isSmallLayout) {
//         return '100%';
//     }
//     if (props.isMediumLayout) {
//         return '100%';
//     }
//     return 'auto';
// }};
//     background-color: #ffffff;
//     z-index: 1;
//     padding-bottom: ${(props: BoxComponentProps) => {
//     if (props.isSmallLayout) {
//         return 16;
//     }
//     return 28;
// }}px;
//     max-width: ${(props: BoxComponentProps) => {
//     if (props.isSmallLayout) {
//         return 'auto%';
//     }
//     if (props.isMediumLayout) {
//         return 'auto';
//     }
//     return '944px';
// }}; ;
// `;
//
// interface ButtonRightWrapProps {
//     readonly isMediumLayout: boolean;
//     readonly isSmallLayout: boolean;
// }
//
// const ButtonRightWrap = styled.div`
//     display: flex;
//     margin-right: ${(props: ButtonRightWrapProps) => {
//     if (props.isSmallLayout) {
//         return 16;
//     }
//     if (props.isMediumLayout) {
//         return 24;
//     }
//     return 0;
// }}px;
// `;
//
// interface ButtonLeftWrapProps {
//     readonly isMediumLayout: boolean;
//     readonly isSmallLayout: boolean;
// }
//
// const ButtonLeftWrap = styled.div`
//     display: flex;
//     margin-left: ${(props: ButtonLeftWrapProps) => {
//     if (props.isSmallLayout) {
//         return 16;
//     }
//     if (props.isMediumLayout) {
//         return 24;
//     }
//     return 0;
// }}px;
// `;
//
// const BottomNavigation: React.FC<BottomNavigationProps> = (props) => {
//     const { price, firstButton, secondButton } = props;
//
//     const theme = useTheme();
//     const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
//     const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
//     const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;
//
//     return (
//         <div
//             style={
//                 isSmallLayout === false && isMediumLayout === false
//                     ? {
//                         // maxWidth: 944,
//                         // margin: '0 auto',
//                         position: 'fixed',
//                         width: '100%',
//                     }
//                     : undefined
//             }
//         >
//             <BoxComponent isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
//                 {isSmallLayout && price !== null && (
//                     <div
//                         style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             marginBottom: 16,
//                             marginTop: 16,
//                         }}
//                     >
//                         <BottomNavigationPrice price={price} />
//                     </div>
//                 )}
//                 <div
//                     style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: firstButton === undefined ? 'center' : 'space-between',
//                         marginTop: isSmallLayout && price ? 16 : 28,
//                     }}
//                 >
//                     {firstButton !== undefined && (
//                         <ButtonLeftWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
//                             <Button onClick={firstButton.onClick} text={firstButton.text} variant={firstButton.variant} />
//                         </ButtonLeftWrap>
//                     )}
//                     <div
//                         style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                         }}
//                     >
//                         {!isSmallLayout && price !== null && (
//                             <div
//                                 style={{
//                                     display: 'flex',
//                                     marginRight: 20,
//                                 }}
//                             >
//                                 <BottomNavigationPrice price={price} />
//                             </div>
//                         )}
//                         <ButtonRightWrap isSmallLayout={isSmallLayout} isMediumLayout={isMediumLayout}>
//                             <Button onClick={secondButton.onClick} text={secondButton.text} variant={secondButton.variant} arrowContinue={secondButton.arrowContinue} />
//                         </ButtonRightWrap>
//                     </div>
//                 </div>
//             </BoxComponent>
//         </div>
//     );
// };
//
// export default BottomNavigation;

import { AppLanguageEnum, getNameByAppLanguageEnum } from 'localization/appLanguage';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { NewHeaderProps } from 'component/newHeader/newHeaderProps';
import { useTranslation } from 'react-i18next';
import LanguageSelect from 'component/newHeader/LanguageSelect';
import Logo from 'component/logo';
import MenuItem from 'component/newHeader/MenuItem';
import MenuLink from 'component/newHeader/MenuLink';
import Product from 'component/newHeader/Product';
import React, { useState } from 'react';
import Submenu from 'component/newHeader/Submenu';
import ToggleButton from 'component/newHeader/ToggleButton';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'GeneralSans,sans-serif',
    },
    headerContent: {
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            padding: '22px 16px',
            display: 'flex',
        },
    },
    logoWrapper: {
        display: 'flex',

        [theme.breakpoints.up('md')]: {
            width: '96px',
            marginRight: 'auto',
            justifyContent: 'center',
        },

        [theme.breakpoints.down('md')]: {
            padding: '22px 16px',
            position: 'relative',
            justifyContent: 'flex-end',
        },
    },
    logoLink: {
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
        },
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },

        [theme.breakpoints.down('md')]: {
            padding: '0 16px',
        },
    },
}));

const NewHeader: React.FC<NewHeaderProps> = (props) => {
    const { language, onChangeLanguage } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktopLayout = useMediaQuery(theme.breakpoints.up('md'));

    const [activeSubmenu, setActiveSubmenu] = useState<string | undefined>(undefined);
    const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

    const toggleSubmenu = (item: string) => {
        setActiveSubmenu(activeSubmenu === item ? undefined : item);
    };

    const closeSubmenu = (item: string) => {
        if (activeSubmenu === item) {
            setActiveSubmenu(undefined);
        }
    };

    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const changeLanguage = (newLanguage: AppLanguageEnum) => {
        onChangeLanguage(newLanguage);
        closeSubmenu('language');
    };

    return (
        <header>
            <div style={{ maxWidth: '1440px', marginRight: 'auto', marginLeft: 'auto' }}>
                <div className={classes.headerContent}>
                    <div className={classes.logoWrapper}>
                        <a href={'https://www.adys.lu/?lang=fr'} className={classes.logoLink}>
                            <Logo />
                        </a>
                        {!isDesktopLayout ? <ToggleButton active={mobileMenuVisible} onClick={toggleMobileMenu} /> : null}
                    </div>
                    {isDesktopLayout || mobileMenuVisible ? (
                        <div className={classes.menu}>
                            <MenuItem
                                name={t('header.hygiene')}
                                active={activeSubmenu === 'hygiene'}
                                onClick={() => {
                                    toggleSubmenu('hygiene');
                                }}
                                onClickAway={() => closeSubmenu('hygiene')}
                            >
                                <Submenu style={isDesktopLayout ? { left: `-${language === AppLanguageEnum.english ? 440 : 200}px`, minWidth: '1004px' } : undefined}>
                                    <Product
                                        href={`https://www.adys.lu/services/nettoyage-de-poubelles-conteneurs?lang=${language}`}
                                        name={t('header.cleaningOfWasteBins')}
                                        image={'/wp-content/uploads/2021/04/menu-item-10-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/sortie-et-rentree-des-poubelles?lang=${language}`}
                                        name={t('header.takingWasteBins')}
                                        image={'/wp-content/uploads/2021/04/menu-item-09-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/sortie-des-sacs-valorlux?lang=${language}`}
                                        name={t('header.takingOutValorluxBags')}
                                        image={'/wp-content/uploads/2021/04/menu-item-08-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/station-de-tri?lang=${language}`}
                                        name={t('header.sortingStation')}
                                        image={'/wp-content/uploads/2021/04/R7g1AWdw-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/elimination-de-dechets-encombrants?lang=${language}`}
                                        name={t('header.bulkyWasteRemoval')}
                                        image={'/wp-content/uploads/2021/04/menu-item-06-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/diffuseur-de-parfum?lang=${language}`}
                                        name={t('header.perfumeDiffuser')}
                                        image={'/wp-content/uploads/2021/04/menu-item-05-260x260.png'}
                                    />
                                </Submenu>
                            </MenuItem>
                            <MenuItem
                                name={t('header.pestControl')}
                                active={activeSubmenu === 'ratControl'}
                                onClick={() => {
                                    toggleSubmenu('ratControl');
                                }}
                                onClickAway={() => closeSubmenu('ratControl')}
                            >
                                <Submenu style={isDesktopLayout ? { left: `-${language === AppLanguageEnum.english ? 400 : 180}px`, minWidth: '852px' } : undefined}>
                                    <Product
                                        href={`https://www.adys.lu/services/desinsectisation?lang=${language}`}
                                        name={t('header.insectControl')}
                                        image={'/wp-content/uploads/2021/04/menu-item-04-e1619101985371.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/deratisation?lang=${language}`}
                                        name={t('header.ratControl')}
                                        image={'/wp-content/uploads/2021/04/menu-item-03-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/desinfection?lang=${language}`}
                                        name={t('header.disinfection')}
                                        image={'/wp-content/uploads/2021/04/menu-item-02-260x260.png'}
                                    />
                                    <Product
                                        href={`https://www.adys.lu/services/lutte-contre-les-guepes?lang=${language}`}
                                        name={t('header.waspControl')}
                                        image={'/wp-content/uploads/2021/04/menu-item-01-260x260.png'}
                                    />
                                    <Product href={`https://www.adys.lu/horesca?lang=${language}`} name={t('header.horesca')} image={'/wp-content/uploads/2021/09/horesca-scaled-260x260.jpeg'} />
                                </Submenu>
                            </MenuItem>
                            <MenuLink href={`https://www.adys.lu/about-us/?lang=${language}`}>{t('header.aboutUs')}</MenuLink>
                            <MenuLink href={`https://www.adys.lu/?lang=${language}#contact`}>{t('header.contactUs')}</MenuLink>
                            <MenuItem
                                name={t(getNameByAppLanguageEnum(language))}
                                active={activeSubmenu === 'language'}
                                onClick={() => {
                                    toggleSubmenu('language');
                                }}
                                onClickAway={() => closeSubmenu('language')}
                            >
                                <Submenu style={{ right: 0, padding: 0 }}>
                                    <LanguageSelect language={language} onChange={changeLanguage} />
                                </Submenu>
                            </MenuItem>
                        </div>
                    ) : null}
                </div>
            </div>
        </header>
    );
};

export default NewHeader;

import { Box, makeStyles } from '@material-ui/core';
import { ButtonVariant } from 'component/button/buttonProps';
import { OtherServiceProps } from 'component/otherServices/otherService/OtherServiceProps';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'component/button';
import React from 'react';
import TitleTypeService from 'component/titleTypeService';
import useSetNewOrderStarted from 'store/order/hook/useSetNewOrderStarted';

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: theme.palette.grey[400],
        borderStyle: 'solid',
        borderWidth: 1,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,

        [theme.breakpoints.down('xs')]: {
            '&:not(:first-child)': {
                borderTop: 'none',
            },
        },

        [theme.breakpoints.up('sm')]: {
            '&:not(:first-child)': {
                borderLeft: 'none',
            },
        },
    },
}));

const OtherService: React.FC<OtherServiceProps> = (props) => {
    const { service, pathMoreInfo, pathOrderNow } = props;
    const history = useHistory();
    const startNewOrder = useSetNewOrderStarted();
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box marginBottom={4}>
                <TitleTypeService serviceType={service} leftIcon={true} />
            </Box>
            <Box display="flex">
                {pathMoreInfo && (
                    <Box marginRight={2}>
                        <Button
                            href={pathMoreInfo}
                            text={t('order.screen.success.otherService.moreInfo')}
                            variant={ButtonVariant.outlined}
                        />
                    </Box>
                )}
                <Button
                    onClick={async () => {
                        await startNewOrder(service);
                        history.push(pathOrderNow);
                    }}
                    text={t('order.screen.success.otherService.orderNow')}
                    variant={ButtonVariant.contained}
                />
            </Box>
        </div>
    );
};

export default OtherService;

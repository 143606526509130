import { binCleaningAddressRoute, binCleaningConfirmationRoute, binCleaningPaymentRoute, binCleaningServiceRoute, binCleaningSuccessRoute, binCleaningWelcomeRoute } from 'route/routeBinCleaning';
import { insectControlAddressRoute, insectControlConfirmRoute, insectControlPaymentRoute, insectControlServiceRoute } from 'route/routeInsectControl';
import { ratControlAddressRoute, ratControlConfirmRoute, ratControlPaymentRoute, ratControlServiceRoute } from 'route/routeRatControl';
import i18next from 'i18next';

export enum PageEnum {
    welcome = 'welcome',
    cleaningAddress = 'cleaningAddress',
    cleaningService = 'cleaningService',
    cleaningConfirm = 'cleaningConfirm',
    cleaningPayment = 'cleaningPayment',
    ratControlAddress = 'ratControlAddress',
    ratControlService = 'ratControlService',
    ratControlConfirm = 'ratControlConfirm',
    ratControlPayment = 'ratControlPayment',
    insectControlAddress = 'insectControlAddress',
    insectControlService = 'insectControlService',
    insectControlConfirm = 'insectControlConfirm',
    insectControlPayment = 'insectControlPayment',
    personalInformation = 'personalInformation',
    congratulations = 'congratulations',
}

export const getStepNumberByPage = (page: PageEnum): number => {
    switch (page) {
        case PageEnum.welcome:
            throw new Error('PageEnum.welcome is not supported in getStepNumberByPage');
        case PageEnum.ratControlAddress:
            return 1;
        case PageEnum.ratControlService:
            return 2;
        case PageEnum.ratControlConfirm:
            return 3;
        case PageEnum.ratControlPayment:
            return 4;
        case PageEnum.insectControlAddress:
            return 1;
        case PageEnum.insectControlService:
            return 2;
        case PageEnum.insectControlConfirm:
            return 3;
        case PageEnum.insectControlPayment:
            return 4;
        case PageEnum.cleaningAddress:
            return 1;
        case PageEnum.cleaningService:
            return 2;
        case PageEnum.cleaningConfirm:
            return 3;
        case PageEnum.cleaningPayment:
            return 4;
        // case PageEnum.personalInformation:
        //     return 3;
        case PageEnum.congratulations:
            throw new Error('PageEnum.congratulations is not supported in getStepNumberByPage');
        default:
            throw new Error('Unexpected PageEnum in getStepNumberByPage');
    }
};

export const getNavigationNameForPageEnum = (page: PageEnum): string => {
    switch (page) {
        case PageEnum.welcome:
            throw new Error('PageEnum.welcome is not supported in getNavigationNameForPageEnum');
        case PageEnum.ratControlAddress:
        case PageEnum.insectControlAddress:
        case PageEnum.cleaningAddress:
            return i18next.t('order.screen.address.title.deliveryAddress');
        case PageEnum.cleaningService:
            return i18next.t('order.screen.cleaningService.title');
        case PageEnum.ratControlService:
        case PageEnum.insectControlService:
            return i18next.t('order.screen.ratControlService.title');
        case PageEnum.personalInformation:
            return i18next.t('order.screen.personalInformation.title');
        case PageEnum.ratControlConfirm:
        case PageEnum.insectControlConfirm:
        case PageEnum.cleaningConfirm:
            return i18next.t('order.screen.confirmation.title');
        case PageEnum.ratControlPayment:
        case PageEnum.insectControlPayment:
        case PageEnum.cleaningPayment:
            return i18next.t('order.screen.payment.title');
        case PageEnum.congratulations:
            return i18next.t('order.screen.success.navigationName');
        default:
            throw new Error('Unexpected PageEnum in translatePageEnum');
    }
};

export const getRouteByPageEnum = (page: PageEnum): string => {
    switch (page) {
        case PageEnum.welcome:
            return binCleaningWelcomeRoute.url();
        case PageEnum.cleaningAddress:
            return binCleaningAddressRoute.url();
        case PageEnum.cleaningService:
            return binCleaningServiceRoute.url();
        case PageEnum.cleaningConfirm:
            return binCleaningConfirmationRoute.url();
        case PageEnum.cleaningPayment:
            return binCleaningPaymentRoute.url();
        case PageEnum.ratControlAddress:
            return ratControlAddressRoute.url();
        case PageEnum.ratControlService:
            return ratControlServiceRoute.url();
        case PageEnum.ratControlPayment:
            return ratControlPaymentRoute.url();
        case PageEnum.ratControlConfirm:
            return ratControlConfirmRoute.url();
        case PageEnum.insectControlAddress:
            return insectControlAddressRoute.url();
        case PageEnum.insectControlService:
            return insectControlServiceRoute.url();
        case PageEnum.insectControlPayment:
            return insectControlPaymentRoute.url();
        case PageEnum.insectControlConfirm:
            return insectControlConfirmRoute.url();
        // case PageEnum.personalInformation:
        //     return personalInformationRoute.url();
        case PageEnum.congratulations:
            return binCleaningSuccessRoute.url();
        default:
            throw new Error('Unexpected PageEnum in getRouteByPageEnum');
    }
};

export const getTitleForPageEnum = (page: PageEnum): string => {
    switch (page) {
        case PageEnum.welcome:
            return i18next.t('order.screen.welcome.title');
        case PageEnum.ratControlAddress:
        case PageEnum.insectControlAddress:
        case PageEnum.cleaningAddress:
            return i18next.t('order.screen.address.title.deliveryAddress');
        case PageEnum.cleaningService:
            return i18next.t('order.screen.cleaningService.title');
        case PageEnum.ratControlService:
        case PageEnum.insectControlService:
            return i18next.t('order.screen.ratControlService.title');
        // case PageEnum.personalInformation:
        //     return i18next.t('order.screen.personalInformation.title');
        case PageEnum.cleaningConfirm:
            return i18next.t('order.screen.confirmation.title');
        case PageEnum.ratControlConfirm:
        case PageEnum.insectControlConfirm:
            return i18next.t('order.screen.confirmation.title');
        case PageEnum.ratControlPayment:
        case PageEnum.insectControlPayment:
        case PageEnum.cleaningPayment:
            return i18next.t('order.screen.payment.title');
        case PageEnum.congratulations:
            throw new Error('PagePageEnum.congratulations is not supported for getTitleForPageEnum');
        default:
            throw new Error('Unexpected PageEnum in translatePageEnum');
    }
};

export const getSubtitleForPageEnum = (page: PageEnum): string => {
    switch (page) {
        case PageEnum.welcome:
            return i18next.t('order.screen.welcome.subtitle');
        case PageEnum.ratControlAddress:
            return i18next.t('order.screen.address.subtitle.deliveryAddress.ratControl');
        case PageEnum.insectControlAddress:
            return i18next.t('order.screen.address.subtitle.deliveryAddress.insectControl');
        case PageEnum.cleaningAddress:
            return i18next.t('order.screen.address.subtitle.deliveryAddress.binCleaning');
        case PageEnum.cleaningService:
            return i18next.t('order.screen.cleaningService.subtitle');
        case PageEnum.ratControlService:
        case PageEnum.insectControlService:
            return i18next.t('order.screen.ratControlService.subtitle');
        // case PageEnum.personalInformation:
        //     return i18next.t('order.screen.personalInformation.subtitle');
        case PageEnum.ratControlPayment:
        case PageEnum.insectControlPayment:
        case PageEnum.cleaningPayment:
            throw new Error('PageEnum.payment is not supported for getSubtitleForPageEnum');
        case PageEnum.congratulations:
            throw new Error('PagePageEnum.congratulations is not supported for getSubtitleForPageEnum');
        default:
            throw new Error('Unexpected PageEnum in translatePageEnum');
    }
};

import axios from 'axios';

const instance = axios.create({
    // headers: {
    //     get: {
    //         'Content-Type': 'application/json',
    //     },
    //     post: {
    //         'Content-Type': 'application/json',
    //     },
    //     'Content-Type': 'application/json',
    // },
    // timeout: 8000, // 8 seconds
    // transformRequest: [
    //     (data, headers) => {
    //         headers['Content-Type'] = 'application/json';
    //         return JSON.stringify(data);
    //     },
    // ],
});

export default instance;

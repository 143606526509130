import { Address } from '../../../model/forSending/address';
import { binCleaningAddressRoute, binCleaningPaymentRoute, binCleaningServiceRoute, binCleaningSuccessRoute } from 'route/routeBinCleaning';
import { Box, Checkbox, FormControlLabel, FormHelperText, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonVariant } from '../../button/buttonProps';
import { confirmationSchema } from '../../../store/order/state';
import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { getInterestCustomerUrl } from '../../../api/url/apiBackEndUrl';
import { getLocaleByLanguageStringWithUndefined } from '../../../localization/appLanguage';
import { getNavigationNameForPageEnum, getTitleForPageEnum, PageEnum } from '../../../route/page';
import { getUrlWithConditionsAndTerms } from '../../../config/url';
import { InfoBoxBinValues } from 'component/confirmData/infoBoxBins/bin/infoBoxBinProps';
import { newAddedStreetId } from '../../../config/newAddedStreet';
import { ServiceType } from 'common/serviceType';
import { TextInputSize } from '../../form/textInput/textInputProps';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from '../../../system/axios/axios';
import ConfirmData from '../../confirmData';
import i18next from 'i18next';
import InfoBoxBins from 'component/confirmData/infoBoxBins';
import PageWrapper from 'component/pageWrapper';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ServerError from '../../error/serverError';
import TextInput from '../../form/textInput';
import useBillingClient from 'store/order/hook/address/useBillingClient/useBillingClient';
import useCleaningServiceData from '../../../store/order/hook/cleaningService/useCleaningServiceData/useCleaningServiceData';
import useConfirmationData from '../../../store/order/hook/confirmation/useConfirmationData/useConfirmationData';
import useDeliveryClient from 'store/order/hook/address/useDeliveryClient/useDeliveryClient';
import useSetConfirmationDataWasSetAction from '../../../store/order/hook/confirmation/useSetMessageWasSetAction/useSetConfirmationDataWasSetAction';

interface FormInputs {
    readonly agreeTerms: true;
    readonly message: string | null;
}

interface CleaningService {
    readonly quantity: number;
    readonly tariffConfigId: number;
}

interface Interest {
    readonly billingAddress: Address | null;
    readonly cityId: number;
    readonly cleaningServices: CleaningService[];
    readonly customerMessage: string;
    readonly emailAddress: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly locale: string;
    readonly phoneNumber: string;
    readonly postalCodeId: number;
    readonly streetAdded?: string;
    readonly streetId: number | null;
    readonly streetNumber: string;
}

const CleaningConfirmationPage: React.FC<{}> = () => {
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();
    const setConfirmationDataWasSetAction = useSetConfirmationDataWasSetAction();
    const history = useHistory();
    const cleaningServiceData = useCleaningServiceData();
    const deliveryClient = useDeliveryClient();
    const billingAddressData = useBillingClient();
    const confirmationData = useConfirmationData();

    const [sendInterestNow, setSendInterestNow] = useState<boolean>(false);

    const termsRef = useRef<HTMLFormElement>(null);

    const { register, errors, handleSubmit } = useForm<FormInputs>({
        resolver: zodResolver(confirmationSchema),
        defaultValues: {
            message: confirmationData?.message,
        },
    });

    const { data: dataSuccess } = useQuery<boolean>(
        ['confirmationInterest'],
        async () => {
            if (deliveryClient === undefined) {
                throw new Error('Unexpected: deliveryClientData is undefined');
            }
            if (deliveryClient.person === undefined) {
                throw new Error('Unexpected: deliveryClientData is undefined');
            }
            if (cleaningServiceData === undefined) {
                throw new Error('Unexpected: cleaningServiceData is undefined');
            }
            if (confirmationData === undefined) {
                throw new Error('Unexpected: confirmationData is undefined');
            }

            let postData: Interest = {
                cityId: deliveryClient.address.city.id,
                customerMessage: confirmationData.message === null ? '' : confirmationData.message,
                emailAddress: deliveryClient.email,
                firstName: deliveryClient.person.firstName,
                lastName: deliveryClient.person.lastName,
                streetNumber: deliveryClient.address.streetNo,
                streetId: deliveryClient.address.street.id === newAddedStreetId ? null : deliveryClient.address.street.id,
                postalCodeId: deliveryClient.address.postalCode.id,
                phoneNumber: deliveryClient.phone,
                cleaningServices: cleaningServiceData.tariffItems.map((item) => {
                    return {
                        quantity: item.count,
                        tariffConfigId: item.tariffConfigId,
                    };
                }),
                locale: getLocaleByLanguageStringWithUndefined(i18next.language),
                billingAddress:
                    billingAddressData === null || billingAddressData === undefined
                        ? null
                        : {
                              postalCodeId: billingAddressData.address.postalCode.id,
                              cityId: billingAddressData.address.city.id,
                              streetNumber: billingAddressData.address.streetNo,
                              streetId: billingAddressData.address.street.id === 0 ? null : billingAddressData.address.street.id,
                              streetAdded: billingAddressData.address.street.id === 0 ? billingAddressData.address.street.name : null,
                          },
            };
            if (deliveryClient.address.street.id === newAddedStreetId) {
                postData = {
                    ...postData,
                    streetAdded: deliveryClient.address.street.name,
                };
            }

            const response = await axios.post(getInterestCustomerUrl(), postData);
            const responseData = response.data;
            return responseData.success;
        },
        {
            enabled: sendInterestNow === true,
        },
    );

    useEffect(() => {
        if (dataSuccess === true) {
            history.push(binCleaningSuccessRoute.url());
        }
    }, [dataSuccess, history]);

    const onSubmit = async (data: FormInputs) => {
        await setConfirmationDataWasSetAction(data);
        if (cleaningServiceData === undefined) {
            throw new Error('Unexpected: cleaningServiceData is undefined2');
        }
        if (cleaningServiceData.price <= 0) {
            setSendInterestNow(true);
            return;
        }
        history.push(binCleaningPaymentRoute.url());
    };

    const termsAndConditionsError = errors.agreeTerms?.type === 'invalid_literal_value';

    useEffect(() => {
        if (termsAndConditionsError === true) {
            // @ts-ignore
            termsRef.current.scrollIntoView();
        }
    }, [termsAndConditionsError]);

    const subtitle = useMemo(() => {
        if (cleaningServiceData?.price !== undefined && cleaningServiceData.price > 0) {
            return t('order.screen.confirmation.subtitle');
        }

        return t('order.screen.confirmation.subtitleWithoutPayment');
    }, [cleaningServiceData?.price, t]);

    if (deliveryClient === undefined || billingAddressData === undefined) {
        history.push(binCleaningAddressRoute.url());
        return null;
    }
    if (cleaningServiceData === undefined) {
        history.push(binCleaningServiceRoute.url());
        return null;
    }

    if (dataSuccess === false) {
        return <ServerError />;
    }

    const items = cleaningServiceData.tariffItems.map<InfoBoxBinValues>((tariffItem) => {
        return {
            available: tariffItem.binColor !== null,
            cleaningFrequency: tariffItem.cleaningFrequency,
            count: tariffItem.count,
            binType: tariffItem.binType,
            binColor: tariffItem.binColor,
            selectedMonths: tariffItem.selectedMonths,
        };
    });

    return (
        <PageWrapper
            page={PageEnum.cleaningConfirm}
            pageTitle={getNavigationNameForPageEnum(PageEnum.cleaningConfirm)}
            serviceType={ServiceType.binsCleaning}
            wizardItems={[PageEnum.cleaningAddress, PageEnum.cleaningService, PageEnum.cleaningConfirm, PageEnum.cleaningPayment]}
            title={flc(getTitleForPageEnum(PageEnum.cleaningConfirm))}
            subtitle={subtitle}
            price={{
                label: flc(t('order.pricePerYear')),
                amount: cleaningServiceData.price,
            }}
            secondButton={{
                arrowContinue: true,
                onClick: handleSubmit(onSubmit),
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(binCleaningServiceRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            <ConfirmData deliveryClientData={deliveryClient} billingClientData={billingAddressData}>
                <InfoBoxBins items={items} />
            </ConfirmData>

            <Box marginTop={isSmallLayout ? 0 : '20px'}>
                <form onSubmit={handleSubmit(onSubmit)} ref={termsRef}>
                    <Box minHeight={'70px'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputRef={register}
                                    style={
                                        {
                                            // backgroundColor: 'white',
                                            // color: '#ffffff',
                                        }
                                    }
                                    name={'agreeTerms'}
                                />
                            }
                            label={
                                <>
                                    {t('order.screen.confirmation.termAndConditions.label')}&nbsp;
                                    <a href={getUrlWithConditionsAndTerms()} target={'_blank'}>
                                        {t('order.screen.confirmation.termAndConditions.labelLing')}
                                    </a>
                                </>
                            }
                        />
                        {termsAndConditionsError === true && (
                            <FormHelperText
                                style={{
                                    color: theme.palette.secondary.main,
                                    marginLeft: 0,
                                    marginTop: 0,
                                }}
                            >
                                {t('order.screen.confirmation.termAndConditions.required')}
                            </FormHelperText>
                        )}
                    </Box>
                    <Box>
                        <TextInput name="message" size={TextInputSize.big} placeholder={flc(t('order.screen.confirmation.form.message.placeholder'))} inputRef={register} rows={3} />
                    </Box>
                </form>
            </Box>
            <Box marginTop={'16px'}>&nbsp;</Box>
        </PageWrapper>
    );
};

export default CleaningConfirmationPage;

import { AddressBlockProps } from 'component/form/addressForm/addressBlock/addressBlockProps';
import { AutocompleteSelectItem, AutocompleteSelectSize } from 'component/form/autocompleteSelect/autocompleteSelectProps';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { City } from 'model/city/citySchema';
import { CustomerType } from 'component/form/addressForm/index';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getLocationUrl } from 'api/url/apiBackEndUrl';
import { parseCityList } from 'model/city/parser/parseCity';
import { Street } from 'model/street/streetSchema';
import { TextInputSize } from 'component/form/textInput/textInputProps';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import AutocompleteSelect from 'component/form/autocompleteSelect';
import axios from 'system/axios/axios';
import OrderTitle from 'component/orderTitle';
import React, { useEffect } from 'react';
import ServerError from 'component/error/serverError';
import SwitchInput from 'component/form/switchInput';
import TextInput from 'component/form/textInput';

const AddressBlock: React.FC<AddressBlockProps> = (props) => {
    const { name, zipCodes, showTypeSwitch } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const { register, watch, errors, setValue } = useFormContext();

    const zip = watch(`${name}.zip`);
    const type = watch(`${name}.type`);

    const { data: citiesAndStreets, isError: isErrorLocations } = useQuery<{ cities: City[]; streets: Street[] }>(
        ['locations', zip?.id],
        async () => {
            const postalCodeId = zip?.id;
            if (postalCodeId === undefined) {
                throw new Error('Unexpected: postalCodeId is undefined');
            }

            const response = await axios.get(getLocationUrl(postalCodeId));
            return {
                cities: parseCityList(response.data.data.cities),
                streets: parseCityList(response.data.data.streets),
            };
        },
        {
            enabled: zip !== null && zip.id !== 0,
            refetchOnMount: false,
            onSuccess: (data) => {
                setValue(`${name}.street`, data.streets[0]);
                setValue(`${name}.city`, data.cities[0]);
            },
        },
    );

    useEffect(() => {
        if (zip === null) {
            setValue(`${name}.street`, null);
            setValue(`${name}.city`, null);
        }
    }, [zip, name, setValue]);

    if (isErrorLocations) {
        return <ServerError />;
    }

    return (
        <>
            <Box mb={1}>
                <AutocompleteSelect
                    items={zipCodes.map<AutocompleteSelectItem>((item) => ({ id: item.id, name: item.code }))}
                    name={`${name}.zip`}
                    size={AutocompleteSelectSize.small}
                    error={errors[name] && errors[name].zip && t('order.screen.address.form.zip.required')}
                    label={flc(t('order.screen.address.form.zip.name'))}
                    addNew={true}
                    addLabelText={flc(t('order.screen.address.form.contactUs'))}
                    filterMinLength={2}
                />
            </Box>

            <div
                style={{
                    display: 'flex',
                    flexDirection: isSmallLayout ? 'column' : 'row',
                    marginBottom: 8,
                }}
            >
                <TextInput
                    name={`${name}.streetNo`}
                    size={TextInputSize.small}
                    label={flc(t('order.screen.address.form.streetNo.name'))}
                    inputRef={register}
                    disabled={zip === null}
                    error={errors[name] && errors[name].streetNo && t('order.screen.address.form.streetNo.required')}
                />
                <div
                    style={{
                        marginLeft: isSmallLayout ? 0 : 16,
                    }}
                >
                    <AutocompleteSelect
                        items={citiesAndStreets?.streets ?? []}
                        name={`${name}.street`}
                        size={AutocompleteSelectSize.big}
                        error={errors[name] && errors[name].street && t('order.screen.address.form.street.required')}
                        label={flc(t('order.screen.address.form.street.name'))}
                        disabled={zip === null}
                        addNew={true}
                        addLabelText={flc(t('order.screen.address.form.addStreet'))}
                    />
                </div>
            </div>
            <AutocompleteSelect
                items={citiesAndStreets?.cities ?? []}
                name={`${name}.city`}
                size={AutocompleteSelectSize.big}
                error={errors[name] && errors[name].city && t('order.screen.address.form.city.required')}
                label={flc(t('order.screen.address.form.city.name'))}
                disabled={zip === null}
            />

            <OrderTitle
                title={t('order.screen.address.title.customerInfo')}
                subtitle={t('order.screen.address.subtitle.customerInfo')}
                titleStyle={{
                    fontSize: 30,
                }}
            />

            <Box mb={2} display={showTypeSwitch === false ? 'none' : undefined}>
                <SwitchInput
                    items={[
                        {
                            value: CustomerType.person,
                            name: t('enum.customerType.private').toUpperCase(),
                        },
                        {
                            value: CustomerType.company,
                            name: t('enum.customerType.company').toUpperCase(),
                        },
                    ]}
                    name={`${name}.type`}
                />
            </Box>

            {type === CustomerType.person && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: isSmallLayout ? 'column' : 'row',
                        marginBottom: 8,
                    }}
                >
                    <TextInput
                        name={`${name}.personFirstName`}
                        label={flc(t('order.screen.address.form.firstName.name'))}
                        inputRef={register}
                        size={TextInputSize.big}
                        error={errors[name] && errors[name]['personFirstName'] && t('order.screen.address.form.firstName.required')}
                    />
                    <div
                        style={{
                            marginLeft: isSmallLayout ? 0 : 16,
                        }}
                    >
                        <TextInput
                            name={`${name}.personLastName`}
                            label={flc(t('order.screen.address.form.lastName.name'))}
                            inputRef={register}
                            size={TextInputSize.big}
                            error={errors[name] && errors[name]['personLastName'] && t('order.screen.address.form.lastName.required')}
                        />
                    </div>
                </div>
            )}
            {type === CustomerType.company && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: isSmallLayout ? 'column' : 'row',
                        marginBottom: 8,
                    }}
                >
                    <TextInput
                        name={`${name}.companyName`}
                        label={flc(t('order.screen.address.form.companyName.name'))}
                        inputRef={register}
                        size={TextInputSize.big}
                        error={errors[name] && errors[name]['companyName'] && t('order.screen.address.form.companyName.required')}
                    />
                    <div
                        style={{
                            marginLeft: isSmallLayout ? 0 : 16,
                        }}
                    >
                        <TextInput
                            name={`${name}.companyVatNumber`}
                            label={flc(t('order.screen.address.form.vatNumber.name'))}
                            inputRef={register}
                            size={TextInputSize.big}
                            error={errors[name] && errors[name]['companyVatNumber'] && t('order.screen.address.form.vatNumber.required')}
                        />
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: isSmallLayout ? 'column' : 'row',
                }}
            >
                <TextInput
                    name={`${name}.email`}
                    html5Type={'email'}
                    label={flc(t('order.screen.address.form.email.name'))}
                    inputRef={register}
                    size={TextInputSize.big}
                    error={errors[name] && errors[name].email && t('order.screen.address.form.email.invalid')}
                />
                <div
                    style={{
                        marginLeft: isSmallLayout ? 0 : 16,
                    }}
                >
                    <TextInput
                        name={`${name}.phone`}
                        html5Type={'tel'}
                        label={flc(t('order.screen.address.form.phone.name'))}
                        inputRef={register}
                        size={TextInputSize.big}
                        error={errors[name] && errors[name].phone && t('order.screen.address.form.phone.required')}
                    />
                </div>
            </div>
        </>
    );
};

export default AddressBlock;

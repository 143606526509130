import * as z from 'zod';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, MenuItem } from '@material-ui/core';
import { RatControlFormProps } from 'component/form/ratControlForm/ratControlFormProps';
import { RatControlTypeEnum, translateRatControlTypeEnum } from 'model/ratControl/ratControlType/ratControlTypeEnum';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import FileInput from 'component/form/fileInput';
import React from 'react';
import Select from 'component/form/select';
import TextField from 'component/form/textField';

const ratControlFormValuesSchema = z.object({
    images: z.array(z.instanceof(File)),
    problem: z.nativeEnum(RatControlTypeEnum),
    description: z.string().optional(),
});

export type RatControlFormValues = z.infer<typeof ratControlFormValuesSchema>;

const RatControlForm: React.FC<RatControlFormProps> = (props) => {
    const { id, onSubmit, defaultValues } = props;
    const { t } = useTranslation();

    const methods = useForm<RatControlFormValues>({
        resolver: zodResolver(ratControlFormValuesSchema),
        defaultValues: {
            images: defaultValues?.images ?? [],
            // @ts-ignore
            problem: defaultValues?.problem ?? '',
            description: defaultValues?.description,
        },
    });
    const { handleSubmit, errors } = methods;

    return (
        <FormProvider {...methods}>
            <form id={id} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FileInput
                            name="images"
                            label={flc(t('order.screen.ratControlService.form.images.label'))}
                            error={errors.images && t('order.screen.ratControlService.form.images.required')}
                            accept="image/*"
                            multiple={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select name="problem" label={flc(t('order.screen.ratControlService.form.problem.label'))} error={errors.problem && t('order.screen.ratControlService.form.problem.required')}>
                            <MenuItem value={RatControlTypeEnum.rats}> {translateRatControlTypeEnum(RatControlTypeEnum.rats)}</MenuItem>
                            <MenuItem value={RatControlTypeEnum.mice}> {translateRatControlTypeEnum(RatControlTypeEnum.mice)}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="description"
                            label={flc(t('order.screen.ratControlService.form.description.label'))}
                            error={errors.description && t('order.screen.ratControlService.form.description.required')}
                            variant="outlined"
                            fullWidth
                            rows={4}
                            margin="none"
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default RatControlForm;

import {
    binCleaningAddressRoute,
    binCleaningConfirmationRoute,
    binCleaningDefaultRoute,
    binCleaningFrRoute,
    binCleaningLbRoute,
    binCleaningPaymentRoute,
    binCleaningServiceRoute,
    binCleaningSuccessRoute,
    embeddedCalculatorFrRoute,
    embeddedCalculatorLbRoute,
    embeddedCalculatorRoute,
} from 'route/routeBinCleaning';
import { Box } from '@material-ui/core';
import { insectControlAddressRoute, insectControlConfirmRoute, insectControlFrRoute, insectControlLbRoute, insectControlPaymentRoute, insectControlServiceRoute, insectControlSuccessRoute } from 'route/routeInsectControl';
import { ratControlAddressRoute, ratControlConfirmRoute, ratControlFrRoute, ratControlLbRoute, ratControlPaymentRoute, ratControlServiceRoute, ratControlSuccessRoute } from 'route/routeRatControl';
import { rootRoute } from 'route/route';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CleaningAddressPage from './component/page/cleaningAddress';
import CleaningConfirmationPage from './component/page/cleaningConfirmation';
import CleaningFr from 'component/page/cleaningFr';
import CleaningLb from 'component/page/cleaningLb';
import CleaningPaymentPage from './component/page/cleaningPayment';
import CleaningServicePage from './component/page/cleaningService';
import CleaningSuccessPage from './component/page/cleaningSuccess';
import EmbeddedCalculator from "component/page/embeddedCalculator";
import EmbeddedCalculatorFr from "component/page/embeddedCalculatorFr";
import EmbeddedCalculatorLb from "component/page/embeddedCalculatorLb";
import FacebookDomainVerification from 'component/system/facebookDomainVerification';
import i18next from 'i18next';
import InsectControlAddressPage from 'component/page/insectControlAddress';
import InsectControlConfirmationPage from 'component/page/insectControlConfirmation';
import InsectControlFr from 'component/page/insectControlFr';
import InsectControlLb from 'component/page/insectControlLb';
import InsectControlPaymentPage from 'component/page/insectControlPayment';
import InsectControlServicePage from 'component/page/insectControlService';
import InsectControlSuccessPage from 'component/page/insectControlSuccess';
import NotFoundPage from './component/page/notFound';
import RatControlAddressPage from 'component/page/ratControlAddress';
import RatControlConfirmationPage from 'component/page/ratControlConfirmation';
import RatControlFr from 'component/page/ratControlFr';
import RatControlLb from 'component/page/ratControlLb';
import RatControlPaymentPage from 'component/page/ratControlPayment';
import RatControlServicePage from 'component/page/ratControlService';
import RatControlSuccessPage from 'component/page/ratControlSuccess';
import React, { useEffect } from 'react';
import Root from 'component/page/root';
import useScrollToTop from 'component/hook/useScrollToTop/useScrollToTop';
import WelcomePage from './component/page/welcome';

const App: React.FC<{}> = () => {
    const { t } = useTranslation();

    useScrollToTop();

    useEffect(() => {
        document.title = t('title.default');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language]);

    return (
        <>
            <FacebookDomainVerification />
            <Box>
                <Switch>
                    <Route path={rootRoute.pattern()} exact component={Root} />
                    <Route path={embeddedCalculatorRoute.pattern()} exact component={EmbeddedCalculator} />
                    <Route path={embeddedCalculatorFrRoute.pattern()} exact component={EmbeddedCalculatorFr} />
                    <Route path={embeddedCalculatorLbRoute.pattern()} exact component={EmbeddedCalculatorLb} />
                    <Route path={binCleaningFrRoute.pattern()} exact component={CleaningFr} />
                    <Route path={binCleaningLbRoute.pattern()} exact component={CleaningLb} />
                    <Route path={binCleaningDefaultRoute.pattern()} exact component={WelcomePage} />
                    <Route path={binCleaningAddressRoute.pattern()} exact component={CleaningAddressPage} />
                    <Route path={binCleaningServiceRoute.pattern()} exact component={CleaningServicePage} />
                    <Route path={binCleaningConfirmationRoute.pattern()} exact component={CleaningConfirmationPage} />
                    <Route path={binCleaningPaymentRoute.pattern()} exact component={CleaningPaymentPage} />
                    <Route path={binCleaningSuccessRoute.pattern()} exact component={CleaningSuccessPage} />
                    <Route path={ratControlFrRoute.pattern()} exact component={RatControlFr} />
                    <Route path={ratControlLbRoute.pattern()} exact component={RatControlLb} />
                    <Route path={ratControlAddressRoute.pattern()} exact component={RatControlAddressPage} />
                    <Route path={ratControlServiceRoute.pattern()} exact component={RatControlServicePage} />
                    <Route path={ratControlConfirmRoute.pattern()} exact component={RatControlConfirmationPage} />
                    <Route path={ratControlPaymentRoute.pattern()} exact component={RatControlPaymentPage} />
                    <Route path={ratControlSuccessRoute.pattern()} exact component={RatControlSuccessPage} />
                    <Route path={insectControlFrRoute.pattern()} exact component={InsectControlFr} />
                    <Route path={insectControlLbRoute.pattern()} exact component={InsectControlLb} />
                    <Route path={insectControlAddressRoute.pattern()} exact component={InsectControlAddressPage} />
                    <Route path={insectControlServiceRoute.pattern()} exact component={InsectControlServicePage} />
                    <Route path={insectControlConfirmRoute.pattern()} exact component={InsectControlConfirmationPage} />
                    <Route path={insectControlPaymentRoute.pattern()} exact component={InsectControlPaymentPage} />
                    <Route path={insectControlSuccessRoute.pattern()} exact component={InsectControlSuccessPage} />
                    <Route path="*" component={NotFoundPage} />;
                </Switch>
            </Box>
        </>
    );
};

export default App;

import { AppState } from '../../../../appState';
import { useSelector } from 'react-redux';

const useOrderWasCreated = (): boolean => {
    return useSelector<AppState, boolean>((state) => {
        return state.order.orderWasCreated;
    });
};

export default useOrderWasCreated;

import { ServiceType } from 'common/serviceType';
import { ServiceTypeIconProps } from 'component/serviceTypeIcon/serviceTypeIconProps';
import { ServiceTypeIconSize } from 'component/serviceTypeIcon/serviceTypeIconSize';
import Bin from 'assets/typeService/bin.svg';
import Insect from 'assets/typeService/insect.svg';
import Rat from 'assets/typeService/rat.svg';
import React from 'react';
import styled from 'styled-components';

const dimensionSmall = 20; //px
const dimensionBig = 36; //px

interface BoxProps {
    readonly height: number;
    readonly width: number;
}

const Box = styled.div`
    height: ${(props: BoxProps) => props.height}px;
    width: ${(props: BoxProps) => props.width}px;
`;

const ServiceTypeIcon: React.FC<ServiceTypeIconProps> = (props) => {
    const { serviceType, size } = props;

    let height: number;
    let width: number;
    switch (size) {
        case ServiceTypeIconSize.small:
            height = dimensionSmall;
            width = dimensionSmall;
            break;
        case ServiceTypeIconSize.big:
            height = dimensionBig;
            width = dimensionBig;
            break;
        default:
            throw new Error('Unexpected ServiceTypeIconSize in ServiceTypeIcon');
    }

    let svg: string;
    switch (serviceType) {
        case ServiceType.binsCleaning:
            svg = Bin;
            break;
        case ServiceType.insectControl:
            svg = Insect;
            break;
        case ServiceType.ratControl:
            svg = Rat;
            break;
        default:
            throw new Error('Unexpected serviceType in ServiceTypeIcon');
    }

    return (
        <Box height={height} width={width}>
            <img
                src={svg}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            />
        </Box>
    );
};

export default ServiceTypeIcon;

import { BinColorEnum } from '../../../model/cleaningService/binColor/binColorSchema';

export enum BinImageSize {
    small = 'small',
    big = 'big',
}

export interface BinImageProps {
    readonly color: BinColorEnum;
    readonly size: BinImageSize;
}

import i18next from 'i18next';

export enum InsectControlTypeEnum {
    ants = 'ants',
    cockroaches = 'cockroaches',
    flies = 'flies',
    other = 'other',
}

export const translateInsectControlTypeEnum = (insectControlTypeEnum: InsectControlTypeEnum): string => {
    switch (insectControlTypeEnum) {
        case InsectControlTypeEnum.ants:
            return i18next.t('enum.insectControlType.ants');
        case InsectControlTypeEnum.cockroaches:
            return i18next.t('enum.insectControlType.cockroaches');
        case InsectControlTypeEnum.flies:
            return i18next.t('enum.insectControlType.flies');
        case InsectControlTypeEnum.other:
            return i18next.t('enum.insectControlType.other');
        default:
            throw new Error('unexpected InsectControlTypeEnum in translateInsectControlTypeEnum');
    }
};

import { CheckSize } from '../../../../icon/check/checkProps';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getStepNumberByPage } from '../../../../../route/page';
import { Typography } from '@material-ui/core';
import { WizardNavigationStepNumberProps, WizardNavigationStepType } from './wizardNavigationStepNumberProps';
import Check from '../../../../icon/check';
import React from 'react';

const dimension = 24;

interface BoxProps {
    readonly type: WizardNavigationStepType;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        box: {
            alignItems: 'center',
            backgroundColor: (props: BoxProps) => (props.type === WizardNavigationStepType.future ? '#C0C1C1' : theme.palette.primary.main),
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'column',
            height: dimension,
            justifyContent: 'center',
            width: dimension,
        },
        number: {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    }),
);

const WizardNavigationStepNumber: React.FC<WizardNavigationStepNumberProps> = (props) => {
    const { page, type } = props;

    if (type === WizardNavigationStepType.done) {
        return <Check size={CheckSize.big} />;
    }

    const stylesProps: BoxProps = {
        type,
    };
    const classes = useStyles(stylesProps);

    return (
        <div className={classes.box}>
            <Typography className={classes.number}>{getStepNumberByPage(page)}</Typography>
        </div>
    );
};

export default WizardNavigationStepNumber;

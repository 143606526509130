import { Route } from 'route/route';

export const ratControlFrRoute: Route = {
    url: () => '/rat-control/fr',
    pattern: () => '/rat-control/fr',
};

export const ratControlLbRoute: Route = {
    url: () => '/rat-control/lu',
    pattern: () => '/rat-control/lu',
};

export const ratControlAddressRoute: Route = {
    url: () => '/rat-control',
    pattern: () => '/rat-control',
};

export const ratControlServiceRoute: Route = {
    url: () => '/rat-control/service',
    pattern: () => '/rat-control/service',
};

export const ratControlConfirmRoute: Route = {
    url: () => '/rat-control/confirmation',
    pattern: () => '/rat-control/confirmation',
};

export const ratControlPaymentRoute: Route = {
    url: () => '/rat-control/payment',
    pattern: () => '/rat-control/payment',
};

export const ratControlSuccessRoute: Route = {
    url: () => '/rat-control/success',
    pattern: () => '/rat-control/success',
};

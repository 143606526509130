import { getRouteByPageEnum, PageEnum } from '../../../route/page';
import { Link } from 'react-router-dom';
import { WizardNavigationStepLinkWrapProps } from './wizardNavigationStepLinkWrapProps';
import { WizardNavigationStepType } from './step/number/wizardNavigationStepNumberProps';
import React from 'react';
import WizardNavigationStep from './step';

const WizardNavigationStepLinkWrap: React.FC<WizardNavigationStepLinkWrapProps> = (props) => {
    const { page, size, type } = props;

    if (type === WizardNavigationStepType.done && page !== PageEnum.congratulations) {
        return (
            <Link
                to={getRouteByPageEnum(page)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <WizardNavigationStep page={page} size={size} type={type} />
            </Link>
        );
    }

    return <WizardNavigationStep page={page} size={size} type={type} />;
};

export default WizardNavigationStepLinkWrap;

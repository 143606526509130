import { Box, Divider } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { PageWrapperProps } from 'component/pageWrapper/pageWrapperProps';
import BottomNavigation from 'component/bottomNavigation';
import ContentBox from 'component/contentBox';
import FooterWrap from 'component/footerWrap';
import GoogleTagManager from 'component/system/googleTagManager';
import HeaderWrap from 'component/headerWrap';
import OrderTitle from 'component/orderTitle';
import React from 'react';
import TitleTypeService from 'component/titleTypeService';
import WizardNavigation from 'component/wizardNavigation';

const PageWrapper: React.FC<PageWrapperProps> = (props) => {
    const { children, price, firstButton, secondButton, page, wizardItems, pageTitle, title, subtitle, serviceType } = props;

    return (
        <>
            <Helmet>
                <title>Ady's Hygiene - {pageTitle ?? 'order service'}</title>
            </Helmet>
            <GoogleTagManager />
            <HeaderWrap />
            <WizardNavigation page={page} items={wizardItems} />

            <ContentBox>
                <Box paddingY={2}>
                    <TitleTypeService serviceType={serviceType} />
                </Box>
                <Divider />
                {title && subtitle && <OrderTitle title={title} subtitle={subtitle} />}
                {children}
            </ContentBox>

            <BottomNavigation price={price} firstButton={firstButton} secondButton={secondButton} />

            <FooterWrap />
        </>
    );
};

export default PageWrapper;

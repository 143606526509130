import { ConfirmationData } from '../../../state';
import { confirmationDataWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetConfirmationDataWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setConfirmationDataWasSet = useCallback(
        async (confirmationData: ConfirmationData) => {
            await dispatch(confirmationDataWasSet(confirmationData));
        },
        [dispatch],
    );

    return setConfirmationDataWasSet;
};

export default useSetConfirmationDataWasSetAction;

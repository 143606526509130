import { billingClientWasSet } from '../../../action';
import { ClientData } from '../../../state';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetBillingClientWasSetAction = () => {
    const dispatch = useDispatchTyped();

    return useCallback(
        async (billingClient: ClientData | null) => {
            await dispatch(billingClientWasSet(billingClient));
        },
        [dispatch],
    );
};

export default useSetBillingClientWasSetAction;

import i18next from 'i18next';

export enum ServiceType {
    binsCleaning = 'binsCleaning',
    insectControl = 'insectControl',
    ratControl = 'ratControl',
}

export const translateServiceType = (serviceType: ServiceType): string => {
    switch (serviceType) {
        case ServiceType.ratControl:
            return i18next.t('serviceType.ratControl');
        case ServiceType.binsCleaning:
            return i18next.t('serviceType.binsCleaning');
        case ServiceType.insectControl:
            return i18next.t('serviceType.insectControl');
        default:
            throw new Error('unexpected ServiceType in translateServiceType');
    }
};

import { AutocompleteSelectItem, AutocompleteSelectProps, AutocompleteSelectSize } from './autocompleteSelectProps';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterOptionsState } from '@material-ui/lab';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { InputAdornment, TextField as TextFieldMui, useMediaQuery, useTheme } from '@material-ui/core';
import { newAddedStreetId } from 'config/newAddedStreet';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AutocompleteSelectAddLabel from './addLabel';
import React, { useCallback, useState } from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import styled from 'styled-components';

interface BoxWrapProps {
    readonly isSmallLayout: boolean;
    readonly size: AutocompleteSelectSize;
}

const BoxWrap = styled.div`
    height: 76px;
    width: ${(props: BoxWrapProps) => {
        if (props.isSmallLayout) {
            return 'auto';
        }
        switch (props.size) {
            case AutocompleteSelectSize.big:
                return 456;
            case AutocompleteSelectSize.small:
                return 236;
            default:
                throw new Error('unexpected TextInputSize in BoxWrap');
        }
    }}px;
`;

const AddOptionBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const filter = createFilterOptions<AutocompleteSelectItem>({
    ignoreAccents: true,
    matchFrom: 'start',
});

const AutocompleteSelect: React.FC<AutocompleteSelectProps> = (props) => {
    const { disabled, items, label, error, size, name, addLabelText, addNew, filterMinLength } = props;
    const [inputValue, setInputValue] = useState<string>('');

    const { t } = useTranslation();

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, watch, setValue } = useFormContext();
    const value = watch(name);

    const handleSelectByText = useCallback(() => {
        const foundItem = items.find((item) => {
            return item.name.toLowerCase().trim() === inputValue?.toLowerCase().trim();
        });

        if (foundItem === undefined) {
            setInputValue(value ? value.name : '');
            return;
        }
        setValue(name, foundItem);
        setInputValue(foundItem.name);
    }, [name, value, inputValue, items, setValue]);

    return (
        <BoxWrap size={size} isSmallLayout={isSmallLayout}>
            <Controller
                control={control}
                name={name}
                error={error !== undefined}
                render={({ onChange, value, name }) => (
                    <Autocomplete
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() === 'enter'.toLowerCase()) {
                                handleSelectByText();
                                e.preventDefault();
                            }
                        }}
                        onBlur={() => {
                            handleSelectByText();
                        }}
                        renderOption={(option) => {
                            if (addNew === true && option.id === newAddedStreetId && addLabelText !== undefined) {
                                return (
                                    <AddOptionBox>
                                        <div>{flc(t('order.screen.address.form.notFound'))}</div>
                                        <AutocompleteSelectAddLabel text={addLabelText} />
                                    </AddOptionBox>
                                );
                            }
                            return option.name;
                        }}
                        value={value}
                        onChange={(e, newValue) => onChange(newValue)}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextFieldMui
                                {...params}
                                label={label}
                                name={name}
                                error={error !== undefined}
                                helperText={error}
                                variant={'outlined'}
                                size={'medium'}
                                fullWidth={true}
                                InputProps={(() => {
                                    return {
                                        ...params.InputProps,
                                        endAdornment:
                                            error === undefined ? (
                                                params.InputProps.endAdornment
                                            ) : (
                                                <>
                                                    {params.InputProps.endAdornment}
                                                    <InputAdornment position={'end'}>
                                                        <ReportProblemIcon color={'secondary'} />
                                                    </InputAdornment>
                                                </>
                                            ),
                                    };
                                })()}
                            />
                        )}
                        options={items}
                        filterOptions={(options: AutocompleteSelectItem[], state: FilterOptionsState<AutocompleteSelectItem>) => {
                            let filtered;
                            if (filterMinLength !== undefined && state.inputValue.length < filterMinLength) {
                                filtered = [];
                            } else {
                                filtered = filter(options, state);
                            }

                            if (
                                addNew === true &&
                                state.inputValue !== '' &&
                                options.every((option) => {
                                    return option.name !== state.inputValue;
                                })
                            ) {
                                filtered.push({
                                    name: state.inputValue,
                                    id: newAddedStreetId,
                                });
                            }

                            return filtered;
                        }}
                        getOptionLabel={(option: AutocompleteSelectItem) => option.name}
                        disabled={disabled}
                    />
                )}
            />
        </BoxWrap>
    );
};

export default AutocompleteSelect;

import { ButtonVariant } from 'component/button/buttonProps';
import { CircularProgress } from '@material-ui/core';
import { flc } from 'utils/strings/firstLetterCapitalize';
import { getInsectControlServicesUrl } from 'api/url/apiBackEndUrl';
import { getNavigationNameForPageEnum, getSubtitleForPageEnum, getTitleForPageEnum, PageEnum } from 'route/page';
import { insectControlAddressRoute, insectControlConfirmRoute } from 'route/routeInsectControl';
import { InsectControlService } from 'model/insectControl/insectControlServiceSchema';
import { parseInsectControlServiceList } from 'model/insectControl/parser/parseInsectControlService';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import axios from 'system/axios/axios';
import InsectControlForm, { InsectControlFormValues } from 'component/form/insectControlForm';
import PageWrapper from 'component/pageWrapper';
import React from 'react';
import ServerError from 'component/error/serverError';
import useInsectControlData from 'store/order/hook/insectControlService/useInsectControlData/useInsectControlData';
import usePostalCode from 'store/order/hook/address/usePostalCode/usePostalCode';
import useSetInsectControlDataWasSetAction from 'store/order/hook/insectControlService/useSetInsectControlDataWasSetAction/useSetInsectControlDataWasSetAction';

const InsectControlServicePage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const postalCode = usePostalCode();
    const insectControlData = useInsectControlData();
    const setInsectControlDataWasSet = useSetInsectControlDataWasSetAction();

    if (postalCode === undefined) {
        history.push(insectControlAddressRoute.url());
        return null;
    }

    const { data: insectControlService, isError } = useQuery<InsectControlService>(
        ['insectControlServices', postalCode?.id],
        async () => {
            const postalCodeId = postalCode?.id;
            if (postalCodeId === undefined) {
                throw new Error('Unexpected: postalCodeId is undefined');
            }
            const response = await axios.get(getInsectControlServicesUrl(postalCodeId));
            // console.log('response', response);
            const responseData = response.data;
            const insectControlServices = parseInsectControlServiceList(responseData.data);

            return insectControlServices[0];
        },
        {
            enabled: postalCode !== undefined,
        },
    );

    if (isError) {
        return <ServerError />;
    }

    const onSubmit = async (inputs: InsectControlFormValues) => {
        if (insectControlService === undefined) {
            throw new Error('insectControlData is undefined');
        }
        await setInsectControlDataWasSet({
            tariffConfigId: insectControlService.tariffConfigId,
            price: insectControlService.price,
            images: inputs.images,
            problem: inputs.problem,
            description: inputs.description,
        });
        history.push(insectControlConfirmRoute.url());
    };

    let content: React.ReactNode = null;
    if (insectControlService === undefined) {
        content = <CircularProgress />;
    } else {
        content = <InsectControlForm id="insectControl" onSubmit={onSubmit} defaultValues={insectControlData} />;
    }

    return (
        <PageWrapper
            page={PageEnum.insectControlService}
            pageTitle={getNavigationNameForPageEnum(PageEnum.insectControlService)}
            serviceType={ServiceType.insectControl}
            wizardItems={[PageEnum.insectControlAddress, PageEnum.insectControlService, PageEnum.insectControlConfirm, PageEnum.insectControlPayment]}
            title={flc(getTitleForPageEnum(PageEnum.insectControlService))}
            subtitle={getSubtitleForPageEnum(PageEnum.insectControlService)}
            price={{
                label: flc(t('order.price')),
                amount: insectControlService?.price ?? insectControlData?.price ?? null,
            }}
            secondButton={{
                type: 'submit',
                form: 'insectControl',
                arrowContinue: true,
                text: t('order.navigation.continue'),
                variant: ButtonVariant.contained,
            }}
            firstButton={{
                onClick: () => {
                    history.push(insectControlAddressRoute.url());
                },
                text: t('order.navigation.back'),
                variant: ButtonVariant.outlined,
            }}
        >
            {content}
        </PageWrapper>
    );
};

export default InsectControlServicePage;

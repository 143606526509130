import * as z from 'zod';
import { CleaningFrequencyCodeEnum } from './cleaningFrequencyCodeEnum';

export const CleaningFrequencySchema = z.object({
    tariffConfigId: z.number().int(),
    code: z.nativeEnum(CleaningFrequencyCodeEnum),
    description: z.string(),
    quantity: z.number().int(),
    price: z.number().nullable(),
    isRenewedAfter12months: z.boolean(),
});

export type CleaningFrequency = z.infer<typeof CleaningFrequencySchema>;

import { binCleaningAddressRoute } from 'route/routeBinCleaning';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonVariant } from '../../button/buttonProps';
import { getNavigationNameForPageEnum, PageEnum } from '../../../route/page';
import { getUrlAdysHomepage } from '../../../config/url';
import { insectControlAddressRoute } from 'route/routeInsectControl';
import { ratControlAddressRoute } from 'route/routeRatControl';
import { ServiceType } from 'common/serviceType';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import adysMan from '../../../assets/adysman/adysman.png';
import OrderTitle from '../../orderTitle';
import OtherServices from 'component/otherServices';
import PageWrapper from 'component/pageWrapper';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useCleaningServiceData from '../../../store/order/hook/cleaningService/useCleaningServiceData/useCleaningServiceData';
import useOrderWasCreated from 'store/order/hook/order/useOrderWasCreated/useOrderWasCreated';
import usePaymentData from '../../../store/order/hook/payment/usePaymentData/usePaymentData';
import usePersonalInformationData from '../../../store/order/hook/personalInformation/usePersonalInformationData/usePersonalInformationData';
import usePostalCodeIsNotInSystem from '../../../store/order/hook/postalCodeIsNotInSystem/usePostalCodeIsNotInSystem/usePostalCodeIsNotInSystem';
import useServiceType from 'store/order/hook/order/useServiceType/useServiceType';

const ContentBoxTop = styled.div``;

interface ContentBoxBottomProps {
    readonly isSmallLayout: boolean;
}

const ContentBoxBottom = styled.div`
    align-self: flex-end;
    margin-top: ${(props: ContentBoxBottomProps) => {
        if (props.isSmallLayout) {
            return '50px';
        }
        return '200px';
    }};
    flex: 1;
    display: flex;
    justify-content: center;
`;

const ContentBoxIn = styled.div`
    height: 100%; ;
`;

const CleaningSuccessPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const orderWasCreated = useOrderWasCreated();
    const serviceType = useServiceType();
    const paymentData = usePaymentData();
    const personalInformationData = usePersonalInformationData();
    const cleaningServiceData = useCleaningServiceData();
    const postalCodeIsNotInSystem = usePostalCodeIsNotInSystem();

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    const isOrder = useMemo<boolean>(() => {
        return cleaningServiceData !== undefined && cleaningServiceData.price > 0;
    }, [cleaningServiceData]);

    const isInterest = useMemo<boolean>(() => {
        if (cleaningServiceData === undefined) {
            return false;
        }
        if (cleaningServiceData.price <= 0) {
            return true;
        }
        const someBinColorNull = cleaningServiceData.tariffItems.some((item) => {
            return item.binColor === null;
        });
        return someBinColorNull;
    }, [cleaningServiceData]);

    const title1 = useMemo<string>(() => {
        if (isOrder) {
            return t('order.screen.success.paidTitle');
        }
        if (!isOrder && isInterest) {
            return t('order.screen.success.interestTitle');
        }
        if (postalCodeIsNotInSystem) {
            return t('order.screen.success.interestTitle');
        }
        return '';
    }, [isInterest, isOrder, postalCodeIsNotInSystem, t]);

    const title2 = useMemo<string | null>(() => {
        if (isOrder && isInterest) {
            return t('order.screen.success.interestTitleIfPaid');
        }
        return null;
    }, [isInterest, isOrder, t]);

    const text1a = useMemo<string>(() => {
        if (isOrder && paymentData?.isPaid === true) {
            return t('order.screen.success.paidText1', {
                email: personalInformationData === undefined || personalInformationData === null ? '' : personalInformationData.email,
            });
        }
        if (isOrder && paymentData?.isPaid === false) {
            return t('order.screen.success.noPaidText1', {
                email: personalInformationData === undefined || personalInformationData === null ? '' : personalInformationData.email,
            });
        }
        if (!isOrder && isInterest) {
            return t('order.screen.success.interestText1');
        }
        if (postalCodeIsNotInSystem) {
            return t('order.screen.success.interestText1PostalConIsNotInSystem');
        }
        return '';
    }, [isInterest, isOrder, paymentData?.isPaid, personalInformationData, postalCodeIsNotInSystem, t]);

    const text1b = useMemo<string | null>(() => {
        if (isOrder && paymentData?.isPaid === true) {
            return t('order.screen.success.paidText2');
        }
        if (isOrder && paymentData?.isPaid === false) {
            return null;
        }
        if (!isOrder && isInterest) {
            return null;
        }
        return null;
    }, [isInterest, isOrder, paymentData?.isPaid, t]);

    const text2 = useMemo<string | null>(() => {
        if (isOrder && isInterest) {
            return t('order.screen.success.interestText1');
        }
        return null;
    }, [isInterest, isOrder, t]);

    if (!orderWasCreated && !isInterest) {
        switch (serviceType) {
            case ServiceType.binsCleaning:
                history.push(binCleaningAddressRoute.url());
                break;
            case ServiceType.ratControl:
                history.push(ratControlAddressRoute.url());
                break;
            case ServiceType.insectControl:
                history.push(insectControlAddressRoute.url());
                break;
            default:
                throw new Error(`unexpected service type ${serviceType}`);
        }
        return null;
    }

    return (
        <PageWrapper
            page={PageEnum.congratulations}
            pageTitle={getNavigationNameForPageEnum(PageEnum.congratulations)}
            serviceType={ServiceType.binsCleaning}
            price={undefined}
            secondButton={{
                arrowContinue: false,
                onClick: () => {
                    window.location.href = getUrlAdysHomepage();
                },
                text: t('order.navigation.close'),
                variant: ButtonVariant.outlined,
            }}
        >
            <ContentBoxIn>
                <ContentBoxTop>
                    <OrderTitle title={title1} titleCenter={true} />

                    <Box width={isSmallLayout ? 'auto' : '500px'} margin={'0 auto'}>
                        <Box marginBottom={'16px'}>
                            <Typography
                                align={'center'}
                                style={{
                                    color: 'rgba(0,0,0,0.64)',
                                    fontSize: '18px',
                                }}
                            >
                                {text1a}
                            </Typography>
                        </Box>
                        {text1b !== null && (
                            <Typography
                                align={'center'}
                                style={{
                                    color: 'rgba(0,0,0,0.64)',
                                    fontSize: '18px',
                                }}
                            >
                                {text1b}
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        {title2 !== null && <OrderTitle title={title2} titleCenter={true} />}
                        {text2 !== null && (
                            <Box>
                                <Typography
                                    align={'center'}
                                    style={{
                                        color: 'rgba(0,0,0,0.64)',
                                        fontSize: '18px',
                                    }}
                                >
                                    {text2}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Typography
                        variant={'h2'}
                        style={{
                            paddingTop: 20,
                            paddingBottom: 40,
                            ...(isSmallLayout ? { fontSize: 32 } : {}),
                        }}
                        align="center"
                    >
                        {t('order.screen.success.anyOtherProblem')}
                    </Typography>
                    <OtherServices currentService={ServiceType.binsCleaning} />
                </ContentBoxTop>
                <ContentBoxBottom isSmallLayout={isSmallLayout}>
                    <img src={adysMan} alt={"Ady'sman"} />
                </ContentBoxBottom>
            </ContentBoxIn>
        </PageWrapper>
    );
};

export default CleaningSuccessPage;

import { AppState } from '../../../../appState';
import { ConfirmationData } from '../../../state';
import { useSelector } from 'react-redux';

const useConfirmationData = (): ConfirmationData | undefined => {
    const confirmationData = useSelector<AppState, ConfirmationData | undefined>((state) => {
        return state.order.confirmationData;
    });

    return confirmationData;
};

export default useConfirmationData;

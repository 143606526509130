import './index.css';
import './localization/i18next';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { getStore } from './store/configureStore';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import App from './App';
import globalTheme from './theme/globalTheme';
import history from './system/history/history';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: "https://b6c6afc1bfb142ba89968c7c9cf4f1c6@sentry.trigama.cz/18",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new CaptureConsoleIntegration({
            levels: ['error'],
        })
    ],

    beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'xhr' && hint !== undefined && (hint.xhr as XMLHttpRequest).response !== "") {
            if (breadcrumb.data === undefined) {
                breadcrumb.data = {response: (hint.xhr as XMLHttpRequest).response};
            } else {
                breadcrumb.data = {...breadcrumb.data, response: (hint.xhr as XMLHttpRequest).response};
            }
        }

        // Filter requests to braintree analytics
        if (breadcrumb.category === 'xhr' && breadcrumb.data !== undefined && breadcrumb.data.url.includes('origin-analytics-sand.sandbox.braintree-api.com')) {
            return null;
        }

        // Filter known console warnings/errors
        if (breadcrumb.category === 'console') {
            if (breadcrumb.message !== undefined && breadcrumb.message.startsWith('Material-UI: You have provided an out-of-range value')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.startsWith('Warning: Using UNSAFE_componentWillMount in strict mode')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('cleaningFrequencyorganic is missing')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('cleaningFrequencyhousehold is missing')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('cleaningFrequencyglass is missing')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('is deprecated in StrictMode')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('Warning: A component is changing an uncontrolled input to be controlled')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('Material-UI: A component is changing the uncontrolled value state')) {
                return null;
            }
            if (breadcrumb.message !== undefined && breadcrumb.message.includes('Each child in a list should have a unique "key" prop')) {
                return null;
            }
        }
        return breadcrumb;
    },

    normalizeDepth: 10,
    tracesSampleRate: 1.0,
});

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={getStore()}>
                <ReactQueryDevtools initialIsOpen={true} />
                <Router history={history}>
                    <MuiThemeProvider theme={globalTheme}>
                        <CssBaseline />
                        <App />
                    </MuiThemeProvider>
                </Router>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles(() => ({
    title: {
        '&::first-letter': {
            textTransform: 'uppercase',
        },
    },
}));

const ServerError: React.FC<{}> = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Typography variant={'h3'} className={classes.title}>
                {t('error.serverError.title')}
            </Typography>
            <Typography variant={'body1'}>{t('error.serverError.message')}</Typography>
        </>
    );
};

export default ServerError;

import { AppState } from '../../../../appState';
import { CleaningServiceData } from '../../../state';
import { useSelector } from 'react-redux';

const useCleaningServiceData = (): CleaningServiceData | undefined => {
    const cleaningServiceData = useSelector<AppState, CleaningServiceData | undefined>((state) => {
        return state.order.cleaningServiceData;
    });

    return cleaningServiceData;
};

export default useCleaningServiceData;

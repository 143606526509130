import { ConfirmDataInfoBoxProps } from './confirmDataInfoBoxProps';
import ConfirmDataTitle from '../title';
import ConfirmDataValue from '../value';
import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div``;
// height: 24px;

const ConfirmDataInfoBox: React.FC<ConfirmDataInfoBoxProps> = (props) => {
    const { value, title } = props;

    return (
        <BoxWrap>
            <ConfirmDataTitle title={title} />
            <ConfirmDataValue value={value} />
        </BoxWrap>
    );
};

export default ConfirmDataInfoBox;

import { AppLanguageEnum, getAbbreviationsByAppLanguageEnum } from 'localization/appLanguage';
import { insectControlAddressRoute } from 'route/routeInsectControl';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import React, { useEffect } from 'react';

const InsectControlFr: React.FC<{}> = () => {
    const history = useHistory();

    useEffect(() => {
        i18next.changeLanguage(getAbbreviationsByAppLanguageEnum(AppLanguageEnum.fr)).then(() => {
            history.push(insectControlAddressRoute.url());
        });
    }, [history]);

    return null;
};

export default InsectControlFr;

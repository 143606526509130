import { Route } from 'route/route';

export const insectControlFrRoute: Route = {
    url: () => '/insect-control/fr',
    pattern: () => '/insect-control/fr',
};

export const insectControlLbRoute: Route = {
    url: () => '/insect-control/lu',
    pattern: () => '/insect-control/lu',
};

export const insectControlAddressRoute: Route = {
    url: () => '/insect-control',
    pattern: () => '/insect-control',
};

export const insectControlServiceRoute: Route = {
    url: () => '/insect-control/service',
    pattern: () => '/insect-control/service',
};

export const insectControlConfirmRoute: Route = {
    url: () => '/insect-control/confirmation',
    pattern: () => '/insect-control/confirmation',
};

export const insectControlPaymentRoute: Route = {
    url: () => '/insect-control/payment',
    pattern: () => '/insect-control/payment',
};

export const insectControlSuccessRoute: Route = {
    url: () => '/insect-control/success',
    pattern: () => '/insect-control/success',
};

import * as z from 'zod';
import { BinColorEnum } from '../../model/cleaningService/binColor/binColorSchema';
import { BinTypeEnum } from '../../model/cleaningService/binType/binTypeEnum';
import { City } from '../../model/city/citySchema';
import { CleaningFrequencyCodeEnum } from '../../model/cleaningService/cleaningFrequency/cleaningFrequencyCodeEnum';
import { InsectControlTypeEnum } from 'model/insectControl/insectControlType/insectControlTypeEnum';
import { PostalCode } from '../../model/postalCode/postalCodeSchema';
import { RatControlTypeEnum } from 'model/ratControl/ratControlType/ratControlTypeEnum';
import { ServiceType } from "common/serviceType";
import { Street } from '../../model/street/streetSchema';

export const personalInformationSchema = z.object({
    email: z.string().email(),
    firstName: z.string().nonempty(),
    lastName: z.string().nonempty(),
    phone: z
        .string()
        .nonempty()
        .regex(/^[\d+\/ ]+$/i), // phone number
});

export type PersonalInformationData = z.infer<typeof personalInformationSchema>;

export const confirmationSchema = z.object({
    agreeTerms: z.literal(true),
    message: z.string().nullable(),
});

export type ConfirmationData = z.infer<typeof confirmationSchema>;

export interface SelectedMonths {
    readonly january: boolean;
    readonly february: boolean;
    readonly march: boolean;
    readonly april: boolean;
    readonly may: boolean;
    readonly june: boolean;
    readonly july: boolean;
    readonly august: boolean;
    readonly september: boolean;
    readonly october: boolean;
    readonly november: boolean;
    readonly december: boolean;
}

export interface TariffItem {
    readonly binColor: BinColorEnum | null;
    readonly binType: BinTypeEnum;
    readonly cleaningFrequency: CleaningFrequencyCodeEnum;
    readonly count: number;
    readonly selectedMonths: SelectedMonths | null;
    readonly tariffConfigId: number;
}

export interface CleaningServiceData {
    readonly price: number;
    readonly tariffItems: TariffItem[];
}

export interface DefaultsData {
    readonly postalCode: PostalCode;
    readonly tariffItems: TariffItem[];
}

export interface RatControlData {
    readonly description?: string;
    readonly images: File[];
    readonly price: number;
    readonly problem: RatControlTypeEnum;
    readonly tariffConfigId: number;
}

export interface InsectControlData {
    readonly description?: string;
    readonly images: File[];
    readonly price: number;
    readonly problem: InsectControlTypeEnum;
    readonly tariffConfigId: number;
}

export interface Person {
    readonly firstName: string;
    readonly lastName: string;
}

export interface Company {
    readonly name: string;
    readonly vatNumber: string;
}

export interface Address {
    readonly city: City;
    readonly postalCode: PostalCode;
    readonly street: Street;
    readonly streetNo: string;
}

export interface ClientData {
    readonly person?: Person;
    readonly company?: Company;
    readonly email: string;
    readonly phone: string;
    readonly address: Address;
}

export interface PaymentData {
    readonly isPaid: boolean;
}

export interface OrderReducerState {
    readonly serviceType: ServiceType | undefined;
    readonly deliveryClientData: ClientData | undefined;
    readonly billingClientData: ClientData | undefined | null; // null: same as deliveryClientData
    readonly cleaningServiceData: CleaningServiceData | undefined;
    readonly ratControlData: RatControlData | undefined;
    readonly insectControlData: InsectControlData | undefined;
    readonly confirmationData: ConfirmationData | undefined;
    readonly paymentData: PaymentData | undefined;
    readonly personalInformationData: PersonalInformationData | undefined | null;
    readonly postalCodeIsNotInSystem: boolean;
    readonly orderWasCreated: boolean;
}

import i18next from 'i18next';

export enum CleaningFrequencyCodeEnum {
    cleaningsPerYear1 = '1_cleaning_per_year',
    cleaningsPerYear2 = '2_cleanings_per_year',
    cleaningsPerYear3 = '3_cleanings_per_year',
    cleaningsPerYear4 = '4_cleanings_per_year',
    cleaningsPerYear6 = '6_cleanings_per_year',
    cleaningsPerYear12 = '12_cleanings_per_year',
}

export const translateCleaningFrequencyCode = (cleaningFrequencyCodeEnum: CleaningFrequencyCodeEnum): string => {
    switch (cleaningFrequencyCodeEnum) {
        case CleaningFrequencyCodeEnum.cleaningsPerYear1:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear1');
        case CleaningFrequencyCodeEnum.cleaningsPerYear2:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear2');
        case CleaningFrequencyCodeEnum.cleaningsPerYear3:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear3');
        case CleaningFrequencyCodeEnum.cleaningsPerYear4:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear4');
        case CleaningFrequencyCodeEnum.cleaningsPerYear6:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear6');
        case CleaningFrequencyCodeEnum.cleaningsPerYear12:
            return i18next.t('enum.cleaningFrequencyCode.cleaningsPerYear12');
        default:
            throw new Error('unexpected cleaningFrequencyCodeEnum in translateCleaningFrequencyCode.');
    }
};

export const getCountPerYearByFrequencyCode = (cleaningFrequencyCodeEnum: CleaningFrequencyCodeEnum): number => {
    switch (cleaningFrequencyCodeEnum) {
        case CleaningFrequencyCodeEnum.cleaningsPerYear1:
            return 1;
        case CleaningFrequencyCodeEnum.cleaningsPerYear2:
            return 2;
        case CleaningFrequencyCodeEnum.cleaningsPerYear3:
            return 3;
        case CleaningFrequencyCodeEnum.cleaningsPerYear4:
            return 4;
        case CleaningFrequencyCodeEnum.cleaningsPerYear6:
            return 6;
        case CleaningFrequencyCodeEnum.cleaningsPerYear12:
            return 12;
        default:
            throw new Error('unexpected cleaningFrequencyCodeEnum in getCountPerYearByFrequencyCode.');
    }
};

import * as Sentry from "@sentry/react";
import { AppAction } from './appAction';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { AppState } from './appState';
import { createEpicMiddleware } from 'redux-observable';
import { EpicsDependencies } from './epicsDependencies';
import { rootEpic } from './epic';
import logger from 'redux-logger';
import reducers from './rootReducer';

// @ts-ignore
const composeEnhancers = process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// call only from index.js|App.tsx !
export const getStore = () => {
    const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState, EpicsDependencies>({
        dependencies: {},
    });

    const middlewares = [logger, epicMiddleware];

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

    const store: Store<AppState, AppAction> = createStore<AppState, AppAction, undefined, undefined>(reducers, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer));

    epicMiddleware.run(rootEpic);

    return store;
};

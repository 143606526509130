import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { FormHelperText, useTheme } from '@material-ui/core';
import { MonthsCheckboxesProps } from './monthsCheckboxesProps';
import { MonthsCheckboxesSize } from './monthsCheckboxesSize';
import { useTranslation } from 'react-i18next';
import MonthCheckbox from './month';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div``;

interface CheckBoxesProps {
    readonly size: MonthsCheckboxesSize;
}

const CheckBoxes = styled.div`
    display: flex;
    flex-direction: ${(props: CheckBoxesProps) => {
        switch (props.size) {
            case MonthsCheckboxesSize.big:
                return 'row';
            case MonthsCheckboxesSize.small:
                return 'column';
            default:
                return 'row';
        }
    }};
    justify-content: space-between;
`;

const SixMonthsWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MonthsCheckboxes: React.FC<MonthsCheckboxesProps> = (props) => {
    const { size, selectedMonths, selectedMonthsHandlers, disabled, error, warning } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const Divide = useMemo<React.FC>(() => {
        switch (size) {
            case MonthsCheckboxesSize.big:
                return React.Fragment;
            case MonthsCheckboxesSize.small:
                return SixMonthsWrap;
            default:
                return React.Fragment;
        }
    }, [size]);

    return (
        <BoxWrap>
            <CheckBoxes size={size}>
                <Divide>
                    <MonthCheckbox
                        checked={selectedMonths.january}
                        text={flc(t('order.month.abbreviation.january'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onJanuaryChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.february}
                        text={flc(t('order.month.abbreviation.february'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onFebruaryChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.march}
                        text={flc(t('order.month.abbreviation.march'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onMarchChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.april}
                        text={flc(t('order.month.abbreviation.april'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onAprilChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.may}
                        text={flc(t('order.month.abbreviation.may'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onMayChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.june}
                        text={flc(t('order.month.abbreviation.june'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onJuneChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                </Divide>
                <Divide>
                    <MonthCheckbox
                        checked={selectedMonths.july}
                        text={flc(t('order.month.abbreviation.july'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onJulyChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.august}
                        text={flc(t('order.month.abbreviation.august'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onAugustChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.september}
                        text={flc(t('order.month.abbreviation.september'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onSeptemberChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.october}
                        text={flc(t('order.month.abbreviation.october'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onOctoberChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.november}
                        text={flc(t('order.month.abbreviation.november'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onNovemberChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                    <MonthCheckbox
                        checked={selectedMonths.december}
                        text={flc(t('order.month.abbreviation.december'))}
                        onChange={(newValue) => {
                            selectedMonthsHandlers.onDecemberChanged(newValue);
                        }}
                        disabled={disabled}
                    />
                </Divide>
            </CheckBoxes>
            {warning !== undefined && (
                <FormHelperText
                    style={{
                        color: theme.palette.secondary.main,
                    }}
                >
                    {warning}
                </FormHelperText>
            )}
            {error !== undefined && (
                <FormHelperText
                    style={{
                        color: theme.palette.secondary.main,
                    }}
                >
                    {error}
                </FormHelperText>
            )}
        </BoxWrap>
    );
};

export default MonthsCheckboxes;

import { CleaningServiceData } from '../../../state';
import { cleaningServiceDataWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetCleaningServiceDataWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setCleaningServiceDataWasSet = useCallback(
        async (cleaningServiceData: CleaningServiceData) => {
            await dispatch(cleaningServiceDataWasSet(cleaningServiceData));
        },
        [dispatch],
    );

    return setCleaningServiceDataWasSet;
};

export default useSetCleaningServiceDataWasSetAction;

import { PageEnum } from '../../../../route/page';
import { WizardNavigationStepType } from './number/wizardNavigationStepNumberProps';

export enum WizardNavigationStepSize {
    big = 'big',
    small = 'small',
}

export interface WizardNavigationStepProps {
    readonly page: PageEnum;
    readonly size: WizardNavigationStepSize;
    readonly type: WizardNavigationStepType;
}

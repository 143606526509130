import { flc } from '../../../../utils/strings/firstLetterCapitalize';
import { getNavigationNameForPageEnum, PageEnum } from '../../../../route/page';
import { Typography } from '@material-ui/core';
import { WizardNavigationStepProps, WizardNavigationStepSize } from './wizardNavigationStepProps';
import { WizardNavigationStepType } from './number/wizardNavigationStepNumberProps';
import React from 'react';
import WizardNavigationStepNumber from './number';

const WizardNavigationStep: React.FC<WizardNavigationStepProps> = (props) => {
    const { size, type, page } = props;

    if (size === WizardNavigationStepSize.small) {
        return (
            <div
                style={{
                    marginLeft: 4,
                    marginRight: 4,
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <WizardNavigationStepNumber type={type} page={page} />
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FDFEFE',
                paddingLeft: page === PageEnum.cleaningAddress ? 0 : 12,
                paddingRight: page === PageEnum.cleaningPayment ? 0 : 12,
            }}
        >
            <WizardNavigationStepNumber type={type} page={page} />
            <div
                style={{
                    marginLeft: 8,
                }}
            >
                <Typography
                    style={{
                        fontWeight: type === WizardNavigationStepType.current || page === PageEnum.congratulations ? 'bold' : 'normal',
                        color: type === WizardNavigationStepType.future ? '#000000aa' : '#000000',
                    }}
                >
                    {flc(getNavigationNameForPageEnum(page))}
                </Typography>
            </div>
        </div>
    );
};

export default WizardNavigationStep;

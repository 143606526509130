import { CheckProps, CheckSize } from './checkProps';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import React, { memo } from 'react';

const dimensionSmall = 20;
const dimensionBig = 24;

const Check: React.FC<CheckProps> = memo((props) => {
    const { size } = props;

    let dimension: number;
    switch (size) {
        case CheckSize.small:
            dimension = dimensionSmall;
            break;
        case CheckSize.big:
            dimension = dimensionBig;
            break;
        default:
            throw new Error('Unexpected CheckSize in Check');
    }

    return (
        <div
            style={{
                height: dimension,
                width: dimension,
                overflow: 'hidden',
            }}
        >
            <CheckCircleRoundedIcon
                style={{
                    color: '#36a6db',
                    height: '100%',
                    width: '100%',
                    transform: 'scale(1.2)',
                }}
            />
        </div>
    );
});

export default Check;

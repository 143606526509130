import { BinColorEnum } from '../../../model/cleaningService/binColor/binColorSchema';
import { BinImageProps, BinImageSize } from './binImageProps';
import BinBlack from '../../../assets/bin/binBlack.svg';
import BinBlue from '../../../assets/bin/binBlue.svg';
import BinBrown from '../../../assets/bin/binBrown.svg';
import BinGreen from '../../../assets/bin/binGreen.svg';
import BinYellow from '../../../assets/bin/binYellow.svg';
import React from 'react';
import styled from 'styled-components';

const dimensionSmall = 16; //px
const dimensionBig = 24; //px

interface BoxProps {
    readonly height: number;
    readonly width: number;
}

const Box = styled.div`
    height: ${(props: BoxProps) => props.height}px;
    width: ${(props: BoxProps) => props.width}px;
`;

const BinImage: React.FC<BinImageProps> = (props) => {
    const { size, color } = props;

    let height: number;
    let width: number;
    switch (size) {
        case BinImageSize.small:
            height = dimensionSmall;
            width = dimensionSmall;
            break;
        case BinImageSize.big:
            height = dimensionBig;
            width = dimensionBig;
            break;
        default:
            throw new Error('Unexpected BinImageSize in BinImage');
    }

    let svg: string;
    switch (color) {
        case BinColorEnum.black:
            svg = BinBlack;
            break;
        case BinColorEnum.blue:
            svg = BinBlue;
            break;
        case BinColorEnum.brown:
            svg = BinBrown;
            break;
        case BinColorEnum.green:
            svg = BinGreen;
            break;
        case BinColorEnum.yellow:
            svg = BinYellow;
            break;
        default:
            throw new Error('Unexpected BinColorEnum in BinImage');
    }

    return (
        <Box height={height} width={width}>
            <img
                src={svg}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            />
        </Box>
    );
};

export default BinImage;

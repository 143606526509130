import * as z from 'zod';

export const personalInformationSchema = z.object({
    firstName: z.string().nonempty().optional(),
    lastName: z.string().nonempty().optional(),
    company: z.string().nonempty().optional(),
    email: z.string().email(),
    phone: z
        .string()
        .nonempty()
        .regex(/^[\d+\/ ]+$/i), // phone number
});

export type PersonalInformationData = z.infer<typeof personalInformationSchema>;

export interface PaymentProps {
    readonly onError: (customerId: string) => void;
    readonly onSuccess: (customerId: string, nonce: string) => void;
    readonly personalInformation: PersonalInformationData;
    readonly price: number;
}

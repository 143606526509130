import { ButtonProps, ButtonVariant } from './buttonProps';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ButtonMui from '@material-ui/core/Button';
import React, { memo } from 'react';

const useStyles = makeStyles(() => ({
    button: {
        borderRadius: '20px',
        fontWeight: 'bold',
    },
}));

const Button: React.FC<ButtonProps> = memo((props) => {
    const { text, variant, arrowContinue, onClick: onClickFromProps, type, form, href } = props;

    const disabled = onClickFromProps === null;
    const onClick = onClickFromProps === null ? undefined : onClickFromProps;

    const classes = useStyles();

    return (
        <ButtonMui
            type={type}
            form={form}
            href={href}
            variant={variant}
            color={'secondary'}
            style={{
                ...(disabled === true
                    ? {
                          backgroundColor: variant === ButtonVariant.contained ? '#f0aec4' : '#ffffff',
                          color: variant === ButtonVariant.contained ? '#ffffff' : '#efadc3',
                          borderColor: '#efefef',
                      }
                    : {}),
            }}
            disabled={disabled}
            className={classes.button}
            endIcon={arrowContinue === true ? <ArrowForwardRoundedIcon /> : undefined}
            onClick={onClick}
        >
            {text}
        </ButtonMui>
    );
});

export default Button;

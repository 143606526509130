import { AppState } from '../../../../appState';
import { ClientData } from '../../../state';
import { useSelector } from 'react-redux';

const useDeliveryClient = (): ClientData | undefined => {
    return useSelector<AppState, ClientData | undefined>((state) => {
        return state.order.deliveryClientData;
    });
};

export default useDeliveryClient;

import { RatControlData } from '../../../state';
import { ratControlDataWasSet } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetRatControlDataWasSetAction = () => {
    const dispatch = useDispatchTyped();

    const setRatControlDataWasSet = useCallback(
        async (ratControlData: RatControlData) => {
            await dispatch(ratControlDataWasSet(ratControlData));
        },
        [dispatch],
    );

    return setRatControlDataWasSet;
};

export default useSetRatControlDataWasSetAction;

import { ServiceType } from 'common/serviceType';
import { serviceTypeWasSet } from 'store/order/action';
import { useCallback } from 'react';
import useDispatchTyped from 'store/hook/useDispatchTyped/useDispatchTyped';

const useSetServiceTypeWasSetAction = () => {
    const dispatch = useDispatchTyped();

    return useCallback(
        async (serviceType: ServiceType) => {
            await dispatch(serviceTypeWasSet(serviceType));
        },
        [dispatch],
    );
};

export default useSetServiceTypeWasSetAction;

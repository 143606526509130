import { SelectedMonths } from '../../store/order/state';

export const convertMonthsToString = (selectedMonths: SelectedMonths): string => {
    let output = '';
    output += selectedMonths.january ? '1' : '0';
    output += selectedMonths.february ? '1' : '0';
    output += selectedMonths.march ? '1' : '0';
    output += selectedMonths.april ? '1' : '0';
    output += selectedMonths.may ? '1' : '0';
    output += selectedMonths.june ? '1' : '0';
    output += selectedMonths.july ? '1' : '0';
    output += selectedMonths.august ? '1' : '0';
    output += selectedMonths.september ? '1' : '0';
    output += selectedMonths.october ? '1' : '0';
    output += selectedMonths.november ? '1' : '0';
    output += selectedMonths.december ? '1' : '0';

    return output;
};

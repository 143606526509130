import { postalCodeIsNotInSystemWasSet, postalCodeIsNotInSystemWasUnset } from '../../../action';
import { useCallback } from 'react';
import useDispatchTyped from '../../../../hook/useDispatchTyped/useDispatchTyped';

const useSetPostalCodeIsNotInSystemAction = () => {
    const dispatch = useDispatchTyped();

    const setPostalCodeIsNotInSystemWasSet = useCallback(async () => {
        await dispatch(postalCodeIsNotInSystemWasSet());
    }, [dispatch]);

    const setPostalCodeIsNotInSystemWasUnset = useCallback(async () => {
        await dispatch(postalCodeIsNotInSystemWasUnset());
    }, [dispatch]);

    return {
        setPostalCodeIsNotInSystemWasSet,
        setPostalCodeIsNotInSystemWasUnset,
    };
};

export default useSetPostalCodeIsNotInSystemAction;

import { flc } from '../../../utils/strings/firstLetterCapitalize';
import { InfoBoxBinsProps } from './infoBoxBinsProps';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDataTitle from '../title';
import InfoBoxBin from './bin';
import React, { memo } from 'react';
import styled from 'styled-components';

const BoxWrap = styled.div``;

interface ItemsWrapProps {
    readonly isSmallLayout: boolean;
    readonly itemsCount: number;
}

const ItemsWrap = styled.div`
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props: ItemsWrapProps) => {
        if (props.itemsCount <= 2) {
            return 'flex-start';
        }
        return 'space-between';
    }};
    flex-direction: ${(props: ItemsWrapProps) => {
        if (props.isSmallLayout) {
            return 'column';
        }
        return 'row';
    }};
`;

interface ItemWrapProps {
    readonly isSmallLayout: boolean;
}

const ItemWrap = styled.div`
    min-width: 200px;
    margin-bottom: ${(props: ItemWrapProps) => {
        if (props.isSmallLayout) {
            return 24;
        }
        return 0;
    }}px;
`;

const InfoBoxBins: React.FC<InfoBoxBinsProps> = memo((props) => {
    const { items } = props;

    const itemsCount = items.length;

    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BoxWrap>
            <ConfirmDataTitle title={flc(t('order.screen.confirmation.titles.bins'))} />
            <ItemsWrap isSmallLayout={isSmallLayout} itemsCount={itemsCount}>
                {items.map((item) => {
                    return (
                        <ItemWrap isSmallLayout={isSmallLayout}>
                            <InfoBoxBin values={item} />
                        </ItemWrap>
                    );
                })}
            </ItemsWrap>
        </BoxWrap>
    );
});

export default InfoBoxBins;

import * as Sentry from "@sentry/react";
import { AppLanguageEnum } from 'localization/appLanguage';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { cardPaymentMethodPayload, create, Dropin } from 'braintree-web-drop-in';
import { getPaymentCustomerUrl } from 'api/url/apiBackEndUrl';
import { getPriceWithVat } from 'utils/vat/vat';
import { parsePaymentCustomer } from 'model/paymentCustomer/parser/parsePaymentCustomer';
import { PaymentCustomer } from 'model/paymentCustomer/paymentCustomerSchema';
import { PaymentProps, personalInformationSchema } from 'component/payment/paymentProps';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import axios from 'system/axios/axios';
import ErrorMessage from 'component/errorMessage';
import i18next from 'i18next';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ServerError from 'component/error/serverError';
import useSetPaymentDataWasSetAction from 'store/order/hook/payment/useSetPaymentDataWasSetAction/useSetPaymentDataWasSetAction';

export interface PaymentRef {
    submit: () => Promise<void>;
}

const Payment = forwardRef<PaymentRef, PaymentProps>((props, ref) => {
    const { price, personalInformation, onSuccess, onError } = props;

    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumLayoutRaw = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMediumLayout = isSmallLayout === true ? false : isMediumLayoutRaw;

    const setPaymentData = useSetPaymentDataWasSetAction();
    const braintreeRef = useRef<HTMLDivElement>(null);
    const braintreeRef2 = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const dropIn = useRef<Dropin | null>(null);
    const random = useMemo(() => {
        return Math.random();
    }, []);

    const [paymentSucceed, setPaymentSucceed] = useState<boolean | undefined>(undefined);
    const [firstPaymentFailed, setFirstPaymentFailed] = useState<boolean>(false);
    const [secondPaymentFailed, setSecondPaymentFailed] = useState<boolean>(false);
    const [paymentCustomer, setPaymentCustomer] = useState<PaymentCustomer | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const { data: paymentCustomerFromQuery, isError: isErrorPaymentCustomerLoading, isLoading: isPaymentCutomerLoading } = useQuery<PaymentCustomer>(
        ['paymentCustomer', random],
        async (): Promise<PaymentCustomer> => {
            try {
                personalInformationSchema.parse(personalInformation)
            } catch (e) {
                console.log(e);
                throw new Error('Unexpected: personalInformation does not match schema');
            }

            const postData = {
                emailAddress: personalInformation.email,
                firstName: personalInformation.firstName,
                lastName: personalInformation.lastName,
                phoneNumber: personalInformation.phone,
                company: personalInformation.company,
            };

            const response = await axios.post(getPaymentCustomerUrl(), postData);
            const responseData = response.data;
            const paymentCustomer: PaymentCustomer = parsePaymentCustomer(responseData.data);
            return paymentCustomer;
        },
        {
            enabled: paymentCustomer === undefined,
            refetchOnMount: 'always',
            // refetchOnMount: false,
            // keepPreviousData: true,
        },
    );

    useEffect(() => {
        setPaymentCustomer(paymentCustomerFromQuery);
    }, [paymentCustomerFromQuery]);

    const braintreeConnect = useCallback(async (paymentCustomer: PaymentCustomer, htmlElement: HTMLElement) => {
        if (dropIn.current !== null) {
            await dropIn.current.teardown();
        }
        try {
            const instance = await create({
                container: htmlElement,
                authorization: paymentCustomer.token,
                threeDSecure: true,
                locale: i18next.language === AppLanguageEnum.fr ? 'fr_FR' : undefined,
            });
            // setDropIn(instance);
            dropIn.current = instance;
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        if (paymentCustomer === undefined) {
            return;
        }
        if (braintreeRef.current === null) {
            return;
        }
        if (braintreeRef2.current === null) {
            return;
        }
        if (paymentSucceed === undefined) {
            braintreeConnect(paymentCustomer, braintreeRef.current);
        }
        if (paymentSucceed === false) {
            braintreeConnect(paymentCustomer, braintreeRef2.current);
        }
    }, [braintreeConnect, paymentCustomer, paymentSucceed]);

    useEffect(() => {
        const d = dropIn.current;
        if (d === null) {
            return;
        }
        return () => {
            d.teardown();
        };
    }, [firstPaymentFailed, paymentCustomer]);

    if (isErrorPaymentCustomerLoading === true) {
        return <ServerError />;
    }

    const handleRequest = async () => {
        if (dropIn.current === null) {
            return;
        }
        if (!dropIn.current.isPaymentMethodRequestable()) {
            return;
        }
        if (paymentCustomer === undefined) {
            throw new Error('paymentCustomer is undefined');
        }
        if (price === undefined) {
            return;
        }

        const priceWithVat = Math.round(getPriceWithVat(price) * 100) / 100;

        try {
            const response = (await dropIn.current.requestPaymentMethod({
                threeDSecure: {
                    amount: priceWithVat.toFixed(2),
                },
            })) as cardPaymentMethodPayload;
            if (response.liabilityShifted === false) {
                if (firstPaymentFailed === false) {
                    Sentry.addBreadcrumb({
                        category: "payment",
                        message: `First payment failed`,
                        level: Sentry.Severity.Info,
                    });
                    setFirstPaymentFailed(true);
                }
                if (firstPaymentFailed === true && secondPaymentFailed === false) {
                    Sentry.addBreadcrumb({
                        category: "payment",
                        message: `Second payment failed`,
                        level: Sentry.Severity.Info,
                    });
                    setSecondPaymentFailed(true);
                    await setPaymentData({ isPaid: false });
                    onError(paymentCustomer.id);
                }
                setPaymentSucceed(false);
                setShowErrorMessage(true);

                throw new Error('liabilityShifted === false');
            }
            Sentry.addBreadcrumb({
                category: "payment",
                message: `Payment succeeded`,
                level: Sentry.Severity.Info,
            });
            setPaymentSucceed(true);
            await setPaymentData({ isPaid: true });
            onSuccess(paymentCustomer.id, response.nonce);
        } catch (e) {
            console.error(e);
        }
    };

    useImperativeHandle(
        ref,
        (): PaymentRef => ({
            async submit() {
                if (secondPaymentFailed || paymentSucceed) {
                    throw Error('Payment already completed');
                }

                if (dropIn === null) {
                    return;
                }

                setShowErrorMessage(false);
                await handleRequest();
            },
        }),
    );

    return (
        <>
            {(isPaymentCutomerLoading === true || paymentCustomer === undefined) && <CircularProgress />}
            {showErrorMessage === true && (
                <Box
                    paddingTop={(() => {
                        if (isSmallLayout) {
                            return '24px';
                        }
                        if (isMediumLayout) {
                            return '40px';
                        }
                        return '64px';
                    })()}
                >
                    <ErrorMessage text={secondPaymentFailed === false ? t('order.screen.payment.error.failed1') : t('order.screen.payment.error.failed2')} />
                </Box>
            )}
            {secondPaymentFailed === false && <div ref={braintreeRef} />}
            {secondPaymentFailed === false && <div ref={braintreeRef2} />}
        </>
    );
});

export default Payment;

import { InputAdornment, TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps } from './textFieldProps';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const TextField: React.FC<TextFieldProps> = (props) => {
    const { autoFocus, fullWidth, hideErrors, label, placeholder, small, error, name, rows, onChange, disabled, margin, variant } = props;
    const { register } = useFormContext();

    return (
        <MuiTextField
            name={name}
            inputRef={register}
            label={label}
            error={error !== undefined}
            helperText={hideErrors !== true && error}
            variant={variant ?? 'filled'}
            fullWidth={fullWidth}
            margin={margin ?? 'normal'}
            size={small === true ? 'small' : 'medium'}
            autoFocus={autoFocus}
            placeholder={placeholder}
            multiline={rows !== undefined && rows > 1}
            rows={rows}
            onChange={onChange}
            disabled={disabled}
            InputProps={{
                endAdornment:
                    error === undefined ? undefined : (
                        <InputAdornment position={'start'}>
                            <ReportProblemIcon color={'secondary'} />
                        </InputAdornment>
                    ),
            }}
        />
    );
};

export default TextField;
